import { useEffect, useState } from 'react';
import FormInputText from '../uiComponents/FormInputText';
import { useForm } from 'react-hook-form';
import {
  useAddClubMutation,
  useGetAllTrainersQuery,
  useUpdateClubMutation,
} from '../../store';
import Notiflix from 'notiflix';
import Select from '../uiComponents/Select';
import MainHeading from '../uiComponents/MainHeading';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import {
  showLoadingEffect,
  showLoadingNotificationEffect,
  trimCapitalize,
} from '../../utils/helpers';
import { useLocation } from 'react-router-dom';

function ClubForm() {
  const { state } = useLocation();
  const club = state?.club;
  const { register, handleSubmit, reset, setValue } = useForm();
  const [selectedTrainer, setSelectedTrainer] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');

  const [updateClub, updateClubResults] = useUpdateClubMutation();
  const { data: trainersApi, isLoading, isError } = useGetAllTrainersQuery();
  const [addClub, addClubResults] = useAddClubMutation();

  const handleDropDownChange = (option) => {
    setSelectedTrainer(option);
  };

  useEffect(() => {
    showLoadingEffect(trainersApi, isLoading, isError);
  }, [isLoading, isError, trainersApi]);

  useEffect(() => {
    showLoadingNotificationEffect(updateClubResults, 'Club', 'Updating');
  }, [updateClubResults]);

  const handleFormSubmit = (form) => {
    form.trainer = selectedTrainer.value;
    form.country = selectedCountry;
    form.province = selectedProvince;
    form.name = trimCapitalize(form.name);

    console.log(form);
    if (club) {
      form.id = club._id;
      updateClub(form);
    } else addClub(form);
  };

  useEffect(() => {
    showLoadingNotificationEffect(addClubResults, 'Club', 'Adding');
    if (addClubResults.isSuccess) {
      reset();
      setSelectedTrainer('');
    }
    if (addClubResults.isError) {
      if (addClubResults.error.data.message.includes('name')) {
        Notiflix.Report.failure(
          'Duplicate Name',
          'Club with this name already exists, try another name.'
        );
        // console.log(`from name error`, addClubResults.error.data.message);
      }

      if (addClubResults.error.data.message.includes('trainer')) {
        Notiflix.Report.failure(
          'Duplicate Trainer',
          'This trainer is already linked to another club. A trainer can only be linked to one club.'
        );
        // console.log(
        //   `from trainer error ====`,
        //   addClubResults.error.data.message
        // );
      }
    }
  }, [addClubResults, reset]);

  useEffect(() => {
    if (club) {
      setValue('name', club.name);
      setSelectedCountry(club.country);
      setSelectedProvince(club.province);
      setSelectedTrainer({
        label: `${club.trainer.firstName} ${club.trainer.middleName} ${club.trainer.lastName}`,
        value: club.trainer._id,
      });
    }
  }, [club, setValue]);
  if (trainersApi) {
    const trainers = trainersApi.data.map((trainer) => {
      return {
        label: `${trainer.firstName} ${trainer.middleName} ${trainer.lastName}`,
        value: trainer._id,
      };
    });

    return (
      <div
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col bg-gray-100 rounded-lg p-4 md:p-8 h-fit w-5/6 md:w-fit"
      >
        <MainHeading className={'md:text-2xl'}>Add Club</MainHeading>
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="flex flex-col h-fit"
        >
          <div className="flex flex-col md:grid md:grid-cols-2 gap-4 py-4">
            <FormInputText
              label={'Club Name'}
              htmlFor={'name'}
              placeholder={'Zarb-e-Mujahid'}
              register={register}
              required
            />
            <div className="flex flex-col gap-1">
              <label className="font-bold">Country</label>
              <CountryDropdown
                classes="px-3 py-2 rounded shadow hover:ring hover:ring-gray-400 focus:ring focus:ring-gray-800 transition"
                value={selectedCountry}
                onChange={(value) => setSelectedCountry(value)}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="font-bold">Province</label>
              <RegionDropdown
                classes="px-3 py-2 rounded shadow hover:ring hover:ring-gray-400 focus:ring focus:ring-gray-800 transition"
                showDefaultOption={false}
                value={selectedProvince}
                country={selectedCountry}
                onChange={(value) => setSelectedProvince(value)}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="font-bold">Trainer</label>
              <Select
                required
                value={selectedTrainer}
                options={trainers}
                onChange={(value) => handleDropDownChange(value)}
              />
            </div>

            <button className="col-start-1 col-end-3 row-start-3 self-end mt-2 rounded hover:bg-gray-800 uppercase bg-gray-700 text-white font-bold py-2 px-3">
              submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default ClubForm;
