import React from 'react';

import NavLink from '../uiComponents/NavLink';
import {
  HiMiniCalendarDays,
  HiMiniClock,
  HiMiniMapPin,
  HiMiniUser,
} from 'react-icons/hi2';
export default function EventItem({ event }) {
  const regEndDate = new Date(event.regEnd).toLocaleDateString('en-gb');
  const today = new Date();
  // const regStartDate = new Date(event.regStart).toLocaleDateString('en-gb');
  const eventDate = new Date(event.eventDate).toLocaleDateString('en-gb');

  return (
    <div
      key={event._id}
      className="w-full h-fit md:h-full mb-4 flex flex-col md:flex-row bg-gray-100 rounded-lg hover:drop-shadow-lg border border-gray-200  transition-all"
    >
      <div className="h-full flex flex-col justify-between w-full">
        <div className="w-full aspect-[4/3]">
          <img
            className="rounded-lg"
            loading="lazy"
            // src={`${window.location.origin}/public/uploads/events/${event.image}`}
            src={`https://www.server.antf.cloud/public/uploads/events/${event?.image}`}
            // src={test}
            alt=""
          />
        </div>
        <div className="w-full flex flex-col gap-2 px-4">
          <h1 className="font-bold text-center text-gray-800 mt-4 md:mt-1 line-clamp-2">
            {event.title}
          </h1>
          <div className="text-sm text-gray-500">
            <div className="flex gap-1 items-center">
              <HiMiniMapPin />
              <p>Location: {event.locations.join(', ')}</p>
            </div>
            <div className="flex gap-1 items-center">
              <HiMiniCalendarDays />
              <p>Date: {eventDate}</p>
            </div>
            <div className="flex gap-1 items-center">
              <HiMiniUser />
              <p>Participants: {event.participants.length}</p>
            </div>
            <div className="flex gap-1 items-center">
              <HiMiniClock />
              <p>Deadline: {regEndDate}</p>
            </div>
          </div>
        </div>
        <div className="flex justify-center align-middle px-4 pb-4">
          <NavLink
            className={`${
              today > new Date(event.regEnd)
                ? ' text-red-500 bg-red-500 hover:!bg-red-600 transition'
                : ''
            }`}
            to={`/dashboard/events/${event.type}/${event._id}`}
          >
            {today > new Date(event.regEnd) ? 'closed' : 'register'}
          </NavLink>
        </div>
      </div>
    </div>
  );
}
