import React from 'react';
import { dropdownStyles, dropdownTheme } from '../../../utils/helpers';
import Select from 'react-select';

export default function SearchBarOptionsInput({
  label,
  value,
  onChange,
  options,
  ...rest
}) {
  return (
    <div className="flex-col gap-2 w-full md:w-1/2 md:flex">
      <label className="font-bold text-gray-200">{label}</label>
      <Select
        styles={dropdownStyles}
        isSearchable={false}
        theme={dropdownTheme}
        value={value}
        options={options}
        onChange={onChange}
        {...rest}
      />
    </div>
  );
}
