import { useDispatch, useSelector } from 'react-redux';
import {
  setAthleteDanSearch,
  setAthleteFeeSearchTerm,
  setAthleteNameSearchTerm,
  setAthleteProvinceSearch,
  setAthleteStatusSearchTerm,
  setPoomsaeFinalSearchTerm,
  setPoomsaePreliminarySearchTerm,
  setPoomsaeSemiFinalSearchTerm,
  setPoomsaeCategorySearchTerm,
  setEventTypeFilterTerm,
  setEventNameSearchTerm,
  setEventStatusFilterTerm,
  setClubNameSearchTerm,
  setAthleteClubSearchTerm,
  setAthletePromoEligibilityFilter,
} from '../../store';
import { HiAdjustmentsHorizontal, HiXMark } from 'react-icons/hi2';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import {
  poomsaeBlackBeltOptions,
  poomsaeColorBeltOptions,
  provinceOptions,
} from '../../utils/helpers';
import SearchbarSearchInput from './searchbar/SearchbarSearchInput';
import SearchBarOptionsInput from './searchbar/SearchBarOptionsInput';

function SearchBar({ pageType, clubs }) {
  const dispatch = useDispatch();
  const [expandFilters, setExpandFilters] = useState(true);

  useEffect(() => {
    if (isMobile) {
      setExpandFilters(false);
    } else {
      setExpandFilters(true);
    }
  }, [setExpandFilters]);

  const {
    athletePromoEligibilityFilter,
    athleteNameSearchTerm,
    athleteClubSearchTerm,
    // for examination
    athleteFeeSearchTerm,
    athleteProvinceSearch,
    athleteDanSearch,
    athleteStatusSearchTerm,
    // for poomsae
    poomsaeCategorySearchTerm,
    poomsaePreliminarySearchTerm,
    poomsaeSemiFinalSearchTerm,
    poomsaeFinalSearchTerm,
    // for events
    eventTypeFilterTerm,
    eventStatusFilterTerm,
    eventNameSearchTerm,
    // clubs
    clubNameSearchTerm,
  } = useSelector((state) => state.data);

  const eligibiltyOptions = [
    { label: 'Eligible', value: 'Eligible' },
    { label: 'Not Eligible', value: 'Not Eligible' },
    { label: 'No Dan', value: 'No Dan' },
  ];

  const clubOptions = clubs?.map((club) => ({
    label: club.name,
    value: club._id,
  }));

  const danGradeOptions = [
    { label: '1st Dan', value: 1 },
    { label: '2nd Dan', value: 2 },
    { label: '3rd Dan', value: 3 },
    { label: '4th Dan', value: 4 },
    { label: '5th Dan', value: 5 },
    { label: '6th Dan', value: 6 },
  ];

  const atheltesPageDanGradeOptions = [
    ...danGradeOptions,
    { label: 'Color', value: 'color' },
  ];

  const feeStatusOptions = [
    { label: 'Approved', value: 'approved' },
    { label: 'Pending', value: 'pending' },
  ];

  const promotionStatusOptions = [
    { label: 'Uninitialized', value: 'uninitialized' },
    { label: 'Passed', value: 'passed' },
    { label: 'Conditional', value: 'conditional' },
    { label: 'Failed', value: 'failed' },
  ];

  const categorieOptions = [
    ...poomsaeColorBeltOptions,
    ...poomsaeBlackBeltOptions,
  ];

  const poomsaeStatusOptions = [
    { label: 'Uninitialized', value: false },
    { label: 'Completed', value: true },
  ];

  const eventTypeOptions = [
    { label: 'Examination', value: 'examination' },
    { label: 'Poomsae', value: 'poomsae' },
    { label: 'Keurugi', value: 'keurugi' },
  ];

  const eventStatusOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Closed', value: 'closed' },
  ];

  /**
   * Examination
   */
  const handleDanChange = (option) => {
    dispatch(setAthleteDanSearch(option));
  };

  const handleProvinceSearchChange = (option) => {
    dispatch(setAthleteProvinceSearch(option));
  };

  const handleNameSearchTermChange = (event) => {
    dispatch(setAthleteNameSearchTerm(event.target.value));
  };
  const handleFeeSearchChange = (option) => {
    console.log(option);
    dispatch(setAthleteFeeSearchTerm(option));
  };
  const handlePromotionStatusChange = (option) => {
    dispatch(setAthleteStatusSearchTerm(option));
  };

  /**
   * Poomsae
   */

  const handlePoomsaePreliminarySearchChange = (option) => {
    dispatch(setPoomsaePreliminarySearchTerm(option));
  };
  const handlePoomsaeSemiFinalSearchChange = (option) => {
    dispatch(setPoomsaeSemiFinalSearchTerm(option));
  };
  const handlePoomsaeFinalSearchTermChange = (option) => {
    dispatch(setPoomsaeFinalSearchTerm(option));
  };
  const handlePoomsaeCategoryChange = (option) => {
    dispatch(setPoomsaeCategorySearchTerm(option));
  };

  /**
   * Events
   */

  const handleEventStatusFilterTermChange = (option) => {
    dispatch(setEventStatusFilterTerm(option));
  };
  const handleEventNameSearchTermChange = (event) => {
    dispatch(setEventNameSearchTerm(event.target.value));
  };
  const handleEventTypeFilterTermChange = (option) => {
    dispatch(setEventTypeFilterTerm(option));
  };
  const handleReset = (event) => {
    event.preventDefault();
    dispatch(setAthleteDanSearch(''));
    dispatch(setAthletePromoEligibilityFilter(''));
    dispatch(setAthleteProvinceSearch(''));
    dispatch(setAthleteNameSearchTerm(''));
    dispatch(setAthleteFeeSearchTerm(''));
    dispatch(setAthleteStatusSearchTerm(''));
    dispatch(setPoomsaeCategorySearchTerm(''));
    dispatch(setPoomsaePreliminarySearchTerm(''));
    dispatch(setPoomsaeSemiFinalSearchTerm(''));
    dispatch(setPoomsaeFinalSearchTerm(''));
    dispatch(setEventStatusFilterTerm(''));
    dispatch(setEventNameSearchTerm(''));
    dispatch(setEventTypeFilterTerm(''));
    dispatch(setClubNameSearchTerm(''));
  };

  const athletesPageFilters = (
    <>
      <SearchbarSearchInput
        label="Athlete Name"
        placeholder="Anwar Asghari"
        value={athleteNameSearchTerm}
        onChange={handleNameSearchTermChange}
      />
      <SearchBarOptionsInput
        label="Promo Eligibility"
        value={athletePromoEligibilityFilter}
        onChange={(option) =>
          dispatch(setAthletePromoEligibilityFilter(option))
        }
        options={eligibiltyOptions}
        isClearable
      />
      {clubs && (
        <SearchBarOptionsInput
          label="Club"
          value={athleteClubSearchTerm}
          onChange={(option) => dispatch(setAthleteClubSearchTerm(option))}
          options={clubOptions}
          isSearchable={true}
          isClearable
        />
      )}
      <SearchBarOptionsInput
        label="Dan"
        value={athleteDanSearch}
        onChange={handleDanChange}
        options={atheltesPageDanGradeOptions}
        isSearchable={false}
        isClearable
      />

      <SearchBarOptionsInput
        label="Province"
        value={athleteProvinceSearch}
        onChange={handleProvinceSearchChange}
        options={provinceOptions}
        isSearchable={true}
        isClearable
      />
    </>
  );

  const examinationFilters = (
    <>
      <SearchbarSearchInput
        label="Athlete Name"
        placeholder="Anwar Asghari"
        value={athleteNameSearchTerm}
        onChange={handleNameSearchTermChange}
      />
      {clubs && (
        <SearchBarOptionsInput
          label="Club"
          value={athleteClubSearchTerm}
          onChange={(option) => dispatch(setAthleteClubSearchTerm(option))}
          options={clubOptions}
          isSearchable={true}
          isClearable
        />
      )}
      <SearchBarOptionsInput
        label="Fee Status"
        value={athleteFeeSearchTerm}
        onChange={handleFeeSearchChange}
        options={feeStatusOptions}
        isSearchable={false}
        isClearable
      />
      <SearchBarOptionsInput
        label="Applying Dan"
        value={athleteDanSearch}
        onChange={handleDanChange}
        options={danGradeOptions}
        isSearchable={false}
        isClearable
      />
      <SearchBarOptionsInput
        label="Promotion Status"
        value={athleteStatusSearchTerm}
        onChange={handlePromotionStatusChange}
        options={promotionStatusOptions}
        isSearchable={false}
        isClearable
      />
      <SearchBarOptionsInput
        label="Province"
        value={athleteProvinceSearch}
        onChange={handleProvinceSearchChange}
        options={provinceOptions}
        isSearchable={true}
        isClearable
      />
    </>
  );
  const poomsaeFilters = (
    <>
      <SearchbarSearchInput
        label="Athlete Name"
        placeholder="Anwar Asghari"
        value={athleteNameSearchTerm}
        onChange={handleNameSearchTermChange}
      />
      {clubs && (
        <SearchBarOptionsInput
          label="Club"
          value={athleteClubSearchTerm}
          onChange={(option) => dispatch(setAthleteClubSearchTerm(option))}
          options={clubOptions}
          isSearchable={true}
          isClearable
        />
      )}
      <SearchBarOptionsInput
        label="Fee Status"
        options={feeStatusOptions}
        value={athleteFeeSearchTerm}
        onChange={handleFeeSearchChange}
        isSearchable={false}
        isClearable
      />
      <SearchBarOptionsInput
        label="Category"
        options={categorieOptions}
        value={poomsaeCategorySearchTerm}
        onChange={handlePoomsaeCategoryChange}
        isSearchable={true}
        isClearable
      />
      <SearchBarOptionsInput
        label="Preliminary"
        options={poomsaeStatusOptions}
        value={poomsaePreliminarySearchTerm}
        onChange={handlePoomsaePreliminarySearchChange}
        isSearchable={false}
        isClearable
      />
      <SearchBarOptionsInput
        label="Semi Final"
        options={poomsaeStatusOptions}
        value={poomsaeSemiFinalSearchTerm}
        onChange={handlePoomsaeSemiFinalSearchChange}
        isSearchable={false}
        isClearable
      />
      <SearchBarOptionsInput
        label="Final"
        options={poomsaeStatusOptions}
        value={poomsaeFinalSearchTerm}
        onChange={handlePoomsaeFinalSearchTermChange}
        isSearchable={false}
        isClearable
      />
    </>
  );

  const eventFilters = (
    <>
      <SearchbarSearchInput
        label="Event Name"
        placeholder="Poomsae Competition"
        value={eventNameSearchTerm}
        onChange={handleEventNameSearchTermChange}
      />
      <SearchBarOptionsInput
        label="Filter by type"
        value={eventTypeFilterTerm}
        options={eventTypeOptions}
        onChange={handleEventTypeFilterTermChange}
        isSearchable={false}
      />
      <SearchBarOptionsInput
        label="Filter by status"
        value={eventStatusFilterTerm}
        options={eventStatusOptions}
        onChange={handleEventStatusFilterTermChange}
        isSearchable={false}
      />
    </>
  );

  const clubsFilters = (
    <>
      {' '}
      <SearchbarSearchInput
        label="Club Name"
        placeholder="Zarb-e-Mujahid"
        value={clubNameSearchTerm}
        onChange={(e) => dispatch(setClubNameSearchTerm(e.target.value))}
      />
      <SearchBarOptionsInput
        label="Province"
        value={athleteProvinceSearch}
        onChange={handleProvinceSearchChange}
        options={provinceOptions}
        isSearchable={true}
        isClearable
      />
    </>
  );
  const showFilterIcon = isMobile ? true : false;
  return (
    <div className="relative flex flex-col md:flex-row justify-between gap-4">
      <div
        onClick={() => setExpandFilters(!expandFilters)}
        className="absolute top-0 right-0 text-gray-100 text-3xl"
      >
        {showFilterIcon &&
          (expandFilters ? <HiXMark /> : <HiAdjustmentsHorizontal />)}
      </div>

      {expandFilters && (
        <>
          {pageType === 'examination' && examinationFilters}
          {pageType === 'poomsae' && poomsaeFilters}
          {pageType === 'events' && eventFilters}
          {pageType === 'athletes' && athletesPageFilters}
          {pageType === 'clubs' && clubsFilters}
        </>
      )}

      {/**
       * below code is for mobile pages, showing just name input and hiding others, hide the default input when expanded
       */}
      {pageType === 'examination' && !expandFilters && (
        <SearchbarSearchInput
          label="Athlete Name"
          placeholder="Anwar Asghari"
          value={athleteNameSearchTerm}
          onChange={handleNameSearchTermChange}
        />
      )}
      {pageType === 'poomsae' && !expandFilters && (
        <SearchbarSearchInput
          label="Athlete Name"
          placeholder="Anwar Asghari"
          value={athleteNameSearchTerm}
          onChange={handleNameSearchTermChange}
        />
      )}
      {pageType === 'events' && !expandFilters && (
        <SearchbarSearchInput
          label="Event Name"
          placeholder="Poomsae Competition"
          value={eventNameSearchTerm}
          onChange={handleEventNameSearchTermChange}
        />
      )}
      {pageType === 'athletes' && !expandFilters && (
        <SearchbarSearchInput
          label="Athlete Name"
          placeholder="Anwar Asghari"
          value={athleteNameSearchTerm}
          onChange={handleNameSearchTermChange}
        />
      )}
      {pageType === 'clubs' && !expandFilters && (
        <SearchbarSearchInput
          label="Club Name"
          placeholder="Zarb-e-Mujahid"
          value={clubNameSearchTerm}
          onChange={(e) => dispatch(setClubNameSearchTerm(e.target.value))}
        />
      )}
      <button
        onClick={handleReset}
        className="uppercase h-1/2 w-1/4 self-end py-2 px-3 font-bold bg-gray-600 text-white rounded-md shadow hover:bg-gray-500 transition"
      >
        reset
      </button>
    </div>
  );
}

export default SearchBar;
