import { useEffect, useState } from 'react';
import Component from './pointComponent';
import { useDispatch, useSelector } from 'react-redux';
import Notiflix from 'notiflix';
import {
  logout,
  setPoomsaeJudgeRole,
  setShowJudgeConfirmRoleModal,
  useGetOperationalDataQuery,
  useSubmitPoomsaePointMutation,
} from '../../../../store';
import Modal from '../../../../components/uiComponents/Modal';
import JudgeConfirmRoleForm from '../../../../components/poomsae/JudgeConfirmRoleForm';
import Button from '../../../../components/uiComponents/Button';
import { useNavigate } from 'react-router-dom';
import {
  showLoadingEffect,
  showLoadingNotificationEffect,
} from '../../../../utils/helpers';
function ScoringPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authentication);
  const { poomsaeJudgeRole, showJudgeRoleConfirmModal } = useSelector(
    (state) => state.data
  );

  const { data, isLoading, isError } = useGetOperationalDataQuery('poomsae');
  const [submitPoint, submitPointResults] = useSubmitPoomsaePointMutation();

  const [accuracy, setAccuracy] = useState(40);
  const [strength, setStrength] = useState(2);
  const [rythm, setRythm] = useState(2);
  const [energy, setEnergy] = useState(2);

  const [deductThreeCount, setDeductThreeCount] = useState(0);
  const [deductOneCount, setDeductOneCount] = useState(0);
  // const [allowedToScore, setAllowedToScore] = useState();

  useEffect(() => {
    document.title = 'Scoring App';
    if (!poomsaeJudgeRole) {
      dispatch(setShowJudgeConfirmRoleModal(true));
    }
    showLoadingEffect(data, isLoading, isError);
    // setAllowedToScore(true);
  }, [data, dispatch, isError, isLoading, poomsaeJudgeRole]);

  useEffect(() => {
    showLoadingNotificationEffect(submitPointResults, 'Point', 'Adding');
  }, [submitPointResults]);

  const reset = () => {
    setAccuracy(40);
    setStrength(2);
    setRythm(2);
    setEnergy(2);
    setDeductOneCount(0);
    setDeductThreeCount(0);
  };

  const deductPointThree = () => {
    if (accuracy <= 2) return setAccuracy(0);

    setDeductThreeCount(deductThreeCount + 1);
    setAccuracy(accuracy - 3);
  };

  const deductPointOne = () => {
    if (accuracy <= 1) return setAccuracy(0);
    setDeductOneCount(deductOneCount + 1);
    setAccuracy(accuracy - 1);
  };

  const cancelDeductThree = () => {
    if (deductThreeCount > 0) {
      setDeductThreeCount(deductThreeCount - 1);
      setAccuracy(accuracy + 3);
    }
  };
  const cancelDeductOne = () => {
    if (deductOneCount > 0) {
      setDeductOneCount(deductOneCount - 1);
      setAccuracy(accuracy + 1);
    }
  };

  if (data) {
    const profile = data.test ? data.testProfile : data.activeProfile;
    const { activeStage: poomsaeActiveStage } = data;

    const handleSubmit = async () => {
      const body = {
        user: user._id,
        userRole: poomsaeJudgeRole,
        profileId: profile._id,
        accuracyPoints: accuracy / 10,
        presentationPoints: strength + rythm + energy,
        preliminary: poomsaeActiveStage === 'activePreliminary' ? true : false,
        semiFinal: poomsaeActiveStage === 'activeSemiFinal' ? true : false,
        final: poomsaeActiveStage === 'activeFinal' ? true : false,
      };

      Notiflix.Confirm.show(
        'Submit Points',
        `Are you sure to submit points?
        Accuracy: ${body.accuracyPoints.toFixed(2)},
         Presentation: ${body.presentationPoints.toFixed(2)} Total: ${(
          body.accuracyPoints + body.presentationPoints
        ).toFixed(2)}`,
        'Yes',
        'Cancel',
        async () => {
          await submitPoint(body);
          reset();
          // setAllowedToScore(false);
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
      );
    };
    const handleLogout = () => {
      dispatch(logout());
      dispatch(setPoomsaeJudgeRole(''));
      localStorage.removeItem('poomsaeJudgeRole');
      navigate('/', { replace: true });
    };

    const getathleteStage = () => {
      let stage;
      if (profile.activePreliminary) {
        stage = 'Preliminary';

        return stage;
      } else if (profile.activeSemiFinal) {
        stage = 'Semi Final';

        return stage;
      } else if (profile.activeFinal) {
        stage = 'Final';

        return stage;
      }
    };

    // console.log(poomsaeJudgeRole);
    return (
      <div className="lg:p-6 sm:p-2 w-full bg-gray-800">
        <div className="rounded-md bg-gray-700 lg:p-6 sm:p-2 mx-auto">
          <div className="flex gap-4 justify-center lg:text-2xl font-bold text-white items-center sm:text-sm">
            <p className="">{deductThreeCount} x</p>
            <button
              onClick={cancelDeductThree}
              className="lg:w-44 lg:h-14 bg-red-500 rounded-lg sm:w-24 sm:h-10"
            >
              CANCEL 0.3
            </button>
            <button
              onClick={reset}
              className="lg:w-44 lg:h-14 bg-gray-800 rounded-lg sm:w-24 sm:h-10"
            >
              RESET
            </button>
            <button
              onClick={cancelDeductOne}
              className="lg:w-44 lg:h-14 bg-green-500 rounded-lg sm:w-24 sm:h-10"
            >
              CANCEL 0.1
            </button>
            <p className="">x {deductOneCount}</p>
          </div>
          <div className="flex flex-row justify-between items-center">
            <button
              onClick={deductPointThree}
              className="bg-red-500 lg:w-64 lg:h-64 rounded-xl text-8xl md:text-8xl font-bold text-white  sm:w-36 sm:h-36 sm:text-6xl"
            >
              0.3
            </button>
            <div className="flex flex-col gap-4 sm:gap-1 sm:mt-4 justify-center items-center">
              <p className="lg:text-10xl font-bold text-white sm:text-8xl">
                {(accuracy / 10).toFixed(1)}
              </p>
              <p className="text-white font-semibold lg:text-3xl sm:text-2xl mt-4 ">
                {Array.isArray(profile.athlete)
                  ? profile.athlete
                      .map((athlete) => athlete.fullNameEn)
                      .join(', ')
                  : profile.athlete?.fullNameEn}
              </p>
              <p className="text-white font-semibold lg:text-3xl sm:text-2xl">
                {profile.category}
              </p>
              <p className="text-white font-semibold lg:text-3xl sm:text-2xl">
                {getathleteStage()}
              </p>
            </div>
            <button
              onClick={deductPointOne}
              className="bg-green-500 lg:w-64 lg:h-64 rounded-xl lg:text-8xl font-bold text-white sm:w-36 sm:h-36 sm:text-6xl"
            >
              0.1
            </button>
          </div>
          <div className="flex flex-col justify-center">
            <div className="mt-8 ">
              <h1 className="text-white lg:text-3xl font-bold lg:mb-8 sm:mb-4 sm:text-2xl">
                Strength and Speed
              </h1>
              <Component
                setPresentation={setStrength}
                presentation={strength}
              />
            </div>
            <div className="mt-8">
              <h1 className="text-white lg:text-3xl font-bold lg:mb-8 sm:mb-4 sm:text-2xl">
                Rythm and Coordination
              </h1>
              <Component setPresentation={setRythm} presentation={rythm} />
            </div>
            <div className="mt-8">
              <h1 className="text-white lg:text-3xl font-bold lg:mb-8 sm:mb-4 sm:text-2xl">
                Energy Expression
              </h1>
              <Component setPresentation={setEnergy} presentation={energy} />
            </div>
          </div>
          <div className="flex text-white font-bold justify-between mt-8 px-8 w-full ">
            <div className="flex flex-col gap-4 items-center">
              <p className="lg:text-4xl sm:text-xl">Accuracy</p>
              <p className="lg:text-8xl text-scrRed sm:text-6xl">
                {(accuracy / 10).toFixed(1)}
              </p>
            </div>
            <div className="flex flex-col gap-4 items-center">
              <p className="lg:text-4xl  sm:text-xl">Presentation</p>
              <p className="lg:text-8xl text-scrGreen sm:text-6xl">
                {(strength + rythm + energy).toFixed(1)}
              </p>
            </div>
            <div className="flex flex-col gap-4 items-center">
              <p className="lg:text-4xl sm:text-xl">Total</p>
              <p className="lg:text-8xl text-white sm:text-6xl">
                {(strength + rythm + energy + accuracy / 10).toFixed(1)}
              </p>
            </div>
            <div className="flex flex-col gap-4 items-center justify-between">
              <p className="text-4xl"> </p>
              <button
                onClick={handleSubmit}
                className="lg:w-48 lg:h-20 bg-green-500 rounded-lg md:text-3xl sm:w-24 sm:h-12 sm:text-sm"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        {showJudgeRoleConfirmModal && (
          <Modal
            setFunction={poomsaeJudgeRole ? setShowJudgeConfirmRoleModal : null}
          >
            <JudgeConfirmRoleForm />
          </Modal>
        )}
        <div className="flex gap-2 mt-4">
          <Button className={'bg-red-500'} onClick={handleLogout}>
            Log Out | {user?.firstName} | {poomsaeJudgeRole}
          </Button>
          <Button onClick={() => dispatch(setShowJudgeConfirmRoleModal(true))}>
            Change Role
          </Button>
        </div>
      </div>
    );
  }
}

export default ScoringPage;
