import React, { useEffect, useState } from 'react';
import NavLink from '../../../components/uiComponents/NavLink';
import {
  HiEye,
  HiPaperAirplane,
  HiPencilSquare,
  HiMiniCheckCircle,
  HiMiniPlusCircle,
  HiTrash,
} from 'react-icons/hi2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import { useDispatch, useSelector } from 'react-redux';
import Notiflix from 'notiflix';
import { Accordion, Flowbite } from 'flowbite-react';
import {
  setShowPoomsaeControlsModal,
  useGetAllClubsQuery,
  useGetEventQuery,
  useRemovePoomsaeTeamAndDuoMutation,
  useUpdateOperationalDataMutation,
  useUpdatePoomsaeProfileMutation,
} from '../../../store';
import Panel from '../../../components/uiComponents/Panel';
import MainHeading from '../../../components/uiComponents/MainHeading';
import Button from '../../../components/uiComponents/Button';
import SearchBar from '../../../components/uiComponents/SearchBar';
import Scrollbar from '../../../components/uiComponents/Scrollbar';
import SortableTable from '../../../components/uiComponents/SortableTable';
import Modal from '../../../components/uiComponents/Modal';
import PoomsaeControls from '../../../components/poomsae/PoomsaeControls';
import {
  customAccordionTheme,
  getPoomsaeProfile,
  showLoadingEffect,
  showLoadingNotificationEffect,
} from '../../../utils/helpers';
import TeamAndDuoPointsSummary from '../../../components/poomsae/TeamAndDuoPointsSummary';
import { setShowTeamAndDuoPointsSummaryModal } from '../../../store/slices/dataSlice';

export default function PoomsaeParticipantsPage() {
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [teamAndDuoProfileToShow, setTeamAndDuoProfileToShow] = useState();

  const {
    athleteNameSearchTerm,
    athleteClubSearchTerm,
    athleteFeeSearchTerm,
    poomsaeCategorySearchTerm,
    poomsaePreliminarySearchTerm,
    poomsaeSemiFinalSearchTerm,
    poomsaeFinalSearchTerm,

    showPoomsaeControlsModal,
    poomsaeActiveStage,
    showTeamAndDuoPointsSummaryModal,
  } = useSelector((state) => state.data);

  const { user } = useSelector((state) => state.authentication);

  const [updateOperationalData] = useUpdateOperationalDataMutation();
  const [updateProfile, updateProfileResults] =
    useUpdatePoomsaeProfileMutation();

  const [removePoomsaeTeamAndDuo] = useRemovePoomsaeTeamAndDuoMutation();

  const {
    data: eventApi,
    isLoading: eventLoading,
    isError: eventError,
  } = useGetEventQuery({ eventType: 'poomsae', eventId });

  const {
    data: clubs,
    isLoading: clubsIsLoading,
    isError: clubsIsError,
    error: clubsError,
  } = useGetAllClubsQuery();

  useEffect(() => {
    showLoadingEffect(clubs, clubsIsLoading, clubsIsError, clubsError);
  }, [clubs, clubsError, clubsIsError, clubsIsLoading]);

  useEffect(() => {
    showLoadingEffect(eventApi, eventLoading, eventError);
  }, [eventApi, eventError, eventLoading]);

  const handleApproveFee = (poomsaeProfile) => {
    updateProfile({ profileId: poomsaeProfile._id, feePaid: true });
  };

  const handleCancelFee = (poomsaeProfile) => {
    updateProfile({ profileId: poomsaeProfile._id, feePaid: false });
  };

  const handleDeleteTeamDuo = (profileId) => {
    Notiflix.Confirm.show(
      'Remove Athlete from Event',
      'Are you sure you want to remove athlete?',
      'Delete',
      'No',
      () => removePoomsaeTeamAndDuo({ profileId, eventId })
    );
  };
  useEffect(() => {
    showLoadingNotificationEffect(updateProfileResults, 'Profile', 'Update');
  }, [updateProfileResults]);
  const individualTableConfig = [
    {
      label: 'Name',
      render: (athlete) => athlete.fullNameEn,
    },
    {
      label: 'Club Name',
      render: (athlete) => athlete.club.name,
    },
    {
      label: 'Category',
      render: (athlete) => {
        const poomsaeProfile = getPoomsaeProfile(athlete, eventId);
        return poomsaeProfile?.category;
      },
    },
    {
      label: 'Belt',
      render: (athlete) =>
        athlete.belt.charAt(0).toUpperCase() + athlete.belt.slice(1),
    },
    {
      label: 'Fee Status',
      render: (athlete) => {
        const poomsaeProfile = getPoomsaeProfile(athlete, eventId);
        return poomsaeProfile?.feePaid ? (
          <span className="text-white text-center px-2 py-1 rounded bg-green-500">
            Approved
          </span>
        ) : (
          <span className="text-white text-center px-2 py-1 rounded bg-red-500">
            Pending
          </span>
        );
      },
    },
    {
      label: 'Preliminary',
      render: (athlete) => {
        const poomsaeProfile = getPoomsaeProfile(athlete, eventId);
        let text =
          poomsaeProfile?.preliminaryPoints?.length < 5
            ? 'Uninitialized'
            : 'Completed';
        let className =
          poomsaeProfile?.preliminaryPoints?.length < 5
            ? 'bg-yellow-500'
            : 'bg-green-500';

        return (
          <span
            className={`text-white text-center px-2 py-1 rounded ${className}`}
          >
            {text}
          </span>
        );
      },
    },
    {
      label: 'Semi Final',
      render: (athlete) => {
        const poomsaeProfile = getPoomsaeProfile(athlete, eventId);
        let text =
          poomsaeProfile?.semiFinalPoints?.length < 5
            ? 'Uninitialized'
            : 'Completed';
        let className =
          poomsaeProfile?.semiFinalPoints?.length < 5
            ? 'bg-yellow-500'
            : 'bg-green-500';

        return (
          <span
            className={`text-white text-center px-2 py-1 rounded ${className}`}
          >
            {text}
          </span>
        );
      },
    },
    {
      label: 'Final',
      render: (athlete) => {
        const poomsaeProfile = getPoomsaeProfile(athlete, eventId);
        let text =
          poomsaeProfile?.finalPoints?.length < 5
            ? 'Uninitialized'
            : 'Completed';
        let className =
          poomsaeProfile?.finalPoints?.length < 5
            ? 'bg-yellow-500'
            : 'bg-green-500';

        return (
          <span
            className={`text-white text-center px-2 py-1 rounded ${className}`}
          >
            {text}
          </span>
        );
      },
      sortValue: (athlete) => athlete.status,
    },
    {
      label: 'Active Stage',
      render: (athlete) => {
        const poomsaeProfile = getPoomsaeProfile(athlete, eventId);
        let activeStatus = poomsaeProfile?.activePreliminary
          ? 'Preliminary'
          : poomsaeProfile?.activeSemiFinal
          ? 'SemiFinal'
          : poomsaeProfile?.activeFinal
          ? 'Final'
          : '';
        return (
          activeStatus && (
            <span className=" text-white text-center px-2 py-1 rounded bg-green-500">
              {activeStatus}
            </span>
          )
        );
      },
    },
    {
      label: 'Actions',
      render: (athlete) => {
        const poomsaeProfile = getPoomsaeProfile(athlete, eventId);
        return (
          <div className="flex gap-4">
            <Tippy content="View Profile">
              <Link
                to={`/dashboard/events/poomsae/participants/${eventId}/view/${athlete._id}`}
              >
                <HiEye />
              </Link>
            </Tippy>
            {user.role !== 'trainer' && (
              <Tippy content="Edit">
                <Link to={`/dashboard/athletes/edit/${athlete._id}`}>
                  <HiPencilSquare />
                </Link>
              </Tippy>
            )}
            {user.role === 'admin' && (
              <Tippy content="Activate Player">
                <Link
                  onClick={() => {
                    setActivePlayer(poomsaeProfile);
                  }}
                >
                  <HiPaperAirplane />
                </Link>
              </Tippy>
            )}
          </div>
        );
      },
    },
  ];

  const teamsAndDuosTableConfig = [
    {
      label: 'Name',
      render: (profile) => {
        const names = profile.athlete
          .map((athlete) => athlete.fullNameEn)
          .join(', ');
        return names;
      },
    },
    {
      label: 'Club Name',
      render: (profile) => {
        const names = profile.athlete
          .map((athlete) => athlete.club.name)
          .join(', ');
        return names;
      },
    },
    {
      label: 'Category',
      render: (profile) => profile.category,
    },
    {
      label: 'Belt',
      render: (profile) => {
        const belts = profile.athlete.map((athlete) => {
          return athlete.belt.charAt(0).toUpperCase() + athlete.belt.slice(1);
        });
        return belts.join(', ');
      },
    },
    {
      label: 'Fee Status',
      render: (profile) => {
        return profile.feePaid ? (
          <span className="text-white text-center px-2 py-1 rounded bg-green-500">
            Approved
          </span>
        ) : (
          <span className="text-white text-center px-2 py-1 rounded bg-red-500">
            Pending
          </span>
        );
      },
    },
    {
      label: 'Preliminary',
      render: (profile) => {
        let text =
          profile.preliminaryPoints?.length < 5 ? 'Uninitialized' : 'Completed';
        let className =
          profile.preliminaryPoints?.length < 5
            ? 'bg-yellow-500'
            : 'bg-green-500';

        return (
          <span
            className={`text-white text-center px-2 py-1 rounded ${className}`}
          >
            {text}
          </span>
        );
      },
    },
    {
      label: 'Semi Final',
      render: (profile) => {
        let text =
          profile.semiFinalPoints?.length < 5 ? 'Uninitialized' : 'Completed';
        let className =
          profile.semiFinalPoints?.length < 5
            ? 'bg-yellow-500'
            : 'bg-green-500';

        return (
          <span
            className={`text-white text-center px-2 py-1 rounded ${className}`}
          >
            {text}
          </span>
        );
      },
    },
    {
      label: 'Final',
      render: (profile) => {
        let text =
          profile.finalPoints?.length < 5 ? 'Uninitialized' : 'Completed';
        let className =
          profile.finalPoints?.length < 5 ? 'bg-yellow-500' : 'bg-green-500';

        return (
          <span
            className={`text-white text-center px-2 py-1 rounded ${className}`}
          >
            {text}
          </span>
        );
      },
      sortValue: (athlete) => athlete.status,
    },
    {
      label: 'Active Stage',
      render: (profile) => {
        let activeStatus = profile.activePreliminary
          ? 'Preliminary'
          : profile.activeSemiFinal
          ? 'SemiFinal'
          : profile.activeFinal
          ? 'Final'
          : '';
        return (
          activeStatus && (
            <span className=" text-white text-center px-2 py-1 rounded bg-green-500">
              {activeStatus}
            </span>
          )
        );
      },
    },
    {
      label: 'Actions',
      render: (profile) => {
        return (
          <div className="flex gap-4">
            <Tippy content="View Points">
              <button
                onClick={() => {
                  setTeamAndDuoProfileToShow(profile);
                  dispatch(setShowTeamAndDuoPointsSummaryModal());
                }}
              >
                <HiEye />
              </button>
            </Tippy>
            {user.role === 'admin' && (
              <Tippy content="Delete Profile">
                <button onClick={() => handleDeleteTeamDuo(profile._id)}>
                  <HiTrash />
                </button>
              </Tippy>
            )}
            {user.role !== 'trainer' && (
              <Tippy
                content={`${profile.feePaid ? 'Cancel Fee' : 'Approve Fee'}`}
              >
                <button
                  onClick={() => {
                    profile.feePaid
                      ? handleCancelFee(profile)
                      : handleApproveFee(profile);
                  }}
                >
                  {profile.feePaid ? (
                    <HiMiniPlusCircle className="rotate-45" />
                  ) : (
                    <HiMiniCheckCircle />
                  )}
                </button>
              </Tippy>
            )}
            {user.role === 'admin' && (
              <Tippy content="Activate Player">
                <Link
                  onClick={() => {
                    setActivePlayer(profile);
                  }}
                >
                  <HiPaperAirplane />
                </Link>
              </Tippy>
            )}
          </div>
        );
      },
    },
  ];

  const setActivePlayer = (poomsaeProfile) => {
    if (poomsaeProfile.feePaid !== true) {
      Notiflix.Report.warning(
        'Fee Not Cleared',
        'The athlete has not cleared his/her fee.',
        'Okay'
      );
    } else {
      const body = {
        eventType: 'poomsae',
        activeProfile: poomsaeProfile._id,
        activeStage: poomsaeActiveStage,
        activeCategory: poomsaeProfile.category,
      };
      updateOperationalData(body);
    }
  };

  if (eventApi) {
    const { teamsAndDuos } = eventApi;
    let participants = eventApi.participants;

    let filteredData = participants;

    filteredData = filteredData.map((athlete) => {
      const poomsaeProfile = [getPoomsaeProfile(athlete, eventId)];

      return { ...athlete, ...poomsaeProfile };
    });

    filteredData = filteredData.filter((athlete) => {
      return athlete.fullNameEn
        .toLowerCase()
        .includes(athleteNameSearchTerm.toLowerCase());
    });

    if (athleteFeeSearchTerm?.value) {
      filteredData = filteredData.filter((athlete) => {
        const status = getPoomsaeProfile(athlete, eventId).feePaid
          ? 'approved'
          : 'pending';
        return status === athleteFeeSearchTerm.value;
      });
    }

    if (poomsaeCategorySearchTerm?.value) {
      filteredData = filteredData.filter((athlete) => {
        return (
          getPoomsaeProfile(athlete, eventId).category ===
          poomsaeCategorySearchTerm.value
        );
      });
    }

    filteredData = filteredData.filter((athlete) => {
      const poomsaeProfile = getPoomsaeProfile(athlete, eventId);

      if (poomsaePreliminarySearchTerm?.value) {
        if (poomsaeProfile.preliminaryPoints.length > 4) {
          return true;
        } else return false;
      } else if (poomsaePreliminarySearchTerm?.value === false) {
        if (poomsaeProfile.preliminaryPoints.length < 5) {
          return true;
        } else return false;
      } else if (!poomsaePreliminarySearchTerm) return true;
      return true;
    });

    filteredData = filteredData.filter((athlete) => {
      const poomsaeProfile = getPoomsaeProfile(athlete, eventId);

      if (poomsaeSemiFinalSearchTerm?.value) {
        if (poomsaeProfile.semiFinalPoints.length > 4) {
          return true;
        } else return false;
      } else if (poomsaeSemiFinalSearchTerm?.value === false) {
        if (poomsaeProfile.semiFinalPoints.length < 5) {
          return true;
        } else return false;
      } else if (!poomsaeSemiFinalSearchTerm) return true;
      return true;
    });
    filteredData = filteredData.filter((athlete) => {
      const poomsaeProfile = getPoomsaeProfile(athlete, eventId);

      if (poomsaeFinalSearchTerm?.value) {
        if (poomsaeProfile.finalPoints.length > 4) {
          return true;
        } else return false;
      } else if (poomsaeFinalSearchTerm?.value === false) {
        if (poomsaeProfile.finalPoints.length < 5) {
          return true;
        } else return false;
      } else if (!poomsaeFinalSearchTerm) return true;
      return true;
    });

    if (athleteClubSearchTerm?.value) {
      filteredData = filteredData.filter((athlete) => {
        return athlete.club._id === athleteClubSearchTerm.value;
      });
    }

    return (
      <div className="flex flex-col gap-2 md:gap-4 h-full">
        <Panel className={'flex gap-2  md:gap-4 flex-col'}>
          <div className="flex justify-between">
            <MainHeading>
              Event Participants {` | Results: ${filteredData.length}`}
            </MainHeading>
            <div className="hidden md:flex md:flex-row md:gap-2">
              {user.role !== 'trainer' && showPoomsaeControlsModal && (
                <Modal setFunction={setShowPoomsaeControlsModal}>
                  <PoomsaeControls />
                </Modal>
              )}
              {showTeamAndDuoPointsSummaryModal && (
                <Modal setFunction={setShowTeamAndDuoPointsSummaryModal}>
                  <TeamAndDuoPointsSummary
                    poomsaeProfile={teamAndDuoProfileToShow}
                  />
                </Modal>
              )}
              <Button onClick={() => dispatch(setShowPoomsaeControlsModal())}>
                Controls
              </Button>
              <Button onClick={() => navigate(-1)}>Back</Button>
            </div>
          </div>
        </Panel>
        <Panel className="md:hidden">
          <div className="flex flex-row gap-2">
            {user.role !== 'trainer' && showPoomsaeControlsModal && (
              <Modal setFunction={setShowPoomsaeControlsModal}>
                <PoomsaeControls />
              </Modal>
            )}
            <Button onClick={() => dispatch(setShowPoomsaeControlsModal())}>
              Controls
            </Button>
            <Button onClick={() => navigate(-1)}>Back</Button>
            <NavLink
              to={'/dashboard'}
              className="uppercase py-2 px-3 font-bold bg-gray-700 text-white rounded-md shadow hover:bg-gray-800 transition"
            >
              Dashboard
            </NavLink>
          </div>
        </Panel>
        <div className={`p-4 drop-shadow-md rounded-md z-[50] bg-gray-700`}>
          <SearchBar clubs={clubs} pageType="poomsae" />
        </div>
        <Panel className="h-full">
          <Flowbite theme={{ theme: customAccordionTheme }}>
            <Scrollbar>
              <Accordion>
                <Accordion.Panel>
                  <Accordion.Title>Individuals</Accordion.Title>
                  <Accordion.Content>
                    <SortableTable
                      config={individualTableConfig}
                      data={filteredData}
                    ></SortableTable>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                  <Accordion.Title>Teams and Duos</Accordion.Title>
                  <Accordion.Content>
                    <SortableTable
                      config={teamsAndDuosTableConfig}
                      data={teamsAndDuos}
                    ></SortableTable>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>
            </Scrollbar>
          </Flowbite>
        </Panel>
      </div>
    );
  }
}
