import React from 'react';
import { calculateAthletePoints } from '../../utils/helpers';

export default function PoomsaeDetail({ athlete }) {
  const renderedPointsForRound = (profile, round, groupTitle) => {
    const pointArrToSort = [...(profile[round] || '')];
    const sortedJudges = pointArrToSort.sort((a, b) => {
      const jA = Object.keys(a)[2].split('j')[1];
      const jB = Object.keys(b)[2].split('j')[1];

      return jA - jB;
    });

    if (sortedJudges.length > 0) {
      const { avgAccuracy, avgPresentation, totalPoint } =
        calculateAthletePoints(profile, round);

      return (
        <div>
          <p className="font-semibold text-gray-800 text-md mb-2 text-center">
            {groupTitle}
          </p>
          <div className="md:col-start-6 flex flex-col gap-2 bg-gray-800 rounded shadow w-full p-4">
            <div>
              <p className="text-lg font-bold text-gray-100 text-center">
                Result
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex justify-between gap-2 font-bold text-gray-100 text-center">
                <div className="bg-green-500 w-full p-2 rounded">
                  <p>{avgAccuracy?.toFixed(2) ?? `0.00`}</p>
                </div>
                <div className="bg-red-500 w-full p-2 rounded">
                  <p>{avgPresentation?.toFixed(2) ?? `0.00`}</p>
                </div>
              </div>
              <div className="bg-green-500 p-2 rounded text-center font-bold text-gray-100">
                <p>
                  {isNaN(totalPoint?.toFixed(3))
                    ? `0.000`
                    : totalPoint?.toFixed(3)}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderedCompetitions = athlete.poomsaeCompetitionProfiles.map(
    (profile) => {
      const renderedPreliminaryPoints = renderedPointsForRound(
        profile,
        'preliminaryPoints',
        'Preliminary'
      );

      const renderedSemiFinalPoints = renderedPointsForRound(
        profile,
        'semiFinalPoints',
        'Semi Final'
      );

      const renderedFinalPoints = renderedPointsForRound(
        profile,
        'finalPoints',
        'Final'
      );
      return (
        <div
          key={profile._id}
          className="flex flex-col gap-2 justify-center items-center bg-gray-300 rounded-lg p-4"
        >
          <h1 className="font-bold text-gray-800 text-xl text-center">
            {profile.event.title}
          </h1>
          <div className="flex gap-4 auto-rows-min">
            {renderedPreliminaryPoints}
            {renderedSemiFinalPoints}
            {renderedFinalPoints}
            {!renderedPreliminaryPoints &&
              !renderedSemiFinalPoints &&
              !renderedFinalPoints && (
                <div className="w-full h-full  flex items-center justify-center">
                  <h1 className="font-bold text-center text-gray-800 text-xl">
                    Competition not started yet.
                  </h1>
                </div>
              )}
          </div>
        </div>
      );
    }
  );
  return (
    <div className="grid gap-4 grid-cols-1 md:grid-cols-3 auto-rows-min justify-center">
      {renderedCompetitions}
    </div>
  );
}
