import { createSlice } from '@reduxjs/toolkit';
import secureLocalStorage from 'react-secure-storage';

const token = secureLocalStorage.getItem('jwt')
  ? secureLocalStorage.getItem('jwt')
  : null;
const user = secureLocalStorage.getItem('user')
  ? JSON.parse(secureLocalStorage.getItem('user'))
  : null;

const isTrainer =
  JSON.parse(secureLocalStorage.getItem('user'))?.role === 'admin'
    ? false
    : true;

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user,
    token,
    isTrainer,
  },
  reducers: {
    login(state, { payload }) {
      state.token = payload.token;
      state.user = payload.user;
      let isTrainer = payload.user.role === 'admin' ? false : true;
      if (isTrainer) {
        state.isTrainer = true;
      } else state.isTrainer = false;

      secureLocalStorage.setItem('user', JSON.stringify(payload.user));
      secureLocalStorage.setItem('jwt', payload.token);
    },
    logout(state) {
      secureLocalStorage.removeItem('jwt');
      secureLocalStorage.removeItem('user');
      state.token = null;
      state.user = null;
    },
    setUser(state, { payload }) {
      state.user = payload;
      let isTrainer = payload.role === 'admin' ? false : true;
      if (isTrainer) {
        state.isTrainer = true;
      } else state.isTrainer = false;
      secureLocalStorage.setItem('user', JSON.stringify(payload));
    },
  },
});

export const authReducer = authSlice.reducer;
export const { login, logout, setUser } = authSlice.actions;
