import React from 'react';

export default function CertificatesDetail({ athlete }) {
  const renderedKukkiwonCertificates = athlete.danGradesKukkiwon.map(
    (certificate) => {
      const issueDate = new Date(certificate.issueDate).toLocaleDateString(
        'en-gb'
      );
      return (
        <div
          key={certificate._id}
          className="flex justify-between bg-gray-800 rounded-lg p-4 text-gray-100"
        >
          <div className="flex flex-col gap-2">
            <div>No: {certificate.danCertificate}</div>
            <div>Date: {issueDate}</div>
          </div>
          <div className="text-4xl font-bold">{certificate.dan}</div>
        </div>
      );
    }
  );
  const renderedNationalCertificates = athlete.danGradesNational.map(
    (certificate) => {
      const issueDate = new Date(
        certificate.issueDateGregorian
      ).toLocaleDateString('en-gb');
      return (
        <div
          key={certificate._id}
          className="flex justify-between bg-gray-800 rounded-lg p-4 text-gray-100"
        >
          <div className="flex flex-col gap-2">
            <div>No: {certificate.number}</div>
            <div>Date: {issueDate}</div>
          </div>
          <div className="text-4xl font-bold">{certificate.dan}</div>
        </div>
      );
    }
  );
  return (
    <div className="flex flex-col gap-2">
      <div className="w-full">
        <h2 className="font-bold text-gray-800 text-xl mb-2">
          Kukkiwon Certificates
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-5 gap-2">
          {renderedKukkiwonCertificates}
        </div>
      </div>
      <div className="w-full">
        <h2 className="font-bold text-gray-800 text-xl mb-2">
          National Certificates
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-5 gap-2">
          {renderedNationalCertificates}
        </div>
      </div>
    </div>
  );
}
