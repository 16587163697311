import { useEffect, useMemo } from 'react';
import MainHeading from '../components/uiComponents/MainHeading';
import Panel from '../components/uiComponents/Panel';
import {
  setActiveTab,
  useDeleteAthleteMutation,
  useGetAllAthletesQuery,
  useGetAllAthletesTrainerQuery,
  useGetAllClubsQuery,
} from '../store';
import SortableTable from '../components/uiComponents/SortableTable';
import Notiflix from 'notiflix';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { HiEye, HiPencilSquare, HiTrash } from 'react-icons/hi2';
import { useDispatch, useSelector } from 'react-redux';
import SearchBar from '../components/uiComponents/SearchBar';
import Tippy from '@tippyjs/react';

import { getRemainingCertificateTime } from '../utils/helpers';
import Scrollbar from '../components/uiComponents/Scrollbar';
import Button from '../components/uiComponents/Button';

function AllAthletesPage() {
  const navigate = useNavigate();
  const { province } = useParams();
  const dispatch = useDispatch();

  const {
    athleteNameSearchTerm,
    athleteProvinceSearch,
    athleteDanSearch,
    athleteClubSearchTerm,
    athletePromoEligibilityFilter,
  } = useSelector((state) => state.data);
  const { user } = useSelector((state) => state.authentication);

  const { data: allAthletes, isLoading: allAthletesLoading } =
    useGetAllAthletesQuery('', { skip: user.role === 'trainer' });

  const { data: trainerAthletes, isLoading: trainerAthletesLoading } =
    useGetAllAthletesTrainerQuery(user._id, {
      skip: user.role === 'admin' || user.role === 'moderator',
    });

  const { data: clubsApi } = useGetAllClubsQuery('', {
    skip: user.role === 'trainer',
  });

  const [deleteAthlete] = useDeleteAthleteMutation();

  const handleDeleteAthlete = (id) => {
    Notiflix.Confirm.show(
      'Delete Athlete',
      'Are you sure you want to delete this athlete?',
      'Delete',
      'Cancel',
      () => deleteAthlete(id)
    );
  };

  useEffect(() => {
    dispatch(setActiveTab('Athletes'));
  }, [dispatch]);

  const config = [
    {
      label: 'Athlete',
      render: (athlete) => athlete.fullNameEn,
    },

    {
      label: 'Dan',
      render: (athlete) =>
        athlete.danGradesKukkiwon?.slice(-1)[0]?.dan ||
        athlete.belt?.charAt(0).toUpperCase() + athlete.belt?.slice(1),
    },
    {
      label: 'Club',
      render: (athlete) => athlete.club?.name,
    },
    { label: 'Country', render: (athlete) => athlete.club?.country },
    { label: 'Province', render: (athlete) => athlete.club?.province },
    {
      label: 'Promo Eligible',
      render: (athlete) => {
        if (athlete.loading !== true) {
          const lastExaminationProfile =
            athlete.examinationProfiles.slice(-1)[0];
          if (lastExaminationProfile && !lastExaminationProfile.event?.closed) {
            return (
              <span className="w-fit text-white text-center px-2 py-1 rounded bg-blue-500 cursor-default">
                In Event
              </span>
            );
          } else {
            const time = getRemainingCertificateTime(athlete);
            if (time) {
              const { years, months, days, past } = time;
              if (past) {
                // athlete.isElegible = true;
                return (
                  <>
                    <Tippy
                      content={`${years} years, ${months} months, ${days} days`}
                    >
                      <span className="text-white text-center px-2 py-1 rounded bg-green-500 cursor-default">
                        Eligible
                      </span>
                    </Tippy>
                  </>
                );
              } else {
                // athlete.isElegible = false;
                return (
                  <Tippy
                    content={`${years} years, ${months} months, ${days} days`}
                  >
                    <span className="text-white text-center px-2 py-1 rounded bg-orange-500 cursor-default">
                      Not Eligible
                    </span>
                  </Tippy>
                );
              }
            } else {
              return (
                <span className="text-white text-center px-2 py-1 rounded bg-red-500 cursor-default">
                  No Dan
                </span>
              );
            }
          }
        }
      },
    },
    {
      label: 'Profile Status',
      render: (athlete) =>
        athlete.profileLocked ? (
          <span className="text-white text-center px-2 py-1 rounded bg-green-500">
            Locked
          </span>
        ) : (
          <span className="text-white text-center px-2 py-1 rounded bg-red-500">
            Unlocked
          </span>
        ),
    },

    {
      label: 'Actions',
      render: (athlete) => (
        <div className="flex gap-2 text-gray-800 text-lg cursor-pointer">
          <Tippy content="View">
            <Link to={`/dashboard/athletes/view/${athlete._id}`}>
              <HiEye />
            </Link>
          </Tippy>
          <Tippy content="Edit">
            <Link to={`/dashboard/athletes/edit/${athlete._id}`}>
              <HiPencilSquare />
            </Link>
          </Tippy>
          {user.role === 'admin' && (
            <Tippy content="Delete">
              <button onClick={() => handleDeleteAthlete(athlete._id)}>
                <HiTrash />
              </button>
            </Tippy>
          )}
        </div>
      ),
    },
  ];

  // if (allAthletes || trainerAthletes) {
  const finalData = useMemo(() => {
    return allAthletesLoading === false && trainerAthletesLoading === false
      ? allAthletes || trainerAthletes
      : Array.from({ length: 50 }).fill({ loading: true });
  }, [
    allAthletes,
    allAthletesLoading,
    trainerAthletes,
    trainerAthletesLoading,
  ]);
  let filteredData = finalData;

  if (allAthletesLoading === false && trainerAthletesLoading === false) {
    if (athleteNameSearchTerm) {
      filteredData = filteredData?.filter((athlete) => {
        return athlete?.fullNameEn
          ?.toLowerCase()
          .includes(athleteNameSearchTerm.toLowerCase());
      });
    }

    if (athleteProvinceSearch?.value) {
      filteredData = filteredData.filter((athlete) => {
        return athlete.club.province
          .toLowerCase()
          .includes(athleteProvinceSearch.value.toLowerCase());
      });
    }

    if (athleteDanSearch?.value) {
      filteredData = filteredData.filter((athlete) => {
        return (
          athlete.danGradesKukkiwon.slice(-1)[0]?.dan === athleteDanSearch.value
        );
      });
    }

    if (athleteClubSearchTerm?.value) {
      filteredData = filteredData.filter((athlete) => {
        return athlete.club._id === athleteClubSearchTerm.value;
      });
    }
    if (athletePromoEligibilityFilter) {
      filteredData = filteredData.filter((athlete) => {
        const time = getRemainingCertificateTime(athlete);
        if (time) {
          if (athletePromoEligibilityFilter.value === 'Eligible') {
            return time.past;
          }

          if (athletePromoEligibilityFilter.value === 'Not Eligible') {
            return time.future;
          }
        }
        if (athletePromoEligibilityFilter.value === 'No Dan' && !time) {
          return true;
        }
      });
    }
  }

  return (
    <div className="flex flex-col gap-4 h-full">
      <Panel className={'flex gap-4 flex-col'}>
        <div className="flex justify-between">
          <MainHeading>
            Athletes{' '}
            {province && ` | ${province[0].toUpperCase() + province.slice(1)}`}
            {` | Results: ${
              filteredData[0]?.loading ? 0 : filteredData.length
            }`}
          </MainHeading>
          <Button onClick={() => navigate(-1)}>back</Button>
        </div>
      </Panel>
      <div className={`p-4 drop-shadow-md rounded-md z-[50] bg-gray-700`}>
        <SearchBar
          clubs={user.role === 'trainer' ? null : clubsApi}
          pageType="athletes"
        />
      </div>
      <Panel className="h-full">
        <Scrollbar isLoading={allAthletesLoading || trainerAthletesLoading}>
          <SortableTable config={config} data={filteredData} />
        </Scrollbar>
      </Panel>
    </div>
  );
  // }
}

export default AllAthletesPage;
