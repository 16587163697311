import { Suspense, lazy, useEffect } from 'react';
import MainHeading from '../../../components/uiComponents/MainHeading';
import Panel from '../../../components/uiComponents/Panel';
import {
  setShowManageExamEventCertificatesModal,
  useGetAllClubsQuery,
  useGetEventQuery,
} from '../../../store';
import SortableTable from '../../../components/uiComponents/SortableTable';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { HiEye, HiPencilSquare, HiAcademicCap } from 'react-icons/hi2';
import { useDispatch, useSelector } from 'react-redux';
import SearchBar from '../../../components/uiComponents/SearchBar';
import Tippy from '@tippyjs/react';
import Scrollbar from '../../../components/uiComponents/Scrollbar';
import Button from '../../../components/uiComponents/Button';
import {
  getExaminationProfile,
  showLoadingEffect,
} from '../../../utils/helpers';
import Modal from '../../../components/uiComponents/Modal';
import SuspenseLoading from '../../../components/uiComponents/SuspenseLoading';

const ManageExamEventCertificates = lazy(() =>
  import('../../../components/examination/ManageExamEventCertificates')
);

function ExamParticipantsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { eventId } = useParams();

  const {
    athleteNameSearchTerm,
    athleteClubSearchTerm,
    athleteProvinceSearch,
    athleteDanSearch,
    athleteFeeSearchTerm,
    athleteStatusSearchTerm,
    showManageExamEventCertificatesModal,
  } = useSelector((state) => state.data);
  const { user } = useSelector((state) => state.authentication);

  const {
    data: eventApi,
    isLoading: eventLoading,
    isError: eventError,
  } = useGetEventQuery({ eventType: 'examination', eventId });

  const {
    data: clubs,
    isLoading: clubsIsLoading,
    isError: clubsIsError,
    error: clubsError,
  } = useGetAllClubsQuery();

  useEffect(() => {
    showLoadingEffect(eventApi, eventLoading, eventError);
  }, [eventApi, eventError, eventLoading]);
  useEffect(() => {
    showLoadingEffect(clubs, clubsIsLoading, clubsIsError, clubsError);
  }, [clubs, clubsError, clubsIsError, clubsIsLoading]);

  const config = [
    {
      label: 'Athlete',
      render: (athlete) => athlete.fullNameEn,
    },
    {
      label: 'Club',
      render: (athlete) => athlete?.club?.name,
    },
    {
      label: 'Applied Dan',
      render: (athlete) => athlete.examinationProfiles.slice(-1)[0].appliedDan,
    },
    {
      label: 'Fee Status',
      render: (athlete) => {
        const examinationProfile = getExaminationProfile(athlete, eventId)[0];
        return examinationProfile?.feePaid ? (
          <span className="text-white text-center px-2 py-1 rounded bg-green-500">
            Approved
          </span>
        ) : (
          <span className="text-white text-center px-2 py-1 rounded bg-red-500">
            Pending
          </span>
        );
      },
    },
    {
      label: 'Promotion Status',
      render: (athlete) => {
        const examinationProfile = getExaminationProfile(athlete, eventId)[0];

        if (examinationProfile?.promotionStatus === 'uninitialized') {
          return (
            <span className="text-white text-center px-2 py-1 rounded bg-gray-800">
              Uninitialized
            </span>
          );
        } else if (examinationProfile?.promotionStatus === 'failed') {
          return (
            <span className="text-white text-center px-2 py-1 rounded bg-red-500">
              Failed
            </span>
          );
        } else if (examinationProfile?.promotionStatus === 'passed') {
          return (
            <span className="text-white text-center px-2 py-1 rounded bg-green-500">
              Passed
            </span>
          );
        } else if (examinationProfile?.promotionStatus === 'conditional') {
          return (
            <span className="text-white text-center px-2 py-1 rounded bg-yellow-500">
              Conditional
            </span>
          );
        }
      },
    },
    {
      label: 'Actions',
      render: (athlete) => (
        <div className="flex gap-2 text-gray-800 text-lg cursor-pointer">
          <Tippy content="View">
            <Link
              to={`/dashboard/events/examination/participants/${eventId}/view/${athlete._id}`}
            >
              <HiEye />
            </Link>
          </Tippy>
          {user?.role === 'admin' && (
            <Tippy content="Start Exam">
              <Link
                to={`/dashboard/events/examination/participants/${eventId}/start/${athlete._id}`}
              >
                <HiAcademicCap />
              </Link>
            </Tippy>
          )}
          {user.role === 'admin' ||
            user.role === 'moderator' ||
            (user._id === athlete.club.trainer && (
              <Tippy content="Edit">
                <Link to={`/dashboard/athletes/edit/${athlete._id}`}>
                  <HiPencilSquare />
                </Link>
              </Tippy>
            ))}
        </div>
      ),
    },
  ];

  if (eventApi) {
    let filteredData = eventApi.participants;

    config.splice(1, 0, {
      label: 'Province',
      render: (athlete) => athlete.club?.province,
    });

    filteredData = filteredData.filter((athlete) => {
      return athlete.fullNameEn
        .toLowerCase()
        .includes(athleteNameSearchTerm.toLowerCase());
    });

    if (athleteProvinceSearch?.value) {
      filteredData = filteredData.filter((athlete) => {
        return athlete.club.province
          .toLowerCase()
          .includes(athleteProvinceSearch.value?.toLowerCase());
      });
    }

    if (athleteDanSearch?.value) {
      filteredData = filteredData.filter((athlete) => {
        return (
          getExaminationProfile(athlete, eventId)[0]?.appliedDan ===
          athleteDanSearch.value
        );
      });
    }

    if (athleteFeeSearchTerm?.value) {
      filteredData = filteredData.filter((athlete) => {
        const status = getExaminationProfile(athlete, eventId)[0]?.feePaid
          ? 'approved'
          : 'pending';
        return status === athleteFeeSearchTerm.value;
      });
    }

    if (athleteStatusSearchTerm?.value) {
      filteredData = filteredData.filter((athlete) => {
        return (
          getExaminationProfile(athlete, eventId)[0]?.promotionStatus ===
          athleteStatusSearchTerm.value
        );
      });
    }

    filteredData = filteredData.map((athlete) => {
      const examProfile = getExaminationProfile(athlete, eventId);
      return { ...athlete, ...examProfile };
    });

    if (athleteClubSearchTerm?.value) {
      filteredData = filteredData.filter((athlete) => {
        return athlete.club._id === athleteClubSearchTerm.value;
      });
    }

    return (
      <div className="flex flex-col gap-4 h-full">
        <Panel className={'flex gap-4 flex-col'}>
          <div className="flex justify-between items-center">
            <MainHeading>
              Exam Participants {` | Results: ${filteredData.length}`}
            </MainHeading>
            <div className="hidden md:flex gap-2">
              {user.role === 'admin' && (
                <>
                  <Suspense fallback={<SuspenseLoading />}>
                    {showManageExamEventCertificatesModal && (
                      <Modal
                        setFunction={setShowManageExamEventCertificatesModal}
                      >
                        <ManageExamEventCertificates />
                      </Modal>
                    )}
                  </Suspense>

                  <Button
                    onClick={() =>
                      dispatch(setShowManageExamEventCertificatesModal())
                    }
                  >
                    Manage
                  </Button>
                </>
              )}
              <Button onClick={() => navigate(-1)}>Back</Button>
              {/* <NavLink
                to={'/dashboard'}
                className="h-fit uppercase py-2 px-3 font-bold bg-gray-700 text-white rounded-md shadow hover:bg-gray-800 transition"
              >
                Dashboard
              </NavLink> */}
            </div>
          </div>
        </Panel>
        <Panel className="md:hidden">
          <div className="flex flex-row gap-2">
            {user.role === 'admin' && (
              <>
                <Suspense fallback={<SuspenseLoading />}>
                  {showManageExamEventCertificatesModal && (
                    <Modal
                      setFunction={setShowManageExamEventCertificatesModal}
                    >
                      <ManageExamEventCertificates />
                    </Modal>
                  )}
                </Suspense>

                <Button
                  onClick={() =>
                    dispatch(setShowManageExamEventCertificatesModal())
                  }
                >
                  Manage
                </Button>
              </>
            )}
            <Button onClick={() => navigate(-1)}>Back</Button>
          </div>
        </Panel>
        <div className={`p-4 drop-shadow-md rounded-md z-[50] bg-gray-700`}>
          <SearchBar clubs={clubs} pageType={'examination'} />
        </div>
        <Panel className="h-full">
          <Scrollbar>
            <SortableTable config={config} data={filteredData} />
          </Scrollbar>
        </Panel>
      </div>
    );
  }
}

export default ExamParticipantsPage;
