import { useState } from 'react';

function Component({ setPresentation, presentation }) {
  const points = [
    2.0, 1.9, 1.8, 1.7, 1.6, 1.5, 1.4, 1.3, 1.2, 1.1, 1.0, 0.9, 0.8, 0.7, 0.6,
    0.5,
  ];

  const [isActive, setIsActive] = useState(true);

  const handleClick = (point) => {
    setPresentation(point);
    setIsActive(true);
  };
  const renderedComponent = points.map((point) => {
    return (
      <li
        onClick={() => handleClick(point)}
        key={point}
        className={`${
          isActive && presentation === point
            ? 'bg-violet-600 border-2 border-violet-300 sm:text-sm lg:text-2xl'
            : ''
        } lg:w-20 lg:h-20 lg:text-3xl md:w-16  sm:w-40 sm:h-8 bg-gray-800 sm:text-[18px] md:h-10 text-white flex justify-center items-center font-bold text-3xl rounded-lg cursor-pointer`}
      >
        {point.toFixed(1)}
      </li>
    );
  });
  return (
    <ul className="flex sm:gap-1 lg:gap-2 justify-center">
      {renderedComponent}
    </ul>
  );
}

export default Component;
