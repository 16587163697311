import { useNavigate } from 'react-router-dom';
import MainHeading from '../components/uiComponents/MainHeading';
import Panel from '../components/uiComponents/Panel';
import AthleteExamForm from '../components/athlete/AthleteExamForm';
import { useState } from 'react';
import Button from '../components/uiComponents/Button';

function AthleteExaminationPage() {
  const [athleteName, setAthleteName] = useState('');
  const navigate = useNavigate();

  const handleSetAthleteName = (name) => {
    setAthleteName(name);
  };

  return (
    <>
      <Panel className={'mb-4'}>
        <div className="flex justify-between">
          <MainHeading>Athlete Exam | {athleteName}</MainHeading>
          <div className="flex gap-2">
            <Button onClick={() => navigate(-1)}>back</Button>
            {/* <NavLink to={'/dashboard'}>Dashboard</NavLink> */}
          </div>
        </div>
      </Panel>
      <Panel className={'h-fit'}>
        <AthleteExamForm handleSetAthleteName={handleSetAthleteName} />
      </Panel>
    </>
  );
}

export default AthleteExaminationPage;
