import { Link, useNavigate, useParams } from 'react-router-dom';
import MainHeading from '../components/uiComponents/MainHeading';
import Panel from '../components/uiComponents/Panel';
import Scrollbars from 'rc-scrollbars';
import SearchBar from '../components/uiComponents/SearchBar';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setActiveTab,
  setShowClubModal,
  setShowViewClubModal,
  useGetAllClubsQuery,
} from '../store';
import { HiEye, HiPencilSquare } from 'react-icons/hi2';
import Tippy from '@tippyjs/react';
import Modal from './../components/uiComponents/Modal';
import ClubItem from './../components/clubs/ClubItem';
import ClubForm from './../components/userAndClub/ClubForm';
import Table from '../components/uiComponents/Table';
import Button from '../components/uiComponents/Button';
function ClubsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { action } = useParams();

  const { showViewClubModal, showClubModal } = useSelector(
    (state) => state.data
  );
  const { clubNameSearchTerm, athleteProvinceSearch: clubProvinceSearch } =
    useSelector((state) => state.data);
  const {
    data: clubs,
    isLoading: clubsIsLoading,
    isError: clubsIsError,
    error: clubsError,
  } = useGetAllClubsQuery();

  useEffect(() => {
    // showLoadingEffect(clubs, clubsIsLoading, clubsIsError, clubsError);
    if (clubsIsError) {
      console.log(clubsError);
    }
  }, [clubsError, clubsIsError]);

  useEffect(() => {
    dispatch(setActiveTab('Clubs'));
  }, [dispatch]);

  const config = [
    { label: 'Club Name', render: (club) => club.name },
    {
      label: 'Trainer',
      render: (club) =>
        `${club.trainer?.firstName}${
          club.trainer?.middleName ? ` ${club.trainer?.middleName}` : ''
        } ${club.trainer?.lastName}`,
    },
    { label: 'Province', render: (club) => club.province },
    {
      label: 'Actions',
      render: (club) => (
        <div className="flex gap-2 text-gray-800 text-lg cursor-pointer">
          <Tippy content="View">
            <Link
              state={{ club }}
              onClick={() => dispatch(setShowViewClubModal(true))}
              to={`/dashboard/clubs/view/${club._id}`}
            >
              <HiEye />
            </Link>
          </Tippy>
          <Tippy content="Edit">
            <Link
              state={{ club }}
              onClick={() => dispatch(setShowClubModal(true))}
              to={`/dashboard/clubs/edit/${club._id}`}
            >
              <HiPencilSquare />
            </Link>
          </Tippy>
        </div>
      ),
    },
  ];

  let filteredData = clubs || [];

  if (clubNameSearchTerm) {
    filteredData = filteredData.filter((club) =>
      club.name.toLowerCase().includes(clubNameSearchTerm.toLowerCase())
    );
  }
  if (clubProvinceSearch) {
    filteredData = filteredData.filter((club) => {
      return club.province === clubProvinceSearch.value;
    });
  }

  if (clubsIsLoading) {
    filteredData = Array.from({ length: 25 }).fill({ loading: true });
  }
  return (
    <div className="flex flex-col gap-4 h-full">
      <Panel className="print:hidden">
        <div className="flex justify-between items-center">
          <MainHeading className={'text-center'}>Clubs</MainHeading>
          <div className="flex flex-col md:flex-row gap-2">
            <Button onClick={() => navigate(-1)}>back</Button>
            {/* <Link
                to={'/dashboard'}
                className="uppercase h-8 w-auto py-1 px-2 md:h-10 md:px-3 md:p-2 font-bold bg-gray-700 text-white rounded-md shadow hover:bg-gray-800 transition"
              >
                Dashboard
              </Link> */}
          </div>
        </div>
      </Panel>
      {/* <div className="flex flex-col gap-4"> */}
      {action === 'view' && showViewClubModal && (
        <Modal setFunction={setShowViewClubModal}>
          <ClubItem />
        </Modal>
      )}
      {action === 'edit' && showClubModal && (
        <Modal setFunction={setShowClubModal}>
          <ClubForm />
        </Modal>
      )}
      <div className={`block p-4 drop-shadow-md rounded-md z-10 bg-gray-700`}>
        <SearchBar pageType="clubs" />
      </div>
      <Panel className="z-0 h-full">
        <Scrollbars className="rounded-md">
          <Table config={config} data={filteredData} />
        </Scrollbars>
      </Panel>
      {/* </div> */}
    </div>
  );
}

export default ClubsPage;
