import ANTFLogo from '../../assets/img/antf-logo.png';
import KukkiwonLogo from '../../assets/img/kukkiwon-logo.png';
import { getExaminationProfile } from '../../utils/helpers';

function Monitor() {
  const data = {};

  if (!data) {
    return (
      <div className="h-screen w-screen bg-indigo-700 p-12 flex flex-col justify-center items-center">
        <div className="flex justify-between w-full px-4">
          <div>
            <img
              className="w-64 h-auto "
              src={ANTFLogo}
              alt="Afghanistan National Taekwondo Federation Logo"
            />
          </div>
          <div className="flex flex-col gap-4">
            <h1 className="text-center text-4xl font-bold text-white">
              Afghanistan National Taekwondo Federation
            </h1>
            <h1 className="text-center text-4xl font-bold text-white">
              Examination Committee
            </h1>
            <h1 className="text-center text-4xl font-semibold text-white">
              Dan Grade Promotion Test
            </h1>
          </div>
          <div>
            <img
              className="w-64 h-auto "
              src={KukkiwonLogo}
              alt="World Taekwondo Federation Logo"
            />
          </div>
        </div>
      </div>
    );
  }

  const activeExaminationApi = { data: '', id: '' };
  if (data && activeExaminationApi) {
    const athlete = data[0];
    const examination = activeExaminationApi.data;

    const testsObj = getExaminationProfile(athlete, examination._id);

    const testsArray = Object.entries(testsObj.promotionTests);

    const examProfiles = testsArray.map((test, i) => {
      const title = `${test[0].split(/(?=[A-Z])/)[0][0].toUpperCase()}${test[0]
        .split(/(?=[A-Z])/)[0]
        .slice(1)} ${
        test[0].split(/(?=[A-Z])/)[1] ? test[0].split(/(?=[A-Z])/)[1] : ''
      } ${test[0].split(/(?=[A-Z])/)[2] ? test[0].split(/(?=[A-Z])/)[2] : ''}`;
      return (
        <div key={i}>
          <div
            className={`flex flex-col h-40 text-xl ${
              test[1].score >= 5 ? `bg-green-500` : `bg-red-500`
            } text-white font-bold py-5 rounded-xl shadow border border-white transition-all`}
          >
            <h3 className="text-center">{title}</h3>
            <p className="text-7xl text-center">{test[1].score}</p>
          </div>
        </div>
      );
    });

    return (
      <div className="h-screen w-screen bg-indigo-700 p-12 flex flex-col items-center">
        <div className="flex justify-between w-full px-4">
          <div>
            <img
              className="w-64 h-auto "
              src={ANTFLogo}
              alt="Afghanistan National Taekwondo Federation Logo"
            />
          </div>
          <div className="flex flex-col gap-4">
            <h1 className="text-center text-4xl font-bold text-white">
              Afghanistan National Taekwondo Federation
            </h1>
            <h1 className="text-center text-4xl font-bold text-white">
              Examination Committee
            </h1>
            <h1 className="text-center text-4xl font-semibold text-white">
              Dan Grade Promotion Test
            </h1>
          </div>
          <div>
            <img
              className="w-64 h-auto "
              src={KukkiwonLogo}
              alt="World Taekwondo Federation Logo"
            />
          </div>
        </div>

        <div className=" h-full w-4/5 mt-24 flex flex-col gap-8">
          <div className="flex justify-between text-white text-4xl font-semibold ">
            <h1>
              {athlete.fullNameEn} | Dan {testsObj.appliedDan}
            </h1>
            <h1 className="font-arabic">{athlete.fullNameDr}</h1>
          </div>
          <div className="w-full mx-auto grid grid-cols-5 grid-rows-2 gap-4">
            {examProfiles}
          </div>
        </div>
      </div>
    );
  }
}

export default Monitor;
