import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { authReducer } from './slices/authSlice';
import { mainApi } from './apis/mainApi';
import { dataReducer } from './slices/dataSlice';

const store = configureStore({
  reducer: {
    authentication: authReducer,
    data: dataReducer,
    [mainApi.reducerPath]: mainApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(mainApi.middleware);
  },
});

setupListeners(store.dispatch);

export { store };
export {
  useUserLoginMutation,
  useVerifyUserLoginQuery,
  useLazyGetAllDanGradeCertificatesQuery,
  useUserLogoutMutation,
  useUserSignupMutation,
  useGetAllUsersQuery,
  useUpdateMeMutation,
  useUpdateMyPasswordMutation,
  useGetAllTrainersQuery,
  useGetTrainerClubQuery,
  useGetAllAthletesQuery,
  useGetAllAthletesTrainerQuery,
  useGetAllEventsQuery,
  useGetEventQuery,
  useGetAthleteQuery,
  useGetOperationalDataQuery,
  useLazyAthleteLookupQuery,
  useAddAthleteMutation,
  useAddEventMutation,
  useAddCertificateMutation,
  useAddAthleteRefEventMutation,
  useSubmitPoomsaePointMutation,
  useDeletePoomsaePointMutation,
  useAddPoomsaeTeamAndDuoMutation,
  useRemovePoomsaeTeamAndDuoMutation,
  useAddPenaltyMutation,
  useDeletePenaltyMutation,
  useRemoveAthleteRefEventMutation,
  useDeleteAthleteMutation,
  useUpdateAthleteMutation,
  useUpdateEventMutation,
  useUpdateExamProfileMutation,
  useUpdatePoomsaeProfileMutation,
  useUpdateOperationalDataMutation,
  useAddClubMutation,
  useUpdateClubMutation,
  useGetAllClubsQuery,
  useAddAthleteRefToClubMutation,
} from './apis/mainApi';

export {
  setActiveTab,
  setAthleteNameSearchTerm,
  setAthleteClubSearchTerm,
  setAthleteProvinceSearch,
  setAthleteDanSearch,
  setAthletePromoEligibilityFilter,
  setAthleteFeeSearchTerm,
  setAthleteStatusSearchTerm,
  setPoomsaeCategorySearchTerm,
  setPoomsaeFinalSearchTerm,
  setPoomsaePreliminarySearchTerm,
  setPoomsaeSemiFinalSearchTerm,
  setEventNameSearchTerm,
  setEventStatusFilterTerm,
  setEventTypeFilterTerm,
  setClubNameSearchTerm,
  setMenuIsOpen,
  setShowUserProfileModal,
  setShowCertificateModal,
  setShowClubModal,
  setShowViewClubModal,
  setShowExamModal,
  setShowManageExamEventCertificatesModal,
  setShowUserModal,
  setShowPoomsaeControlsModal,
  setShowJudgeConfirmRoleModal,
  setPoomsaeActiveStage,
  resetPoomsaeStopwatch,
  setPoomsaeStopwatchRunning,
  setPoomsaeStopwatchTime,
  setPoomsaeJudgeRole,
  setPageScrollPosition,
} from './slices/dataSlice';

export { login, logout, setUser } from './slices/authSlice';
