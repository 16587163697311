import { useEffect } from 'react';
import { setActiveTab, useGetAllUsersQuery } from '../../store';
import Panel from './../uiComponents/Panel';
import SortableTable from '../uiComponents/SortableTable';
import Tippy from '@tippyjs/react';
import { HiPencilSquare } from 'react-icons/hi2';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

function UsersList() {
  const dispatch = useDispatch();
  const {
    data: users,
    isLoading: usersIsLoading,
    isError: usersIsError,
    error: usersError,
  } = useGetAllUsersQuery();

  useEffect(() => {
    if (usersIsError) {
      console.log(usersError);
    }
    // showLoadingEffect(users, usersIsLoading, usersIsError, usersError);
  }, [users, usersError, usersIsError, usersIsLoading]);

  useEffect(() => {
    dispatch(setActiveTab('Users'));
  }, [dispatch]);
  const config = [
    {
      label: 'User',
      render: (user) =>
        `${user.firstName} ${user.middleName ? `${user.middleName} ` : ' '}${
          user.lastName
        }`,
    },
    {
      label: 'Username',
      render: (user) => user.username,
    },
    { label: 'Role', render: (user) => user.role },
    {
      label: 'Actions',
      render: (user) => {
        return (
          <div className="flex gap-2 text-gray-800 text-lg cursor-pointer">
            {/* <Tippy content="View">
              <Link to={`view/${user._id}`}>
                <HiEye />
              </Link>
            </Tippy> */}
            <Tippy content="Edit">
              <Link to={`/dashboard/users/edit/${user._id}`}>
                <HiPencilSquare />
              </Link>
            </Tippy>
          </div>
        );
      },
    },
  ];

  let usersData = users;

  if (usersIsLoading) {
    usersData = Array.from({ length: 25 }).fill({ loading: true });
  }

  return (
    <Panel>
      <SortableTable data={usersData} config={config} />
    </Panel>
  );
}

export default UsersList;
