import Panel from '../components/uiComponents/Panel';
import MainHeading from '../components/uiComponents/MainHeading';
import BigButton from '../components/uiComponents/BigButton';
import {
  HiAcademicCap,
  HiBars3,
  HiDocumentPlus,
  HiMiniBuildingStorefront,
  HiMiniUserPlus,
  HiUserGroup,
} from 'react-icons/hi2';
import {
  setMenuIsOpen,
  setShowUserModal,
  setShowClubModal,
  setShowExamModal,
  setActiveTab,
  useVerifyUserLoginQuery,
  logout,
} from '../store';

import { useDispatch, useSelector } from 'react-redux';
import Modal from '../components/uiComponents/Modal';
import { setShowCertificateModal } from '../store';
import CertificateForm from '../components/athlete/CertificateForm';
import UserForm from '../components/userAndClub/UserForm';
import ClubForm from '../components/userAndClub/ClubForm';
import BigButtonModal from '../components/uiComponents/BigButtonModal';
import EventForm from '../components/events/EventForm';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.authentication);
  const { showCertificateModal, showUserModal, showClubModal, showExamModal } =
    useSelector((state) => state.data);

  const { data: verifyUserData, error: userVerificationError } =
    useVerifyUserLoginQuery();

  const handleMenuClick = () => {
    dispatch(setMenuIsOpen());
  };

  const date = new Date().toLocaleString('en-us', {
    // weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  useEffect(() => {
    if (userVerificationError) {
      console.log(userVerificationError);
      dispatch(logout());
      navigate('/');
    } else return;
  }, [verifyUserData, dispatch, navigate, userVerificationError]);

  useEffect(() => {
    if (user.role === 'poomsaeJudge') {
      navigate('/poomsaeScoringApp', { replace: true });
    }
  }, [navigate, user]);

  useEffect(() => {
    dispatch(setActiveTab('Dashboard'));
  }, [dispatch]);
  return (
    <div className="flex flex-col gap-4 h-full">
      <Panel className="w-full flex justify-between items-center align-center">
        <MainHeading>Dashboard</MainHeading>
        <MainHeading>{`${date} `}</MainHeading>
        <div
          onClick={handleMenuClick}
          className="self-center text-2xl text-gray-800 cursor-pointer md:hidden"
        >
          <HiBars3 />
        </div>
      </Panel>
      <div className="flex flex-col md:grid md:grid-cols-[1fr_1fr] gap-4">
        <div className="flex flex-col md:grid md:grid-cols-[1fr_1fr] gap-4 md:col-span-2">
          <Panel className="flex flex-col gap-2 md:gap-4 justify-between w-full">
            <MainHeading>Athletes</MainHeading>
            <div className="flex gap-4">
              <BigButton
                to={'/dashboard/addAthlete'}
                icon={<HiUserGroup />}
                text={'Add Athlete'}
              />
              {user?.role === 'admin' && (
                <>
                  <BigButtonModal
                    label="Add Certificate"
                    icon={<HiDocumentPlus />}
                    setFunction={setShowCertificateModal}
                  />

                  {showCertificateModal && (
                    <Modal setFunction={setShowCertificateModal}>
                      <CertificateForm />
                    </Modal>
                  )}
                </>
              )}
              {user.role === 'trainer' && (
                <BigButton
                  to={'/dashboard/athletes'}
                  icon={<HiMiniBuildingStorefront />}
                  text={'My Athletes'}
                />
              )}
            </div>
          </Panel>
          {user?.role === 'admin' && (
            <Panel className="flex flex-col gap-2">
              <MainHeading>Users and Clubs</MainHeading>
              <div className="flex gap-4">
                <>
                  <BigButtonModal
                    label="Add User"
                    icon={<HiMiniUserPlus />}
                    setFunction={setShowUserModal}
                  />
                  {showUserModal && (
                    <Modal setFunction={setShowUserModal}>
                      <UserForm />
                    </Modal>
                  )}
                </>
                <>
                  <BigButtonModal
                    label="Add Club"
                    icon={<HiMiniBuildingStorefront />}
                    setFunction={setShowClubModal}
                  />
                  {showClubModal && (
                    <Modal setFunction={setShowClubModal}>
                      <ClubForm />
                    </Modal>
                  )}
                </>
              </div>
            </Panel>
          )}
          {user?.role === 'admin' && (
            <Panel className="col-span-2 flex flex-col gap-2">
              <MainHeading>Events</MainHeading>
              <>
                <BigButtonModal
                  label="Add Event"
                  icon={<HiAcademicCap />}
                  setFunction={setShowExamModal}
                />
                {showExamModal && (
                  <Modal setFunction={setShowExamModal}>
                    <EventForm />
                  </Modal>
                )}
              </>
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
