import React, { useEffect, useState } from 'react';
import Select from '../uiComponents/Select';
import {
  setShowCertificateModal,
  useAddCertificateMutation,
  useGetAllAthletesQuery,
  useUpdateExamProfileMutation,
} from '../../store';
import { useForm } from 'react-hook-form';
import {
  showLoadingEffect,
  showLoadingNotificationEffect,
} from '../../utils/helpers';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

export default function CertificateForm({ eventParticipant }) {
  // eventParticipant will come from event certificate manager

  const { eventId } = useParams();
  const dispatch = useDispatch();

  const [selectedAthlete, setSelectedAthlete] = useState();
  const [selectedCertificateType, setSelectedCertificateType] = useState();
  const { data, isLoading, isError } = useGetAllAthletesQuery();
  const { register, handleSubmit, reset, setValue } = useForm();

  const [addCertificate, addCertificateResults] = useAddCertificateMutation();
  const [updateExamProfile, updateExamProfileResults] =
    useUpdateExamProfileMutation();

  useEffect(() => {
    showLoadingEffect(data, isLoading, isError);
  }, [data, isLoading, isError]);

  useEffect(() => {
    const { isLoading, isError, error, isSuccess } = addCertificateResults;
    showLoadingEffect(isSuccess, isLoading, isError, error);
    if (isSuccess) {
      handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCertificateResults, data]);

  useEffect(() => {
    if (eventParticipant) {
      setSelectedAthlete({
        label: eventParticipant.fullNameEn,
        value: eventParticipant._id,
      });
      setSelectedCertificateType({
        label:
          eventParticipant.certificateType.charAt(0).toUpperCase() +
          eventParticipant.certificateType.slice(1),
        value: eventParticipant.certificateType,
      });
      setValue(
        'dan',
        eventParticipant.certificateType === 'kukkiwon'
          ? eventParticipant.examinationProfile.appliedDan
          : eventParticipant.examinationProfile.appliedDan + 1
      );
      setValue(
        'danCertificate',
        eventParticipant.danGradesKukkiwon.length > 0
          ? eventParticipant.danGradesKukkiwon.slice(-1)[0].danCertificate
          : ''
      );

      // setValue('issueDate', '2024-03-14');
    }
  }, [eventParticipant, setValue]);

  useEffect(() => {
    showLoadingNotificationEffect(
      updateExamProfileResults,
      'Certificate',
      'Adding'
    );
    if (updateExamProfileResults.isSuccess) {
      dispatch(setShowCertificateModal());
    }
  }, [dispatch, updateExamProfileResults]);

  const handleFormSubmit = (data) => {
    const body = {
      id: selectedAthlete.value,
      type: selectedCertificateType.value,
      patch: { ...data },
    };
    if (eventId) body.patch.event = eventId;
    addCertificate(body);

    if (eventParticipant) {
      const body = {
        profileId: eventParticipant.examinationProfile._id,
      };
      if (eventParticipant.certificateType === 'kukkiwon')
        body.receivedKukkiwonCertificate = true;
      updateExamProfile(body);
    }
  };

  const handleReset = () => {
    reset();
    setSelectedCertificateType('');
    setSelectedAthlete('');
  };

  if (data) {
    const disableInput = eventParticipant && eventParticipant;
    const athletesOptions = data.map((athlete) => {
      return { label: athlete.fullNameEn, value: athlete._id };
    });
    const certificateOptions = [
      { label: 'Kukkiwon', value: 'kukkiwon' },
      { label: 'National', value: 'national' },
    ];
    return (
      <form
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <div className="bg-gray-100 h-full w-full rounded-lg p-4 md:p-8">
          <div className="flex flex-col gap-2">
            <div>
              <label className="font-bold text-gray-800" htmlFor="">
                Athlete
              </label>

              <Select
                required
                value={selectedAthlete}
                onChange={(value) => setSelectedAthlete(value)}
                options={athletesOptions}
                isDisabled={disableInput}
              />
            </div>
            <div>
              <label className="font-bold text-gray-800" htmlFor="">
                Certificate Type
              </label>
              <Select
                required
                value={selectedCertificateType}
                onChange={(value) => setSelectedCertificateType(value)}
                options={certificateOptions}
                isSearchable={false}
                isDisabled={disableInput}
              />
            </div>
            <div>
              <label className="font-bold text-gray-800" htmlFor="dan">
                Dan Number
              </label>
              <input
                {...register('dan')}
                required
                min={1}
                max={7}
                placeholder="1"
                className="w-full px-3 py-2 rounded shadow font-semibold hover:ring hover:ring-gray-400 transition-all ease-in  focus:ring focus:ring-gray-700 disabled:ring-0 disabled:text-gray-400"
                type="number"
                disabled={disableInput}
              />
            </div>
            <div>
              <label
                className="font-bold text-gray-800"
                htmlFor="danCertificate"
              >
                Dan Certificate
              </label>
              <input
                required
                maxLength="8"
                minLength="8"
                placeholder="0156892"
                className="w-full px-3 py-2 rounded shadow font-semibold hover:ring hover:ring-gray-400 transition-all ease-in  focus:ring focus:ring-gray-700"
                {...register('danCertificate')}
                type="text"
              />
            </div>
            <div>
              <label className="font-bold text-gray-800" htmlFor="issueDate">
                Issue Date
              </label>
              <input
                required
                placeholder=""
                className="w-full px-3 py-2 rounded shadow font-semibold hover:ring hover:ring-gray-400 transition-all ease-in  focus:ring focus:ring-gray-700"
                {...register('issueDate')}
                type="date"
              />
            </div>
            <button
              type="submit"
              className="uppercase font-bold text-white rounded w-full px-2 py-3 bg-gray-700 hover:bg-gray-800 transition-all"
            >
              submit
            </button>
            <button
              onClick={handleReset}
              type="reset"
              className="uppercase font-bold text-white rounded w-full px-2 py-3 bg-red-500 hover:bg-red-600 transition-all"
            >
              Reset
            </button>
          </div>
        </div>
      </form>
    );
  }
}
