import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
function NavLink({ link, icon, ...rest }) {
  const { activeTab } = useSelector((state) => state.data);
  const classes = classNames(
    link.label === activeTab &&
      'font-bold bg-gray-600 text-white hover:bg-gray-800'
  );

  return (
    <div
      className={`relative flex items-center gap-2 hover:bg-gray-300 rounded-lg p-2 text-gray-800 ${classes} transition-all`}
    >
      <div className="text-xl">{icon}</div>
      <Link to={link.path} {...rest} className="w-full font-semibold ">
        {link.label}
      </Link>
    </div>
  );
}

export default NavLink;
