import React, { useEffect, useMemo, useState } from 'react';
import Panel from '../../components/uiComponents/Panel';
import MainHeading from '../../components/uiComponents/MainHeading';
import { useNavigate, useParams } from 'react-router-dom';
import Scrollbars from 'rc-scrollbars';
import {
  setShowExamModal,
  useAddAthleteRefEventMutation,
  useGetAllAthletesQuery,
  useGetAllAthletesTrainerQuery,
  useGetEventQuery,
  useRemoveAthleteRefEventMutation,
} from '../../store';
import Notiflix from 'notiflix';
import {
  HiMiniPlusCircle,
  HiMiniXCircle,
  HiExclamationTriangle,
} from 'react-icons/hi2';
import { useDispatch, useSelector } from 'react-redux';
import SortableTable from '../../components/uiComponents/SortableTable';
import Tippy from '@tippyjs/react';
import NavLink from '../../components/uiComponents/NavLink';
import Button from '../../components/uiComponents/Button';
import Modal from '../../components/uiComponents/Modal';
import EventForm from '../../components/events/EventForm';
import { getAge, showLoadingNotificationEffect } from '../../utils/helpers';
import {
  setActiveTab,
  setShowTeamAndDuoCreationModal,
} from '../../store/slices/dataSlice';
import TeamAndDuoCreationForm from '../../components/poomsae/TeamAndDuoCreationForm';
import EventPageInfo, {
  EventPageInfoSkeleton,
} from '../../components/events/EventPageInfo';

export default function EventPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { eventId, eventType } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [showRegisteryClose, setShowRegisteryClose] = useState(false);

  const { user } = useSelector((state) => state.authentication);
  const { showExamModal, showTeamAndDuoCreationModal } = useSelector(
    (state) => state.data
  );

  const {
    data: trainerAthletes,
    isLoading: trainerAthletesIsLoading,
    isError: trainerAthletesIsError,
    error: trainerAthletesError,
  } = useGetAllAthletesTrainerQuery(user._id, {
    skip: user.role !== 'trainer',
  });

  const {
    data: allAthletes,
    isLoading: allAthletesIsLoading,
    isError: allAthletesIsError,
    error: allAthletesError,
  } = useGetAllAthletesQuery('', { skip: user.role === 'trainer' });

  const {
    data: event,
    isLoading: eventLoading,
    isError: eventError,
  } = useGetEventQuery({ eventType, eventId });

  const [addAthleteRef, addAthleteRefResults] = useAddAthleteRefEventMutation();
  const [removeAthleteRef, removeAthleteRefResults] =
    useRemoveAthleteRefEventMutation();

  useEffect(() => {
    if (eventError) {
      console.log(eventError);
    }

    if (allAthletesIsError) {
      console.log(allAthletesError);
    }
    if (trainerAthletesIsError) {
      console.log(trainerAthletesError);
    }
  }, [
    allAthletesError,
    allAthletesIsError,
    eventError,
    trainerAthletesError,
    trainerAthletesIsError,
  ]);

  useEffect(() => {
    dispatch(setActiveTab('Events'));
  }, [dispatch]);

  useEffect(() => {
    showLoadingNotificationEffect(
      removeAthleteRefResults,
      'Athlete',
      'Removing'
    );
  }, [removeAthleteRefResults]);

  useEffect(() => {
    if (user.role === 'trainer' && new Date() > new Date(event?.regEnd)) {
      setShowRegisteryClose(true);
    } else {
      setShowRegisteryClose(false);
    }
  }, [event, user]);

  const eventParticipantsIds = useMemo(() => {
    if (event) {
      const eventParticipants = [];
      event.participants.forEach((participant) => {
        eventParticipants.push(participant._id);
      });
      return eventParticipants;
    }
  }, [event]);

  useEffect(() => {
    showLoadingNotificationEffect(addAthleteRefResults, 'Athlete', 'Adding');
    if (addAthleteRefResults.isError) {
      Notiflix.Report.merge({
        className: 'text-center',
        fontFamily: 'Bahij Nazanin',
        titleFontSize: '24px',
        messageFontSize: '16px',
        rtl: true,
      });
      if (addAthleteRefResults.error?.status === 406) {
        Notiflix.Report.failure(
          'واجد شرایط',
          'بازیکن واجد شرایط نمیباشد. بازیکن باید حد اقل کمر بند زرد بوده و باید در رده سنی ذکر شده توسط فدراسیون باشد',
          'درست است'
        );
      }
      if (addAthleteRefResults.error?.status === 410) {
        Notiflix.Report.failure(
          'واجد شرایط',
          'بازیکن واجد شرایط نمیباشد. بازیکن باید مدت زمان سرتیفیکیت خود را مکملاً سپری کرده باشد',
          'درست است'
        );
      }

      if (addAthleteRefResults.error?.status === 411) {
        Notiflix.Report.failure(
          'واجد شرایط',
          `برنامه انتخاب شده برای ولایت شما نمیباشد کلب شما: ${
            trainerAthletes[0]?.club.province
          } و ولایت مربوط برنامه: ${event.locations.join(', ')}`,
          'درست است'
        );
      }
    }
  }, [addAthleteRefResults, trainerAthletes]);

  const handleAddRemoveAthlete = (athleteId) => {
    // By default for examination
    const body = {
      eventType,
      eventId,
      athleteId,
    };

    if (eventParticipantsIds.includes(athleteId)) {
      Notiflix.Confirm.show(
        'Remove Athlete from Event',
        'Are you sure you want to remove athlete?',
        'Delete',
        'No',
        () => removeAthleteRef(body)
      );
    } else addAthleteRef(body);
  };

  // if (event && (allAthletes || trainerAthletes)) {

  let athletes = allAthletes || trainerAthletes || [];

  // Remove lower belts from examination list
  athletes = athletes.filter((athlete) => {
    if (eventType === 'examination') {
      return athlete.belt === 'black' || athlete.belt === 'red';
    } else {
      return true;
    }
  });
  const regEndDate = new Date(event?.regEnd).toLocaleDateString('en-gb');

  // const regStartDate = new Date(event.regStart).toLocaleDateString('en-gb');
  const eventDate = new Date(event?.eventDate).toLocaleDateString('en-gb');

  const config = [
    {
      label: 'Athlete',
      render: (athlete) => athlete.fullNameEn,
    },
    {
      label: 'Belt',
      render: (athlete) =>
        athlete.belt?.charAt(0).toUpperCase() + athlete.belt?.slice(1),
    },
    {
      label: 'Date of Birth',
      render: (athlete) =>
        `${new Date(athlete.dateOfBirth).toLocaleDateString('en-gb')} (${getAge(
          athlete.dateOfBirth
        )})`,
    },

    {
      label: 'Actions',
      render: (athlete) => (
        <div className="flex text-gray-800 text-lg cursor-pointer">
          <Tippy
            content={`${
              eventParticipantsIds?.includes(athlete._id) ? 'Remove' : 'Add'
            }`}
          >
            <button onClick={() => handleAddRemoveAthlete(athlete._id)}>
              {eventParticipantsIds?.includes(athlete._id) ? (
                <HiMiniXCircle />
              ) : (
                <HiMiniPlusCircle />
              )}
            </button>
          </Tippy>
        </div>
      ),
    },
  ];
  if (user.role !== 'trainer') {
    config.splice(
      3,
      0,
      {
        label: 'Club',
        render: (athlete) => athlete.club?.name,
      },
      { label: 'Province', render: (athlete) => athlete.club?.province }
    );
  }

  let participatingAthletes = athletes
    .filter((athlete) => {
      return eventParticipantsIds?.includes(athlete._id);
    })
    .filter((athlete) =>
      athlete.fullNameEn?.toLowerCase().includes(searchTerm)
    );
  let notParticipatingAthletes = athletes
    .filter((athlete) => {
      return !eventParticipantsIds?.includes(athlete._id);
    })
    .filter((athlete) =>
      athlete.fullNameEn?.toLowerCase().includes(searchTerm)
    );

  // for skeleton loading, table component will render skeleton for each element of the below array
  if (eventLoading || allAthletesIsLoading || trainerAthletesIsLoading) {
    participatingAthletes = Array.from({ length: 50 }).fill({ loading: true });
    notParticipatingAthletes = Array.from({ length: 50 }).fill({
      loading: true,
    });
  }

  if (event && (eventType === 'poomsae') & (event.ageLimit > 0)) {
    notParticipatingAthletes = notParticipatingAthletes.filter((athlete) => {
      return getAge(athlete.dateOfBirth) <= event.ageLimit;
    });
  }

  return (
    <Scrollbars>
      <div className="flex flex-col gap-2 md:gap-4 h-full">
        <Panel className="">
          <div className="flex justify-between items-center">
            <MainHeading className={'text-center'}>
              Event: {event?.title}
            </MainHeading>
            <div className="flex flex-col md:flex-row gap-2">
              <Button onClick={() => navigate(-1)}>back</Button>
              {showExamModal && (
                <>
                  <Modal setFunction={setShowExamModal}>
                    <EventForm />
                  </Modal>
                </>
              )}

              {/* <NavLink to={'/dashboard'}>Dashboard</NavLink> */}
            </div>
          </div>
        </Panel>
        <div className="flex flex-col md:flex-row gap-2 md:gap-4">
          {eventLoading ? (
            <EventPageInfoSkeleton />
          ) : (
            <EventPageInfo
              event={event}
              eventDate={eventDate}
              regEndDate={regEndDate}
            />
          )}

          <Panel className={'hidden md:flex items-center'}>
            <div className="flex flex-col gap-2">
              <NavLink
                to={`/dashboard/events/${eventType}/participants/${eventId}`}
              >
                participants
              </NavLink>
              {eventType === 'poomsae' && (
                <Button
                  onClick={() =>
                    navigate(`/dashboard/events/poomsae/results/${eventId}`)
                  }
                >
                  Results
                </Button>
              )}
              {user.role === 'admin' && (
                <Button
                  onClick={() => {
                    dispatch(setShowExamModal());
                  }}
                >
                  Edit Event
                </Button>
              )}
            </div>
          </Panel>
        </div>

        <div className="flex flex-col gap-2 md:flex-row  md:gap-4 w-full h-full">
          {!showRegisteryClose ? (
            <>
              <Panel className={'h-[800px] w-full md:h-full md:w-1/2'}>
                <div className="h-full">
                  <Scrollbars className="rounded-md">
                    <div className="flex justify-between items-center">
                      <MainHeading className={'md:text-xl mb-2'}>
                        Athletes
                      </MainHeading>
                      <div className="self-start flex items-center gap-2">
                        <label
                          htmlFor="search"
                          className="font-bold text-gray-700"
                        >
                          Search
                        </label>
                        <input
                          placeholder="Anwar Asghari"
                          className="px-2 py-1 text-sm bg-gray-50 rounded shadow font-semibold self-start"
                          type="text"
                          name="search"
                          id="search"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>
                    </div>
                    <SortableTable
                      config={config}
                      data={notParticipatingAthletes}
                    />
                  </Scrollbars>
                </div>
              </Panel>
              <Panel className={'h-[800px]  w-full md:h-full md:w-1/2'}>
                <div className="h-full">
                  <Scrollbars className="rounded-md">
                    <div className="flex justify-between items-center">
                      <MainHeading className={'md:text-xl mb-2'}>
                        Participating
                      </MainHeading>
                      {user.role === 'admin' && eventType === 'poomsae' && (
                        <>
                          <Button
                            className="mb-2 text-sm md:text-md"
                            onClick={() =>
                              dispatch(setShowTeamAndDuoCreationModal())
                            }
                          >
                            Create Teams and Duos
                          </Button>
                        </>
                      )}
                      {showTeamAndDuoCreationModal && (
                        <Modal setFunction={setShowTeamAndDuoCreationModal}>
                          <TeamAndDuoCreationForm />
                        </Modal>
                      )}
                    </div>
                    <SortableTable
                      config={config}
                      data={participatingAthletes}
                    />
                  </Scrollbars>
                </div>
              </Panel>
            </>
          ) : (
            <Panel className="w-full flex justify-center align-middle items-center">
              <div className="w-fit h-fit flex flex-col items-center justify-center align-middle rounded-lg p-4 bg-red-500  text-red-100">
                <div>
                  {<HiExclamationTriangle className="text-9xl text-center" />}
                </div>
                <div className="flex flex-col items-center text-center">
                  <p className="font-bold text-xl">
                    The registeration period has ended.
                  </p>
                  <p className="font-pashto text-2xl font-bold">
                    .د ثبتولو وخت ختم دی
                  </p>
                  <p className="font-pashto text-2xl font-bold">
                    .زمان ثبت نام ختم شده است
                  </p>
                </div>
              </div>
            </Panel>
          )}
        </div>
      </div>
    </Scrollbars>
  );
  // }
}
