import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';

export default function Modal({ setFunction, children }) {
  const dispatch = useDispatch();

  return createPortal(
    <>
      <div
        onClick={() => (setFunction ? dispatch(setFunction()) : '')}
        className="fixed top-0 bottom-0 left-0 right-0 bg-gray-800/50 z-50 flex justify-center items-center w-full h-full"
      >
        {children}
      </div>
    </>,
    document.getElementById('portal')
  );
}
