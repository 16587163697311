function Profile({ user }) {
  return (
    <>
      <img
        className="aspect-[1/1] w-20 rounded-full"
        src={require('../../assets/users/avatar.png')}
        alt="user pictures"
      />
      <p className="font-white font-bold text-center text-xl text-brown mt-2">{`${
        user.firstName
      } ${user.middleName ? `${user.middleName} ` : ''}${user.lastName} `}</p>
    </>
  );
}

export default Profile;
