import Notiflix from 'notiflix';
import {
  differenceInYears,
  differenceInMonths,
  differenceInDays,
  addMonths,
  addYears,
  isBefore,
  isAfter,
} from 'date-fns';

export const getPoomsaeProfile = (athlete, eventId) => {
  const profile = athlete.poomsaeCompetitionProfiles.filter((profile) => {
    if (profile.event._id) {
      return profile.event._id === eventId;
    } else {
      return profile.event === eventId;
    }
  });
  return profile[0];
};

export const getExaminationProfile = (athlete = [], eventId) => {
  const profile = athlete.examinationProfiles?.filter((profile) => {
    if (profile.event?._id) {
      return profile.event._id === eventId;
    } else {
      return profile.event === eventId;
    }
  });
  return profile;
};

export const showLoadingEffect = (data, isLoading, isError, error) => {
  Notiflix.Confirm.init({
    okButtonBackground: '#ef4444',
    titleColor: '#ef4444',
  });
  if (isLoading) {
    Notiflix.Loading.circle('Loading data...', { svgColor: `#cbd4cd` });
  }
  if (isError) {
    Notiflix.Loading.remove(500);
    Notiflix.Notify.failure('Failed to load data');
    console.log(error);
  }
  if (data) {
    Notiflix.Loading.remove(500);
  }
};

export const showLoadingNotificationEffect = (
  results,
  dataType,
  loadingType
) => {
  const { isLoading, isError, isSuccess, error } = results;
  if (isLoading) {
    Notiflix.Notify.info(`${loadingType} ${dataType}...`);
  }
  if (isSuccess) {
    Notiflix.Notify.success(`${dataType} ${loadingType} Successful!`);
  }
  if (isError) {
    Notiflix.Notify.failure(error.data.message);

    console.log(error);
  }
};

export const trimCapitalize = (word) => {
  return word
    .split(' ')
    .filter((el) => el !== '')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const calcAverage = function (arr) {
  // console.log(arr);
  if (arr.length > 4) {
    const min = Math.min(...arr);
    const max = Math.max(...arr);

    const sumAll = arr.reduce((acc, cur) => acc + cur);

    const average = (sumAll - min - max) / 3;

    return average;
  }
};

export const calculateAthletePoints = (profile, field) => {
  const totalAccuracy = [];
  const totalPresentation = [];

  // Calculate and save averages on player
  if (field) {
    profile[field]?.forEach(async (point) => {
      // Add points to depending array
      if (point.j1) {
        totalAccuracy.push(point.j1.accuracyPoints);
        totalPresentation.push(point.j1.presentationPoints);
      }
      if (point.j2) {
        totalAccuracy.push(point.j2.accuracyPoints);
        totalPresentation.push(point.j2.presentationPoints);
      }
      if (point.j3) {
        totalAccuracy.push(point.j3.accuracyPoints);
        totalPresentation.push(point.j3.presentationPoints);
      }
      if (point.j4) {
        totalAccuracy.push(point.j4.accuracyPoints);
        totalPresentation.push(point.j4.presentationPoints);
      }
      if (point.j5) {
        totalAccuracy.push(point.j5.accuracyPoints);
        totalPresentation.push(point.j5.presentationPoints);
      }
    });
  }

  // // Calculate Average
  const avgAccuracy = calcAverage(totalAccuracy);
  const avgPresentation = calcAverage(totalPresentation);
  const totalPoint = avgAccuracy + avgPresentation;
  // console.log(avgAccuracy, avgPresentation, totalPoint);
  // console.log(profile);
  return { avgAccuracy, avgPresentation, totalPoint };
};

export const getPenalties = (profile, stage) => {
  const penalties = profile?.penalties.map((penalty) => {
    const stageToReturn =
      stage === 'Preliminary'
        ? 'preliminary'
        : stage === 'Semi Final'
        ? 'semiFinal'
        : stage === 'Final'
        ? 'final'
        : '';
    if (penalty.round === stageToReturn) {
      return 0.3;
    }
    return 0;
  });
  return penalties?.reduce((acc, penalty) => {
    return acc + penalty;
  }, 0);
};

export const getAge = (dateOfBirth) => {
  var today = new Date();
  var birthDate = new Date(dateOfBirth);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
export const poomsaeBlackDuoCategoryOptions = [
  { label: 'Duo Black Cadet', value: 'DU14' },
  { label: 'Duo Black Junior', value: 'DU18' },
  { label: 'Duo Black Under 30', value: 'DU30' },
  { label: 'Duo Black Over 30', value: 'DO30' },
];
export const poomsaeBlackTeamCategoryOptions = [
  { label: 'Team Black Cadet', value: 'TU14' },
  { label: 'Team Black Junior', value: 'TU18' },
  { label: 'Team Black Under 30', value: 'TU30' },
  { label: 'Team Black Over 30', value: 'TO30' },
];
export const poomsaeBlackBeltOptions = [
  { label: 'Individual Black Cadet', value: 'IU14' },
  { label: 'Individual Black Junior', value: 'IU18' },
  { label: 'Individual Black Under 30', value: 'IU30' },
  { label: 'Individual Black Under 40', value: 'IU40' },
  { label: 'Individual Black Under 50', value: 'IU50' },
  { label: 'Individual Black Under 60', value: 'IU60' },
  { label: 'Individual Black Under 65', value: 'IU65' },
  ...poomsaeBlackDuoCategoryOptions,
  ...poomsaeBlackTeamCategoryOptions,
];

export const poomsaeColorDuoCategoryOptions = [
  { label: 'Duo Color Green Under 10', value: 'DGU10' },
  { label: 'Duo Color Blue Under 10', value: 'DBU10' },
  { label: 'Duo Color Green Under 14', value: 'DGU14' },
  { label: 'Duo Color Blue Under 14', value: 'DBU14' },
  { label: 'Duo Color Green Under 18', value: 'DGU18' },
  { label: 'Duo Color Blue Under 18', value: 'DBU18' },
  { label: 'Duo Color Blue Under 30', value: 'DRU30' },
];

export const poomsaeColorTeamCategoryOptions = [
  { label: 'Team Color Green Under 10', value: 'TGU10' },
  { label: 'Team Color Blue Under 10', value: 'TBU10' },
  { label: 'Team Color Green Under 14', value: 'TGU14' },
  { label: 'Team Color Blue Under 14', value: 'TBU14' },
  { label: 'Team Color Green Under 18', value: 'TGU18' },
  { label: 'Team Color Blue Under 18', value: 'TBU18' },
  { label: 'Team Color Blue Under 30', value: 'TRU30' },
];

export const poomsaeColorBeltOptions = [
  { label: 'Individual Color Green Under 10', value: 'IGU10' },
  { label: 'Individual Color Blue Under 10', value: 'IBU10' },
  { label: 'Individual Color Green Under 14', value: 'IGU14' },
  { label: 'Individual Color Blue Under 14', value: 'IBU14' },
  { label: 'Individual Color Green Under 18', value: 'IGU18' },
  { label: 'Individual Color Blue Under 18', value: 'IBU18' },
  { label: 'Individual Color Blue Under 30', value: 'IBU30' },
  ...poomsaeColorDuoCategoryOptions,
  ...poomsaeColorTeamCategoryOptions,
];

export const customAccordionTheme = {
  accordion: {
    root: {
      base: 'divide-y  divide-gray-500 border-gray-500 dark:divide-gray-500 dark:border-gray-500',
      flush: {
        off: 'rounded-lg border',
        on: 'border-b',
      },
    },
    content: {
      base: 'py-5 px-5 last:rounded-b-lg bg-gray-200 first:rounded-t-lg',
    },
    title: {
      arrow: {
        base: 'h-6 w-6 shrink-0',
        open: {
          off: '',
          on: 'rotate-180',
        },
      },
      base: 'flex w-full items-center justify-between first:rounded-t-lg last:rounded-b-lg py-5 px-5 text-left font-medium dark:text-gray-700',
      flush: {
        off: 'hover:bg-gray-300 dark:bg-gray-200',
        on: 'dark:bg-red-300',
      },
    },
    heading: '',
    open: {
      off: 'bg-gray-500',
      on: 'text-gray-900 bg-gray-500 bg-gray-500 text-gray-700',
    },
  },
};

export const dropdownStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: 'transparent',
    paddingTop: '0.125rem',
    paddingBottom: '0.125rem',
    paddingLeft: '0.125rem',
    paddingRight: '0.125rem',
    boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);',
    fontWeight: '500',
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    outlineColor: '#1f2937',
    outlineWidth: 50,
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    fontWeight: '500',
  }),
  input: (baseStyles, state) => ({
    ...baseStyles,
  }),
};

export const dropdownTheme = (theme) => ({
  ...theme,
  colors: { ...theme.colors, primary25: '#D1D5DB', primary: '#1f2937' },
});

export const provinceOptions = [
  { label: 'Badakhshan', value: 'Badakhshan' },
  { label: 'Badghis', value: 'Badghis' },
  { label: 'Baghlan', value: 'Baghlan' },
  { label: 'Balkh', value: 'Balkh' },
  { label: 'Bamyan', value: 'Bamyan' },
  { label: 'Daykundi', value: 'Daykundi' },
  { label: 'Farah', value: 'Farah' },
  { label: 'Faryab', value: 'Faryab' },
  { label: 'Ghazni', value: 'Ghazni' },
  { label: 'Ghor', value: 'Ghor' },
  { label: 'Helmand', value: 'Helmand' },
  { label: 'Herat', value: 'Herat' },
  { label: 'Jowzjan', value: 'Jowzjan' },
  { label: 'Kabul', value: 'Kabul' },
  { label: 'Kandahar', value: 'Kandahar' },
  { label: 'Kapisa', value: 'Kapisa' },
  { label: 'Khost', value: 'Khost' },
  { label: 'Kunar', value: 'Kunar' },
  { label: 'Kunduz', value: 'Kunduz' },
  { label: 'Laghman', value: 'Laghman' },
  { label: 'Logar', value: 'Logar' },
  { label: 'Maidan Wardak', value: 'Maidan Wardak' },
  { label: 'Nangarhar', value: 'Nangarhar' },
  { label: 'Nimruz', value: 'Nimruz' },
  { label: 'Nuristan', value: 'Nuristan' },
  { label: 'Paktia', value: 'Paktia' },
  { label: 'Paktika', value: 'Paktika' },
  { label: 'Panjshir', value: 'Panjshir' },
  { label: 'Parwan', value: 'Parwan' },
  { label: 'Samangar', value: 'Samangar' },
  { label: 'Sar-e Pol', value: 'Sar-e Pol' },
  { label: 'Takhar', value: 'Takhar' },
  { label: 'Urozgan', value: 'Urozgan' },
  { label: 'Zabul', value: 'Zabul' },
];

export const convertNumToDari = (num) => {
  const numbers = `۰۱۲۳۴۵۶۷۸۹`;
  let res = '';
  const str = num.toString();
  for (let c of str) {
    res += numbers.charAt(c);
  }
  return res;
};

export const getRemainingCertificateTime = (athlete) => {
  const certificate = athlete.danGradesKukkiwon.slice(-1)[0];

  //  If no certificate then return null as athlete has no dan.
  if (!certificate) return null;

  if (certificate) {
    const { dan } = certificate;
    const issueDate = new Date(certificate.issueDate);

    const yearsFromIssueDate = new Date(
      issueDate.setFullYear(issueDate.getFullYear() + dan * 1)
    );
    const now = new Date();
    const target = new Date(yearsFromIssueDate);

    let years = differenceInYears(target, now);
    let adjustedNow = addYears(now, years);
    let months = differenceInMonths(target, adjustedNow);
    adjustedNow = addMonths(adjustedNow, months);
    let days = differenceInDays(target, adjustedNow);

    const toReturn = { years, months, days };
    if (isAfter(target, now)) {
      toReturn.past = false;
      toReturn.future = true;
    } else if (isBefore(target, now)) {
      toReturn.past = true;
      toReturn.future = false;
    }
    return toReturn;
  }
};
