import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  login,
  useLazyAthleteLookupQuery,
  useUserLoginMutation,
} from '../store';

import Notiflix from 'notiflix';
import Panel from '../components/uiComponents/Panel';
import MainHeading from '../components/uiComponents/MainHeading';
import ANTFlogo from '../assets/img/antf-logo.png';
import { showLoadingNotificationEffect } from '../utils/helpers';

function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeLogin, setActiveLogin] = useState(true);
  const [activeLookup, setActiveLookup] = useState(false);
  const [showLookupResult, setShowLookupResult] = useState(false);

  const [lookupfullname, setLookupFullName] = useState('');
  const [lookupDateOfBirth, setLookupDateOfBirth] = useState('');

  const { register, handleSubmit } = useForm();
  const [userLogin, userLoginResults] = useUserLoginMutation();
  const [trigger, result] = useLazyAthleteLookupQuery();
  const { token } = useSelector((state) => state.authentication);

  useEffect(() => {
    if (token) {
      navigate('/dashboard', { replace: true });
    }
  }, [token, navigate]);

  useEffect(() => {}, []);
  const handleSubmitLogin = async (form) => {
    userLogin(form);
  };

  // const handleClickCreate = () => {
  //   Notiflix.Report.info(
  //     'Create Account',
  //     'Kindly contact ANTF Office to create an account.',
  //     'Okay'
  //   );
  // };

  const handleClickForgotPass = (e) => {
    e.preventDefault();
    Notiflix.Report.warning(
      'Forgot Password',
      'Kindly contact ANTF Office for new password.',
      'Okay'
    );
  };

  useEffect(() => {
    showLoadingNotificationEffect(userLoginResults, 'User', 'Login');
    if (userLoginResults.isSuccess) {
      const { user } = userLoginResults.data.data;
      const { token } = userLoginResults.data;
      dispatch(login({ user, token }));
    }
  }, [userLoginResults, dispatch, navigate]);

  useEffect(() => {
    if (result.isFetching) {
      Notiflix.Loading.circle('Looking up...');
    }
    if (result.isSuccess) {
      Notiflix.Loading.remove(500);
      setShowLookupResult(true);
    }
    if (result.isError) {
      Notiflix.Loading.remove(500);

      Notiflix.Report.info(
        'No Athlete Found!',
        'There is no athlete with provided information',
        'Okay'
      );
    }
  }, [result]);

  const handleActiveLogin = () => {
    setActiveLogin(true);
    setActiveLookup(false);
    document.getElementById('glider').classList.remove('right-1');
    document.getElementById('glider').classList.add('left-1');
  };

  const handleActiveLookup = () => {
    setActiveLookup(true);
    setActiveLogin(false);
    document.getElementById('glider').classList.add('right-1');
    document.getElementById('glider').classList.remove('left-1');
  };

  const handleLookupFullnameChange = (e) => {
    setLookupFullName(e.target.value);
  };
  const handleLookupDateOfBirthChange = (e) => {
    setLookupDateOfBirth(e.target.value);
  };

  const handleLookupSubmit = (e) => {
    e.preventDefault();
    const fullName = lookupfullname.split(' ').join('+');

    const query = `fullNameEn=${fullName}&dateOfBirth=${lookupDateOfBirth}`;
    trigger(query);
  };

  return (
    <div className="relative bg-gray-800 h-screen p-4 md:p-12">
      <Panel
        className={`h-full bg-white flex flex-col md:flex-row gap-4 w-full md:justify-between`}
      >
        <div className="md:grid md:grid-cols-1 md:grid-rows-[120px_minmax(150px,_1fr)_100px] md:gap-4 md:w-1/3  p-4">
          {' '}
          <div className="row-start-1 h-32 flex flex-col gap-2">
            <img
              className="w-44 h-auto mx-auto mb-4"
              src={ANTFlogo}
              alt="Afghanistan National Taekwondo Federation Logo"
            />
            <div className="mb-2 rounded-full relative self-center w-36 h-8 flex justify-between bg-gray-200 pl-4 pr-2 py-1">
              <div
                onClick={handleActiveLogin}
                className={`text-gray-800 z-20 cursor-pointer font-semibold transition-all ${
                  activeLogin && 'text-white'
                }`}
              >
                Login
              </div>
              <div
                onClick={handleActiveLookup}
                className={`text-gray-800 z-20 cursor-pointer font-semibold transition-all ${
                  activeLookup && 'text-white'
                }`}
              >
                Lookup
              </div>
              <span
                id="glider"
                className=" absolute top-1 z-10 left-1 w-16 h-6 bg-gray-800 rounded-full transition-all"
              ></span>
            </div>
            {/* <p>
              Don't have an account?{' '}
              <button
                onClick={handleClickCreate}
                className="text-gray-800 font-bold hover:underline"
              >
                Create Account
              </button>
            </p> */}
          </div>
          <div className="relative">
            {activeLogin && (
              <form
                className={`w-full row-start-2`}
                onSubmit={handleSubmit(handleSubmitLogin)}
              >
                <MainHeading className={'mb-8 text-center'}>
                  Welcome, Login.
                </MainHeading>

                <div className="flex flex-col gap-4 mt-12 md:mt-0">
                  <div className="flex flex-col gap-2">
                    <label
                      className="text-gray-800 font-bold "
                      htmlFor="username"
                    >
                      Username
                    </label>
                    <input
                      className="rounded border-2 py-2 px-3 font-semibold text-gray-800 focus:ring focus:ring-gray-800 transition"
                      type="text"
                      placeholder="anwar-asghari"
                      {...register(`username`)}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label
                      className="text-gray-800 font-bold "
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      className="rounded border-2 py-2 px-3 font-semibold text-gray-800 focus:ring focus:ring-gray-800 transition"
                      type="password"
                      placeholder="********"
                      {...register(`password`)}
                    />
                  </div>
                </div>
                <div className="mt-4 flex justify-between">
                  <button
                    type="button"
                    onClick={handleClickForgotPass}
                    className="text-gray-700 font-bold hover:underline "
                  >
                    Forgot Password?
                  </button>
                </div>
                <button
                  type="submit"
                  className="uppercase bg-gray-700 px-3 py-2 w-full text-white rounded shadow mt-4 font-bold hover:bg-gray-800 transition"
                >
                  log in
                </button>
                <p className="font-semibold text-gray-400 text-center md:mt-20 mt-2">
                  Developed by Zuhoor Hashimi
                </p>
              </form>
            )}
            {activeLookup && (
              <form className={`w-full`}>
                <div className="mb-8 text-center">
                  <MainHeading>Enter Your Details</MainHeading>
                  <p className="text-gray-500 text-sm">
                    Enter your name and date of birth exactly as it is written
                    on your KUKKIWON certificate.
                  </p>
                </div>
                <div className="flex flex-col gap-4 mt-12 md:mt-0">
                  <div className="flex flex-col gap-2">
                    <label
                      className="text-gray-800 font-bold "
                      htmlFor="lookupFullName"
                    >
                      Full Name (English)
                    </label>
                    <input
                      className="rounded border-2 py-2 px-3 font-semibold text-gray-800 focus:outline focus:outline-gray-800"
                      type="text"
                      name="lookupFullName"
                      placeholder="Mohammad Anwar Asghari"
                      value={lookupfullname}
                      onChange={handleLookupFullnameChange}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label
                      className="text-gray-800 font-bold "
                      htmlFor="password"
                    >
                      Date of Birth
                    </label>
                    <input
                      className="rounded border-2 py-2 px-3 font-semibold text-gray-800 focus:outline focus:outline-gray-800"
                      type="date"
                      value={lookupDateOfBirth}
                      onChange={handleLookupDateOfBirthChange}
                    />
                  </div>
                </div>
                <button
                  className="uppercase bg-gray-700 px-3 py-2 w-full text-white rounded shadow mt-4 font-bold hover:bg-gray-800 transition"
                  onClick={handleLookupSubmit}
                  type="submit"
                >
                  Lookup
                </button>
                <p className="font-semibold text-gray-400 text-center md:mt-20 mt-2">
                  Developed by Zuhoor Hashimi
                </p>
              </form>
            )}
          </div>
        </div>
        <div className="login-artwork bg-cover md:w-2/3 w-full h-full bg-gray-700 p-2 rounded-lg "></div>
      </Panel>
      <div
        onClick={() => setShowLookupResult(false)}
        className={`absolute top-0 bottom-0 left-0 right-0 bg-gray-800/50 grid items-center justify-center py-32 md:py-12 ${
          showLookupResult === true ? '' : 'hidden'
        }`}
      >
        <div className="w-96 h-fit bg-gray-800 rounded-lg p-4 border-gray-200 border-2">
          <img
            className="aspect-[3/4] w-24 mx-auto mb-4 bg-gray-200 rounded-md shadow-md"
            alt="Lookup athlete"
            src={`https://antf.cloud/public/uploads/${result.data?.data.photo}`}
          />
          <div className="grid grid-cols-2 border-gray-800 border-2 rounded-lg bg-gray-200 drop-shadow-md text-gray-800 font-bold">
            <div className="p-2 border-gray-800 border-b-2">Name</div>
            <div className="p-2 border-gray-800 border-b-2">
              {result.data?.data.fullNameEn}
            </div>
            <div className="p-2 border-gray-800 border-b-2">Date of Birth</div>
            <div className="p-2 border-gray-800 border-b-2">
              {`${new Date(result.data?.data.dateOfBirth).getFullYear()}/${
                new Date(result.data?.data.dateOfBirth).getMonth() + 1
              }/${new Date(result.data?.data.dateOfBirth).getDate()}`}
            </div>
            <div className="p-2 border-gray-800 border-b-2">Club</div>
            <div className="p-2 border-gray-800 border-b-2">
              {result.data?.data.club.name}
            </div>
            <div className="p-2 border-gray-800 border-b-2">Province</div>
            <div className="p-2 border-gray-800 border-b-2">
              {result.data?.data.club.province}
            </div>
            <div className="p-2 border-gray-800 border-b-2">
              Current Kukkiwon Dan
            </div>
            <div className="p-2 border-gray-800 border-b-2">
              {result.data?.data.danGradesKukkiwon.slice(-1)[0]?.dan}
            </div>
            <div className="p-2 border-gray-800 border-b-2">
              Kukkiwon Certificate
            </div>
            <div className="p-2 border-gray-800 border-b-2">
              {result.data?.data.danGradesKukkiwon.slice(-1)[0]?.danCertificate}
            </div>
            <div className="p-2 border-gray-800 border-b-2">
              Current National Dan
            </div>
            <div className="p-2 border-gray-800 border-b-2">
              {result.data?.data.danGradesNational.slice(-1)[0]?.dan}
            </div>
            <div className="p-2 border-gray-800">National Certificate</div>
            <div className="p-2">
              {result.data?.data.danGradesNational.slice(-1)[0]?.danCertificate}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
