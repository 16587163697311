import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetEventQuery } from '../../store';
import {
  calculateAthletePoints,
  customAccordionTheme,
  getPenalties,
  getPoomsaeProfile,
  poomsaeBlackBeltOptions,
  poomsaeColorBeltOptions,
  showLoadingEffect,
} from '../../utils/helpers';
import Table from '../uiComponents/Table';
import { Accordion, Flowbite } from 'flowbite-react';

export default function PoomsaeResultsList() {
  const { eventId } = useParams();
  const {
    data: event,
    isLoading: eventIsLoading,
    isError: eventIsError,
    error: eventError,
  } = useGetEventQuery({ eventId, eventType: 'poomsae' });

  useEffect(() => {
    showLoadingEffect(event, eventIsLoading, eventIsError, eventError);
  }, [event, eventError, eventIsError, eventIsLoading]);

  const getRenderedRoundSortedIndividualParticipants = (
    participants,
    profilePointField,
    penaltyField
  ) => {
    const sortedRound = participants.toSorted((a, b) => {
      const poomsaeProfileA = getPoomsaeProfile(a, eventId);
      const poomsaeProfileB = getPoomsaeProfile(b, eventId);

      const { totalPoint: totalA } = calculateAthletePoints(
        poomsaeProfileA,
        /**
         * example 'preliminaryPoints'
         */
        profilePointField
      );

      const { totalPoint: totalB } = calculateAthletePoints(
        poomsaeProfileB,
        /**
         * example 'preliminaryPoints'
         */
        profilePointField
      );
      /**
       * example for penaltyField = 'Preliminary', 'Semi Final', 'Final'
       */
      const penaltiesA = getPenalties(poomsaeProfileA, penaltyField);
      const penaltiesB = getPenalties(poomsaeProfileB, penaltyField);

      const finalPointB =
        (isNaN(totalB) ? 0 : totalB) - (isNaN(penaltiesB) ? 0 : penaltiesB);

      const finalPointA =
        (isNaN(totalA) ? 0 : totalA) - (isNaN(penaltiesA) ? 0 : penaltiesA);

      return finalPointB - finalPointA;
    });
    const config = [
      { label: 'Name', render: (participant) => participant.fullNameEn },
      { label: 'Club', render: (participant) => participant.club.name },
      {
        label: 'Points',
        render: (participant) => {
          const poomsaeProfile = getPoomsaeProfile(participant, eventId);

          const { totalPoint } = calculateAthletePoints(
            poomsaeProfile,
            /**
             * example 'preliminaryPoints'
             */
            profilePointField
          );
          /**
           * example for penaltyField = 'Preliminary', 'Semi Final', 'Final'
           */
          const penalties = getPenalties(poomsaeProfile, penaltyField);

          const finalPoints =
            (isNaN(totalPoint) ? 0 : totalPoint) -
            (isNaN(penalties) ? 0 : penalties);

          return finalPoints.toFixed(3);
        },
      },
    ];

    return <Table config={config} data={sortedRound} />;
  };

  const renderResultForIndividualCategory = (participants, category) => {
    const categoryFilteredParticipants = participants.filter((participant) => {
      const poomsaeProfile = getPoomsaeProfile(participant, eventId);
      return poomsaeProfile.category === category;
    });
    // console.log(category, categoryFilteredParticipants.length);

    const preliminary = getRenderedRoundSortedIndividualParticipants(
      categoryFilteredParticipants,
      'preliminaryPoints',
      'Preliminary'
    );
    const semiFinal = getRenderedRoundSortedIndividualParticipants(
      categoryFilteredParticipants,
      'semiFinalPoints',
      'Semi Final'
    );
    const final = getRenderedRoundSortedIndividualParticipants(
      categoryFilteredParticipants,
      'finalPoints',
      'Final'
    );

    return (
      <>
        <Accordion.Title className="font-semibold px-4">
          {category}
        </Accordion.Title>
        <Accordion.Content className="">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 rounded-md">
            <div>
              <p className="font-bold text-lg mb-2 text-gray-700">
                Preliminary
              </p>
              {preliminary}
            </div>
            <div>
              <p className="font-bold text-lg mb-2 text-gray-700">Semi Final</p>
              {semiFinal}
            </div>
            <div>
              <p className="font-bold text-lg mb-2 text-gray-700">Final</p>
              {final}
            </div>
          </div>
        </Accordion.Content>
      </>
    );
  };
  const getRenderedRoundSortedTeamAndDuoParticipants = (
    profiles,
    profilePointField,
    penaltyField
  ) => {
    const sortedRound = profiles.toSorted((a, b) => {
      const poomsaeProfileA = a;
      const poomsaeProfileB = b;

      const { totalPoint: totalA } = calculateAthletePoints(
        poomsaeProfileA,
        /**
         * example 'preliminaryPoints'
         */
        profilePointField
      );

      const { totalPoint: totalB } = calculateAthletePoints(
        poomsaeProfileB,
        /**
         * example 'preliminaryPoints'
         */
        profilePointField
      );
      /**
       * example for penaltyField = 'Preliminary', 'Semi Final', 'Final'
       */
      const penaltiesA = getPenalties(poomsaeProfileA, penaltyField);
      const penaltiesB = getPenalties(poomsaeProfileB, penaltyField);

      const finalPointB =
        (isNaN(totalB) ? 0 : totalB) - (isNaN(penaltiesB) ? 0 : penaltiesB);

      const finalPointA =
        (isNaN(totalA) ? 0 : totalA) - (isNaN(penaltiesA) ? 0 : penaltiesA);

      return finalPointB - finalPointA;
    });
    const config = [
      {
        label: 'Name',
        render: (profile) =>
          profile.athlete.map((athlete) => athlete.fullNameEn).join(', '),
      },
      {
        label: 'Club',
        render: (profile) =>
          profile.athlete.map((athlete) => athlete.club.name).join(', '),
      },
      {
        label: 'Points',
        render: (profile) => {
          const poomsaeProfile = profile;

          const { totalPoint } = calculateAthletePoints(
            poomsaeProfile,
            /**
             * example 'preliminaryPoints'
             */
            profilePointField
          );
          /**
           * example for penaltyField = 'Preliminary', 'Semi Final', 'Final'
           */
          const penalties = getPenalties(poomsaeProfile, penaltyField);

          const finalPoints =
            (isNaN(totalPoint) ? 0 : totalPoint) -
            (isNaN(penalties) ? 0 : penalties);

          return finalPoints.toFixed(3);
        },
      },
    ];

    return <Table config={config} data={sortedRound} />;
  };
  const renderResultForTeamsAndDuoCategory = (profiles, category) => {
    const categoryFilteredParticipants = profiles.filter((profile) => {
      return profile.category === category;
    });
    // console.log(category, categoryFilteredParticipants.length);

    const preliminary = getRenderedRoundSortedTeamAndDuoParticipants(
      categoryFilteredParticipants,
      'preliminaryPoints',
      'Preliminary'
    );
    const semiFinal = getRenderedRoundSortedTeamAndDuoParticipants(
      categoryFilteredParticipants,
      'semiFinalPoints',
      'Semi Final'
    );
    const final = getRenderedRoundSortedTeamAndDuoParticipants(
      categoryFilteredParticipants,
      'finalPoints',
      'Final'
    );

    return (
      <>
        <Accordion.Title className="font-semibold px-4">
          {category}
        </Accordion.Title>
        <Accordion.Content className="">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 rounded-md">
            <div>
              <p className="font-bold text-lg mb-2 text-gray-700">
                Preliminary
              </p>
              {preliminary}
            </div>
            <div>
              <p className="font-bold text-lg mb-2 text-gray-700">Semi Final</p>
              {semiFinal}
            </div>
            <div>
              <p className="font-bold text-lg mb-2 text-gray-700">Final</p>
              {final}
            </div>
          </div>
        </Accordion.Content>
      </>
    );
  };

  if (event) {
    const renderedIndividualBlackBeltResults = poomsaeBlackBeltOptions
      // The team and duo require different rendering due to arrays (athlete === []) so have to filter them out
      .filter((option) => option.value.startsWith('I'))
      .map((category) => {
        return (
          <Accordion.Panel key={category.value} className="border-b-2">
            {renderResultForIndividualCategory(
              event.participants,
              category.value
            )}
          </Accordion.Panel>
        );
      });
    const renderedTeamsAndDuoBlackBeltResults = poomsaeBlackBeltOptions
      .filter((option) => !option.value.startsWith('I'))
      .map((category) => {
        return (
          <Accordion.Panel key={category.value} className="border-b-2">
            {renderResultForTeamsAndDuoCategory(
              event.teamsAndDuos,
              category.value
            )}
          </Accordion.Panel>
        );
      });
    const rendredColorBeltIndividualResults = poomsaeColorBeltOptions
      .filter((option) => option.value.startsWith('I'))
      .map((category) => {
        return (
          <Accordion.Panel key={category.value} className="border-b-2 ">
            {renderResultForIndividualCategory(
              event.participants,
              category.value
            )}
          </Accordion.Panel>
        );
      });
    const rendredColorBeltTeamAndDuoResults = poomsaeColorBeltOptions
      .filter((option) => !option.value.startsWith('I'))
      .map((category) => {
        return (
          <Accordion.Panel key={category.value} className="border-b-2">
            {renderResultForTeamsAndDuoCategory(
              event.teamsAndDuos,
              category.value
            )}
          </Accordion.Panel>
        );
      });
    return (
      <Flowbite theme={{ theme: customAccordionTheme }}>
        <Accordion>
          <Accordion.Panel>
            <Accordion.Title>
              <p className="mb-2 font-bold text-xl">Black Belts</p>
            </Accordion.Title>
            <Accordion.Content>
              <Accordion>
                <Accordion.Panel>
                  <Accordion.Title>Individuals</Accordion.Title>
                  <Accordion.Content>
                    <Accordion>{renderedIndividualBlackBeltResults}</Accordion>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                  <Accordion.Title>Teams and Duos</Accordion.Title>
                  <Accordion.Content>
                    <Accordion>{renderedTeamsAndDuoBlackBeltResults}</Accordion>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>
            </Accordion.Content>
          </Accordion.Panel>
          <Accordion.Panel>
            <Accordion.Title>
              <p className="mb-2 font-bold text-xl">Color Belts</p>
            </Accordion.Title>
            <Accordion.Content>
              <Accordion>
                <Accordion.Panel>
                  <Accordion.Title>Individuals</Accordion.Title>
                  <Accordion.Content>
                    <Accordion>{rendredColorBeltIndividualResults}</Accordion>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                  <Accordion.Title>Teams and Duos</Accordion.Title>
                  <Accordion.Content>
                    <Accordion>{rendredColorBeltTeamAndDuoResults}</Accordion>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>
            </Accordion.Content>
          </Accordion.Panel>
        </Accordion>
      </Flowbite>
    );
  }
}
