import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetEventQuery, useGetOperationalDataQuery } from '../../../store';
import Table from '../../../components/uiComponents/Table';
import {
  calculateAthletePoints,
  getPenalties,
  getPoomsaeProfile,
} from '../../../utils/helpers';

export default function AnnouncePage() {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const { data: operationalData } = useGetOperationalDataQuery('poomsae');

  const { data: eventApi } = useGetEventQuery({
    eventType: 'poomsae',
    eventId,
  });

  useEffect(() => {
    document.title = 'Results Page';
    if (!operationalData?.announce) {
      navigate(`/events/poomsae/monitor/${eventId}`);
    }
  }, [operationalData, eventId, navigate]);
  if (eventApi && operationalData) {
    const { activeCategory, activeStage } = operationalData;

    let pageTitle;
    let field;
    if (activeStage === 'activePreliminary') {
      pageTitle = 'Preliminary';
      field = 'preliminaryPoints';
    }
    if (activeStage === 'activeSemiFinal') {
      pageTitle = 'Semi Final';
      field = 'semiFinalPoints';
    }
    if (activeStage === 'activeFinal') {
      pageTitle = 'Final';
      field = 'finalPoints';
    }

    let categoryFilteredData = eventApi.participants.filter((athlete) => {
      const poomsaeProfile = getPoomsaeProfile(athlete, eventId);
      return poomsaeProfile.category.includes(activeCategory);
    });

    const sortedData = categoryFilteredData.sort((a, b) => {
      const poomsaeProfileA = getPoomsaeProfile(a, eventId);
      const poomsaeProfileB = getPoomsaeProfile(b, eventId);

      const { totalPoint: totalA } = calculateAthletePoints(
        poomsaeProfileA,
        field
      );
      const { totalPoint: totalB } = calculateAthletePoints(
        poomsaeProfileB,
        field
      );
      const penaltiesA = getPenalties(poomsaeProfileA, pageTitle);
      const penaltiesB = getPenalties(poomsaeProfileB, pageTitle);

      const finalPointB =
        (isNaN(totalB) ? 0 : totalB) - (isNaN(penaltiesB) ? 0 : penaltiesB);

      const finalPointA =
        (isNaN(totalA) ? 0 : totalA) - (isNaN(penaltiesA) ? 0 : penaltiesA);

      return finalPointB - finalPointA;

      // return totalB - penaltiesB - (totalA - penaltiesA);
    });

    const sortedWithPositions = sortedData.map((athlete, i) => {
      const position =
        i === 0
          ? { isFirst: true }
          : i === 1
          ? { isSecond: true }
          : i === 2 || i === 3
          ? { isThird: true }
          : {};
      return { ...athlete, ...position };
    });

    const config = [
      {
        label: 'Club',
        render: (athlete) => (
          <p className={`text-4xl `}> {athlete.club.name}</p>
        ),
      },
      {
        label: 'Name',
        render: (athlete) => <p className="text-4xl"> {athlete.fullNameEn}</p>,
      },
      {
        label: 'Points',
        render: (athlete) => {
          const poomsaeProfile = getPoomsaeProfile(athlete, eventId);

          const { totalPoint } = calculateAthletePoints(poomsaeProfile, field);
          const penalties = getPenalties(poomsaeProfile, pageTitle);

          return (
            <p className="text-4xl"> {(totalPoint - penalties).toFixed(3)}</p>
          );
        },
      },
    ];
    return (
      <div className="bg-gray-800 h-screen flex flex-col items-center py-12 px-8">
        <h1 className="relative text-white text-6xl font-bold mb-8 after:bg-red-500 after:content-[` `] after:w-full after:h-0.5 after:absolute after:right-0 after:left-0 after:bg-darkBlue after:bottom-0">
          Results for Male {activeCategory} | {pageTitle}
        </h1>
        <div className="w-full">
          <Table
            config={config}
            data={sortedWithPositions.slice(0, 5)}
            labelSize={'text-4xl'}
            padding={'p-8'}
          />
        </div>
      </div>
    );
  }
}
