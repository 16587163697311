import Skeleton from './Skeleton';

function Table({ data, config, labelSize, padding }) {
  const renderedHeaders = config.map((column) => {
    return (
      <th
        className={`p-2  text-gray-100 bg-gray-600 ${labelSize} ${padding}`}
        key={column.label}
      >
        {column.label}
      </th>
    );
  });

  const renderedSkeleton = data.map((data, i) => {
    const renderedCells = config.map((column) => {
      return (
        <td className="p-2 font-semibold text-gray-800" key={column?.label}>
          <Skeleton />
        </td>
      );
    });
    return (
      <tr
        key={i}
        className={`
        
          even:bg-gray-50 odd:bg-gray-100 hover:bg-gray-200 transition-all`}
      >
        {renderedCells}
      </tr>
    );
  });
  const renderedRows = data.map((rowData) => {
    const renderedCells = config.map((column) => {
      return (
        <td
          className={`p-2 font-semibold text-gray-800 ${padding}`}
          key={column?.label}
        >
          {column?.render(rowData)}
        </td>
      );
    });
    return (
      <tr
        key={rowData._id || 1}
        className={`even:bg-gray-50 odd:bg-gray-100 hover:bg-gray-200 transition-all ${
          rowData.isFirst
            ? '!bg-amber-400'
            : rowData.isSecond
            ? '!bg-slate-200'
            : rowData.isThird || rowData.isFourth
            ? '!bg-amber-600'
            : 'yellow'
        } `}
      >
        {renderedCells}
      </tr>
    );
  });

  const renderFinalRows = () => {
    if (data[0]?.loading === true) {
      return renderedSkeleton;
    } else {
      return renderedRows;
    }
  };

  return (
    <table className="table-auto w-full ">
      <thead className="h-2">
        <tr className="bg-white text-gray-800 text-left">{renderedHeaders}</tr>
      </thead>
      <tbody className="h-full">{renderFinalRows()}</tbody>
    </table>
  );
}

export default Table;
