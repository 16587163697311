import React, { useEffect } from 'react';
import Panel from '../uiComponents/Panel';
import { setActiveTab, useGetAllEventsQuery } from '../../store';

import Scrollbar from '../uiComponents/Scrollbar';
import SearchBar from '../uiComponents/SearchBar';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MainHeading from '../uiComponents/MainHeading';
import Button from '../uiComponents/Button';
import EventItem from './EventItem';
import EventItemSkeleton from './EventItemSkeleton';

export default function EventsList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { eventTypeFilterTerm, eventStatusFilterTerm, eventNameSearchTerm } =
    useSelector((state) => state.data);
  const {
    data: events,
    isLoading: eventsLoading,
    isError: eventsError,
  } = useGetAllEventsQuery();

  useEffect(() => {
    if (eventsError) {
      console.log(eventsError);
    }
  }, [eventsError]);
  useEffect(() => {
    dispatch(setActiveTab('Events'));
  }, [dispatch]);

  // if (events) {
  let filteredData = events || [];

  filteredData = filteredData.filter((event) => {
    return event.title
      ?.toLowerCase()
      .includes(eventNameSearchTerm.toLowerCase());
  });

  if (eventTypeFilterTerm) {
    filteredData = filteredData.filter((event) => {
      return event.type === eventTypeFilterTerm.value;
    });
  }

  if (eventStatusFilterTerm) {
    filteredData = filteredData.filter((event) => {
      const today = new Date();
      const eventDate = new Date(event.regEnd);
      if (eventStatusFilterTerm.value === 'active') {
        return today < eventDate;
      }
      if (eventStatusFilterTerm.value === 'closed') {
        return today > eventDate;
      }
      return true;
    });
  }

  const renderedEvents = filteredData.map((event) => {
    return <EventItem event={event} />;
  });
  const skeletonEvents = Array.from({ length: 5 }).map(() => {
    return <EventItemSkeleton />;
  });
  return (
    <div className="flex flex-col gap-4 h-full">
      <Panel className="">
        <div className="flex justify-between items-center">
          <MainHeading className={'text-center'}>Events</MainHeading>
          <div className="flex flex-col md:flex-row gap-2">
            <Button onClick={() => navigate(-1)}>back</Button>
            {/* <Link
                to={'/dashboard'}
                className="uppercase h-8 w-auto py-1 px-2 md:h-10 md:px-3 md:p-2 font-bold bg-gray-700 text-white rounded-md shadow hover:bg-gray-800 transition"
              >
                Dashboard
              </Link> */}
          </div>
        </div>
      </Panel>
      <div className={`p-4 drop-shadow-md rounded-md z-50 bg-gray-700`}>
        <SearchBar pageType="events" />
      </div>
      <Panel className={'h-full'}>
        <Scrollbar>
          <div className="h-full flex flex-col md:grid md:grid-cols-4 md:gap-x-8 md:gap-y-4 auto-rows-min">
            {eventsLoading ? skeletonEvents : null}
            {!eventsLoading && renderedEvents}
          </div>
        </Scrollbar>
      </Panel>
    </div>
  );
  // }
}
