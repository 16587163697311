import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Stopwatch from '../../../components/poomsae/Stopwatch';
import { useGetOperationalDataQuery } from '../../../store';
import AfghanistanFlag from '../../../assets/img/af-flag.jpg';
import ATNFLogo from '../../../assets/img/antf-logo.png';
import WTLogo from '../../../assets/img/wt-logo.png';
import {
  calculateAthletePoints,
  getPenalties,
  poomsaeBlackBeltOptions,
  poomsaeColorBeltOptions,
} from '../../../utils/helpers';

function PoomsaeMonitor() {
  const { eventId } = useParams();
  const navigate = useNavigate();

  const { data } = useGetOperationalDataQuery('poomsae');
  const [j1, setj1] = useState({});
  const [j2, setj2] = useState({});
  const [j3, setj3] = useState({});
  const [j4, setj4] = useState({});
  const [j5, setj5] = useState({});
  const [field, setField] = useState('');
  const [stage, setStage] = useState('');

  const [totalPoint, setTotalPoint] = useState(0);

  const accuracyPoints = [
    j1?.accuracyPoints,
    j2?.accuracyPoints,
    j3?.accuracyPoints,
    j4?.accuracyPoints,
    j5?.accuracyPoints,
  ];
  const maxAccuracyPoint = Math.max(...accuracyPoints);
  const minAccuracyPoint = Math.min(...accuracyPoints);

  useEffect(() => {
    document.title = 'Active Player';
    if (data?.announce) {
      navigate(`/events/poomsae/monitor/announcePage/${eventId}`);
    }
  }, [data, eventId, navigate]);

  useEffect(() => {
    const profile = data?.activeProfile;

    if (profile) {
      const presentJudges = [];

      if (JSON.stringify(profile[field]) !== `[]`) {
        profile[field]?.forEach((point) => {
          if (point.j1) {
            presentJudges.push('j1');
            setj1(point.j1);
          }

          if (point.j2) {
            presentJudges.push('j2');
            setj2(point.j2);
          }

          if (point.j3) {
            presentJudges.push('j3');
            setj3(point.j3);
          }

          if (point.j4) {
            presentJudges.push('j4');
            setj4(point.j4);
          }

          if (point.j5) {
            presentJudges.push('j5');
            setj5(point.j5);
          }
        });

        /**
         * below code is for removing judges when deleted.
         */

        if (!presentJudges.includes('j1')) {
          setj1({});
        }
        if (!presentJudges.includes('j2')) {
          setj2({});
        }
        if (!presentJudges.includes('j3')) {
          setj3({});
        }
        if (!presentJudges.includes('j4')) {
          setj4({});
        }
        if (!presentJudges.includes('j5')) {
          setj5({});
        }
      } else {
        setj1({});
        setj2({});
        setj3({});
        setj4({});
        setj5({});
        setTotalPoint(0);
        presentJudges.splice(0, presentJudges.length);
      }
    }
  }, [data, field]);

  const renderFullCategory = (category) => {
    let fullCategory;
    poomsaeColorBeltOptions.forEach((option) => {
      if (category === option.value) {
        return (fullCategory = option.label);
      }
    });

    poomsaeBlackBeltOptions.forEach((option) => {
      if (category === option.value) {
        return (fullCategory = option.label);
      }
    });

    return fullCategory;
  };

  useEffect(() => {
    const athlete = data?.activeProfile?.athlete;

    if (data?.activeStage === 'activePreliminary') {
      setStage('Preliminary');
      setField('preliminaryPoints');
    } else if (data?.activeStage === 'activeSemiFinal') {
      setStage('Semi Final');
      setField('semiFinalPoints');
    } else if (data?.activeStage === 'activeFinal') {
      setStage('Final');
      setField('finalPoints');
    }
    if (athlete) {
      const poomsaeProfile = data?.activeProfile;
      const { totalPoint } = calculateAthletePoints(poomsaeProfile, field);

      const penaltyToDeduct = getPenalties(poomsaeProfile, stage);

      setTotalPoint(totalPoint - penaltyToDeduct);
    }
  }, [data, eventId, field, stage]);

  if (data) {
    const poomsaeProfile = data?.activeProfile;
    const athlete = poomsaeProfile.athlete;
    console.log(poomsaeProfile.category);

    return (
      <div className="relative bg-gray-800 h-screen text-white grid grid-rows-[200px_minmax(200px,_1fr)] grid-cols-3 place-items-center">
        <div className="col-span-3 bg-rose-700 p-4 flex flex-row justify-between mt-16 h-24 w-full items-center ">
          <img
            className="w-44"
            src={ATNFLogo}
            alt="Afghanistan National Taekwondo Federation Logo"
          />
          <Stopwatch />
          <div>
            <img
              className="bg-contain w-44"
              src={WTLogo}
              alt="World Taekwondo Logo"
            />
          </div>
        </div>
        <div className=" bg-rose-700 rounded-lg mb-16 p-4 h-96">
          <div className="flex flex-col items-between font-bold text-4xl gap-2 h-96 w-96">
            <ul className="flex justify-between border-b-4 pb-2">
              <li></li>
              <li>A</li>
              <li>P</li>
              <li>T</li>
            </ul>
            <ul className="flex justify-between border-b-4 pb-2 transition-all duration-300">
              <li>J1</li>
              <li
                className={`${
                  j1?.accuracyPoints === maxAccuracyPoint ? '' : ''
                } ${j1?.accuracyPoints === minAccuracyPoint ? '' : ''}`}
              >
                {j1?.accuracyPoints?.toFixed(2) || 0}
              </li>
              <li>{j1?.presentationPoints?.toFixed(2) || 0}</li>
              <li>
                {isNaN(
                  (j1?.accuracyPoints + j1?.presentationPoints)?.toFixed(2)
                )
                  ? 0
                  : (j1?.accuracyPoints + j1?.presentationPoints)?.toFixed(2)}
              </li>
            </ul>
            <ul className="flex justify-between border-b-4 pb-2">
              <li>J2</li>
              <li
                className={`${
                  j2.accuracyPoints === maxAccuracyPoint ? '' : ''
                } ${j2.accuracyPoints === minAccuracyPoint ? '' : ''}`}
              >
                {j2.accuracyPoints?.toFixed(2) || 0}
              </li>
              <li>{j2.presentationPoints?.toFixed(2) || 0}</li>
              <li>
                {isNaN((j2.accuracyPoints + j2.presentationPoints)?.toFixed(2))
                  ? 0
                  : (j2.accuracyPoints + j2.presentationPoints)?.toFixed(2)}
              </li>
            </ul>
            <ul className="flex justify-between border-b-4 pb-2">
              <li>J3</li>
              <li
                className={`${
                  j3?.accuracyPoints === maxAccuracyPoint ? '' : ''
                } ${j3?.accuracyPoints === minAccuracyPoint ? '' : ''}`}
              >
                {j3?.accuracyPoints?.toFixed(2) || 0}
              </li>
              <li>{j3?.presentationPoints?.toFixed(2) || 0}</li>
              <li>
                {isNaN(
                  (j3?.accuracyPoints + j3?.presentationPoints)?.toFixed(2)
                )
                  ? 0
                  : (j3?.accuracyPoints + j3?.presentationPoints)?.toFixed(2)}
              </li>
            </ul>
            <ul className="flex justify-between border-b-4 pb-2">
              <li>J4</li>
              <li
                className={`${
                  j4?.accuracyPoints === maxAccuracyPoint ? '' : ''
                } ${j4?.accuracyPoints === minAccuracyPoint ? '' : ''}`}
              >
                {j4?.accuracyPoints?.toFixed(2) || 0}
              </li>
              <li>{j4?.presentationPoints?.toFixed(2) || 0}</li>
              <li>
                {isNaN(
                  (j4?.accuracyPoints + j4?.presentationPoints)?.toFixed(2)
                )
                  ? 0
                  : (j4?.accuracyPoints + j4?.presentationPoints)?.toFixed(2)}
              </li>
            </ul>
            <ul className="flex justify-between ">
              <li>J5</li>
              <li
                className={`${
                  j5?.accuracyPoints === maxAccuracyPoint ? '' : ''
                } ${j5?.accuracyPoints === minAccuracyPoint ? '' : ''}`}
              >
                {j5?.accuracyPoints?.toFixed(2) || 0}
              </li>
              <li>{j5?.presentationPoints?.toFixed(2) || 0}</li>
              <li>
                {isNaN(
                  (j5?.accuracyPoints + j5?.presentationPoints)?.toFixed(2)
                )
                  ? 0
                  : (j5?.accuracyPoints + j5?.presentationPoints)?.toFixed(2)}
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col gap-4 justify-center items-center font-bold text-5xl mb-24">
          <img
            className="w-40 border-2  border-solid border-white rounded-lg"
            src={AfghanistanFlag}
            alt="Afghanistan Flag"
          />
          <p className="text-center">
            {Array.isArray(athlete)
              ? athlete.map((athlete) => athlete.fullNameEn).join(', ')
              : athlete?.fullNameEn}
          </p>
          <p className="font-normal">
            {renderFullCategory(poomsaeProfile.category)}
          </p>
          <p className="font-normal">{stage && stage}</p>
        </div>
        <div className=" bg-rose-700 rounded-lg ml-8 mb-16 w-96 p-4 h-96 flex justify-center items-center">
          <h1 className="text-9xl font-bold ">
            {isNaN(totalPoint?.toFixed(3)) ? '0.000' : totalPoint?.toFixed(3)}
          </h1>
        </div>
      </div>
    );
  }
}

export default PoomsaeMonitor;
