import { twMerge } from 'tailwind-merge';

function MainHeading({ children, className, ...rest }) {
  const classes = twMerge(
    `text-gray-700 font-bold text-lg sm:text-xl md:text-2xl`,
    className
  );
  return (
    <h1 {...rest} className={classes}>
      {children}
    </h1>
  );
}

export default MainHeading;
