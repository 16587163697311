import NavLink from '../uiComponents/Link';
import Profile from './Profile';
import { IoIosExit } from 'react-icons/io';
import { FaVihara } from 'react-icons/fa';

import MenuItems from './MenuItems';
import { useDispatch, useSelector } from 'react-redux';

import {
  HiHome,
  HiUserGroup,
  HiXMark,
  HiCalendarDays,
  HiUser,
  HiUsers,
} from 'react-icons/hi2';

import {
  logout,
  setMenuIsOpen,
  setShowUserProfileModal,
  useUserLogoutMutation,
} from '../../store';
import Modal from '../uiComponents/Modal';
import UserProfile from '../userAndClub/UserProfile';
import { mainApi } from '../../store/apis/mainApi';

function SideBar() {
  const dispatch = useDispatch();
  const [userLogout] = useUserLogoutMutation();
  // const navigate = useNavigate();
  const logoutLink = { label: 'Log Out', path: '/' };

  const { user } = useSelector((state) => state.authentication);
  const { showUserProfileModal } = useSelector((state) => state.data);

  const handleLogout = () => {
    dispatch(mainApi.util.resetApiState());
    dispatch(logout());
    userLogout();
  };

  const menuItems = [
    { label: 'Dashboard', path: '/dashboard', icon: <HiHome /> },
    {
      label: 'Athletes',
      path: '/dashboard/athletes',
      icon: <HiUserGroup />,
    },
    {
      label: 'Events',
      path: '/dashboard/events',
      icon: <HiCalendarDays />,
    },
  ];

  if (user.role === 'admin') {
    menuItems.push(
      {
        label: 'Clubs',
        path: '/dashboard/clubs',
        icon: <FaVihara />,
      },
      {
        label: 'Users',
        path: '/dashboard/users',
        icon: <HiUsers />,
      }
    );
  }

  const renderedNavLinks = menuItems.map((menu, index) => {
    return <MenuItems items={menu} key={index} />;
  });
  return (
    <div className="flex flex-col items-center bg-gray-200 rounded-md justify-between h-full p-4 shadow-md print:hidden">
      <div
        onClick={() => dispatch(setMenuIsOpen(false))}
        className="absolute top-9 right-9 text-gray-800 text-2xl font-bold"
      >
        <HiXMark />
      </div>
      <div className="flex flex-col items-center px-4 py-6 w-full rounded-md">
        <Profile user={user} />
      </div>

      <div className="h-96 md:h-96 relative bg-gray-200 text-gray-800 w-full p-4 flex flex-col justify-between gap-4 rounded-md z-40 ">
        <div className="flex flex-col gap-2">{renderedNavLinks}</div>
        {showUserProfileModal && (
          <Modal setFunction={setShowUserProfileModal}>
            <UserProfile />
          </Modal>
        )}
        <div>
          <NavLink
            onClick={() => {
              dispatch(setShowUserProfileModal());
            }}
            icon={<HiUser />}
            link={{ label: 'My Profile', path: '#' }}
          />

          <NavLink
            onClick={handleLogout}
            link={logoutLink}
            replace={true}
            icon={<IoIosExit />}
          ></NavLink>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
