import React from 'react';
import { Link } from 'react-router-dom';

export default function NavLink({ to, children, className, ...rest }) {
  return (
    <Link
      to={to}
      className={`uppercase font-bold text-white bg-gray-700 w-full px-3 py-2 rounded hover:bg-gray-800 text-center transition ${className}`}
      {...rest}
    >
      {children}
    </Link>
  );
}
