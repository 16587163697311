import React, { useEffect, useState } from 'react';
import Select from '../uiComponents/Select';

import MainHeading from '../uiComponents/MainHeading';
import {
  poomsaeBlackDuoCategoryOptions,
  poomsaeBlackTeamCategoryOptions,
  poomsaeColorDuoCategoryOptions,
  poomsaeColorTeamCategoryOptions,
  showLoadingEffect,
  showLoadingNotificationEffect,
} from '../../utils/helpers';
import {
  useAddPoomsaeTeamAndDuoMutation,
  useGetAllAthletesQuery,
} from '../../store';
import { useParams } from 'react-router-dom';

export default function TeamAndDuoCreationForm() {
  const { eventId } = useParams();
  const { data, isLoading, isError } = useGetAllAthletesQuery();
  const [addPoomsaeTeamAndDuo, addPoomsaeTeamAndDuoResults] =
    useAddPoomsaeTeamAndDuoMutation();
  const [selectedAthletes, setSelectedAthletes] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const body = {
      athlete: selectedAthletes.map((athlete) => athlete.value),
      category: selectedCategory.value,
      event: eventId,
    };
    addPoomsaeTeamAndDuo(body);
  };
  useEffect(() => {
    showLoadingNotificationEffect(
      addPoomsaeTeamAndDuoResults,
      'Team and Duo',
      'Adding'
    );
  }, [addPoomsaeTeamAndDuoResults]);
  useEffect(() => {
    showLoadingEffect(data, isLoading, isError);
  }, [data, isLoading, isError]);

  const handleSelectedAthletesChange = (value) => {
    setSelectedAthletes([...value]);
  };

  if (data) {
    const athletesOptions = data.map((athlete) => {
      return { label: athlete.fullNameEn, value: athlete._id };
    });

    return (
      <form
        onClick={(e) => e.stopPropagation()}
        onSubmit={(e) => handleFormSubmit(e)}
        className="w-5/6 md:w-2/6"
      >
        <div className="flex flex-col bg-gray-100 rounded-lg p-4 md:p-8 h-fit w-full md:w-full">
          <MainHeading>Create Teams and Duos</MainHeading>
          <div className="flex flex-col gap-4 py-4">
            <div className="flex flex-col gap-2">
              <label className="font-bold">Athletes</label>
              <Select
                isMulti
                value={selectedAthletes}
                options={athletesOptions}
                onChange={(value) => handleSelectedAthletesChange(value)}
                required
              />
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-bold">Category</label>
              <Select
                value={selectedCategory}
                options={[
                  ...poomsaeBlackDuoCategoryOptions,
                  ...poomsaeBlackTeamCategoryOptions,
                  ...poomsaeColorDuoCategoryOptions,
                  ...poomsaeColorTeamCategoryOptions,
                ]}
                onChange={(value) => setSelectedCategory(value)}
                required
              />
            </div>

            <button className="col-start-1 col-end-3 row-start-4 self-end h-12 w-full mt-2 rounded hover:bg-gray-800 uppercase transition bg-gray-700 text-white font-bold py-3 px-2">
              submit
            </button>
          </div>
        </div>
      </form>
    );
  }
}
