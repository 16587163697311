import HoverNavigation from './HoverNavigation';
import NavLink from '../uiComponents/Link';

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setMenuIsOpen } from '../../store';

function MenuItems({ items }) {
  const dispatch = useDispatch();

  const [barIsOpen, setBarIsOpen] = useState(false);
  const handleMouseEnter = () => {
    setBarIsOpen(true);
  };

  return (
    <div>
      {items.submenu ? (
        <>
          <div>
            <NavLink
              onClick={() => {
                // dispatch(setActiveTab(items.label));
                dispatch(setMenuIsOpen(false));
              }}
              icon={items.icon}
              onMouseEnter={handleMouseEnter}
              link={items}
            ></NavLink>
            <HoverNavigation
              onClick={() => dispatch(setMenuIsOpen(false))}
              barIsOpen={barIsOpen}
              setBarIsOpen={setBarIsOpen}
              submenus={items.submenu}
            />{' '}
          </div>
        </>
      ) : (
        <NavLink
          onClick={() => {
            // dispatch(setActiveTab(items.label));
            dispatch(setMenuIsOpen(false));
          }}
          icon={items.icon}
          link={items}
        ></NavLink>
      )}
    </div>
  );
}
export default MenuItems;
