import Notiflix from 'notiflix';
import React from 'react';

export default function SuspenseLoading() {
  return (
    <div>
      {Notiflix.Loading.circle('Loading page...', { svgColor: '#cbd4cd' })}
    </div>
  );
}
