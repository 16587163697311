import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SideBar from '../navigation/Sidebar';
import { Suspense } from 'react';
import SuspenseLoading from './SuspenseLoading';
function Layout() {
  const { menuIsOpen } = useSelector((state) => state.data);

  return (
    <div className="grid grid-rows-1 md:grid-cols-6 bg-gray-800 p-4 h-screen md:gap-4 print:bg-white">
      <div className="col-start-1 col-end-1 lg:col-start-1 lg:col-end-2 h-full bg-gray-800 rounded-lg hidden lg:block print:hidden lg:print:hidden md:print:hidden">
        <SideBar />
      </div>
      <div className="col-start-1 print:col-start-1 lg:col-start-2 col-end-7 lg:print:col-start-1 ">
        <Suspense fallback={<SuspenseLoading />}>
          {menuIsOpen && (
            <div className="h-full bg-gray-300 rounded-md">
              <SideBar />
            </div>
          )}
          {!menuIsOpen && <Outlet />}
        </Suspense>
      </div>
    </div>
  );
}

export default Layout;
