import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Scrollbars from 'rc-scrollbars';
import { useForm } from 'react-hook-form';

import MainHeading from '../uiComponents/MainHeading';
import FormInputText from '../uiComponents/FormInputText';
import {
  login,
  setUser,
  useGetTrainerClubQuery,
  useUpdateClubMutation,
  useUpdateMeMutation,
  useUpdateMyPasswordMutation,
} from '../../store';
import {
  showLoadingEffect,
  showLoadingNotificationEffect,
  trimCapitalize,
} from '../../utils/helpers';
import Notiflix from 'notiflix';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
export default function UserProfile() {
  const dispatch = useDispatch();
  const [updateMyPassword, updateMyPasswordResults] =
    useUpdateMyPasswordMutation();
  const [updateMe, updateMeResults] = useUpdateMeMutation();

  const [updateClub, updateClubResults] = useUpdateClubMutation();

  const { user } = useSelector((state) => state.authentication);

  const { data, isError, isLoading, error } = useGetTrainerClubQuery(user._id);

  const { register, handleSubmit, setValue, watch, getValues } = useForm();

  const handleProfileFormSubmit = (form) => {
    const { firstName, middleName, lastName, username } = form;
    const body = {
      firstName: trimCapitalize(firstName),
      middleName: trimCapitalize(middleName),
      lastName: trimCapitalize(lastName),
      username: username.trim(),
    };
    updateMe(body);
  };

  useEffect(() => {
    showLoadingEffect(data, isLoading, isError, error);
    if (data) {
      setValue('clubName', data.name);
    }
  }, [data, error, isError, isLoading, setValue]);

  useEffect(() => {
    watch();
    setValue('firstName', user.firstName);
    setValue('middleName', user.middleName);
    setValue('lastName', user.lastName);
    setValue('username', user.username);
  }, [setValue, user, watch]);

  useEffect(() => {
    showLoadingNotificationEffect(
      updateMyPasswordResults,
      'Password',
      'Updating'
    );
    if (updateMyPasswordResults.isSuccess) {
      setValue('currentPassword', '');
      setValue('password', '');
      setValue('passwordConfirm', '');
      const { user } = updateMyPasswordResults.data.data;
      const { token } = updateMyPasswordResults.data;
      dispatch(login({ user, token }));
    }
  }, [dispatch, setValue, updateMyPasswordResults]);

  useEffect(() => {
    showLoadingNotificationEffect(updateMeResults, 'User', 'Updating');
    if (updateMeResults.isSuccess) {
      const user = updateMeResults.data?.data;
      dispatch(setUser(user));
    }
  }, [dispatch, updateMeResults]);

  useEffect(() => {
    showLoadingNotificationEffect(updateClubResults, 'Club', 'Updating');
    if (updateClubResults.isError) {
      console.log(updateClubResults.error.data.error.code);
      if (updateClubResults.error.data.error.code === 11000) {
        Notiflix.Notify.failure('Club with that name already exists.');
      }
    }
  }, [updateClubResults]);

  const handleSubmitUpdateMyPassword = (form) => {
    const { password, passwordConfirm, currentPassword } = getValues();
    if (currentPassword && password && passwordConfirm) {
      if (password !== passwordConfirm) return;
      const body = {
        currentPassword,
        password,
        passwordConfirm,
      };
      updateMyPassword(body);
    }
  };

  const handleSubmitUpdateMyClub = (form) => {
    const { clubName } = form;
    updateClub({ id: data._id, name: trimCapitalize(clubName) });
  };
  if (user.role === 'admin' || data) {
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        className="h-[90%] w-5/6 md:h-5/6 md:w-2/5 bg-gray-100 rounded-lg p-4 md:p-8"
      >
        <Scrollbars>
          <form onSubmit={handleSubmit(handleProfileFormSubmit)}>
            <div className="flex flex-col ">
              <MainHeading>My Profile | Update</MainHeading>
              <div className="flex flex-col gap-4 md:grid md:grid-cols-3 py-4">
                <FormInputText
                  label={'First Name (English)'}
                  htmlFor={'firstName'}
                  placeholder={'Mohammad'}
                  register={register}
                />
                <FormInputText
                  label={'Middle Name (English)'}
                  htmlFor={'middleName'}
                  placeholder={'Anwar'}
                  register={register}
                />
                <FormInputText
                  label={'Last Name (English)'}
                  htmlFor={'lastName'}
                  placeholder={'Asghari'}
                  register={register}
                />
                <FormInputText
                  label={'Username'}
                  htmlFor={'username'}
                  placeholder={'anwar-asghari'}
                  register={register}
                  autoComplete="username"
                />
                <button className="md:row-start-4 w-full self-end rounded hover:bg-gray-800 uppercase bg-gray-700 text-gray-100 font-bold py-2 px-2">
                  update profile
                </button>
              </div>
            </div>
          </form>
          <form onSubmit={handleSubmit(handleSubmitUpdateMyPassword)}>
            <div className="col-span-3">
              <h2 className="text-gray-700 font-bold text-lg sm:text-xl md:text-xl">
                Change Password
              </h2>

              <div className="flex flex-col gap-4 md:grid md:grid-cols-3 py-4 mt-2">
                <FormInputText
                  label={'Current Password'}
                  htmlFor={'currentPassword'}
                  placeholder={'*******'}
                  register={register}
                  type={'password'}
                  autoComplete="current-password"
                  required={getValues('currentPassword')?.length > 0}
                />
                <FormInputText
                  label={'New Password'}
                  htmlFor={'password'}
                  placeholder={'*******'}
                  register={register}
                  autoComplete="new-password"
                  type={'password'}
                  required={getValues('currentPassword')?.length > 0}
                />
                <div>
                  <FormInputText
                    className={`${
                      getValues('password') !== getValues('passwordConfirm') &&
                      getValues('password')?.length > 0 &&
                      getValues('passwordConfirm')?.length > 0
                        ? 'focus:outline focus:outline-red-500 text-red-500'
                        : 'focus:outline focus:outline-green-500 text-green-500'
                    } transition`}
                    type={'password'}
                    label={'Confirm Password'}
                    htmlFor={'passwordConfirm'}
                    placeholder={'*******'}
                    autoComplete="new-password"
                    required={getValues('currentPassword')?.length > 0}
                    register={register}
                  />
                  <p className="text-red-500 font-semibold">
                    {getValues('password') !== getValues('passwordConfirm') &&
                      getValues('password')?.length > 0 &&
                      getValues('passwordConfirm')?.length > 0 &&
                      'Passwords do not match.'}
                  </p>
                </div>
                <button className="self-end w-full rounded hover:bg-gray-800 uppercase bg-gray-700 text-gray-100 font-bold py-2 px-2">
                  update password
                </button>
              </div>
            </div>
          </form>
          {user.role !== 'admin' && (
            <form onSubmit={handleSubmit(handleSubmitUpdateMyClub)}>
              <div className="col-span-3">
                <h2 className="text-gray-700 font-bold text-lg sm:text-xl md:text-xl">
                  Update Club
                </h2>
                <div className="flex flex-col md:grid md:grid-cols-3 gap-4 py-4">
                  <FormInputText
                    label={'Club Name'}
                    htmlFor={'clubName'}
                    placeholder={'Zarb-e-Mujahid'}
                    register={register}
                  />
                  <div className="flex flex-col gap-1">
                    <label className="font-bold">Country</label>
                    <CountryDropdown
                      classes="px-3 py-2 rounded shadow"
                      value={data.country}
                      disabled
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <label className="font-bold">Province</label>
                    <RegionDropdown
                      disabled
                      classes="px-3 py-2 rounded shadow"
                      showDefaultOption={false}
                      value={data.province}
                      country={data.country}
                    />
                  </div>
                  <button className="row-start-2 col-span-1 rounded hover:bg-gray-800 uppercase bg-gray-700 text-gray-100 font-bold py-2 px-2">
                    update club
                  </button>
                </div>
              </div>
            </form>
          )}
        </Scrollbars>
      </div>
    );
  }
}
