import React from 'react';

export default function ExaminationsDetail({ athlete }) {
  const renderedProfiles = athlete.examinationProfiles.map(
    (examinationProfile) => {
      const testsArray = Object.entries(examinationProfile.promotionTests);

      const renderedPromotionCategories = testsArray.map((test, i) => {
        const title = `${test[0]
          .split(/(?=[A-Z])/)[0][0]
          .toUpperCase()}${test[0].split(/(?=[A-Z])/)[0].slice(1)} ${
          test[0].split(/(?=[A-Z])/)[1] ? test[0].split(/(?=[A-Z])/)[1] : ''
        } ${
          test[0].split(/(?=[A-Z])/)[2] ? test[0].split(/(?=[A-Z])/)[2] : ''
        }`;
        return (
          <div key={i}>
            <div
              className={`flex p-4 justify-between md:flex-col ${
                test[1].score >= 5 ? `bg-gray-700` : `bg-red-500`
              } text-white font-bold py-5 rounded shadow`}
            >
              <h3 className="md:text-center">{title}</h3>
              <p className="md:text-7xl text-center">{test[1].score}</p>
            </div>
          </div>
        );
      });
      return (
        <div key={examinationProfile._id}>
          <h1 className="text-xl font-bold text-gray-800 mb-2">
            Promotion Test for Dan {examinationProfile.appliedDan} |{' '}
            {examinationProfile.event?.title}
          </h1>
          <div className="grid grid-cols-2 md:grid-cols-5 md:grid-rows-2 gap-2 mb-2">
            {renderedPromotionCategories}
          </div>
        </div>
      );
    }
  );

  return <div>{renderedProfiles}</div>;
}
