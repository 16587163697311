import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetPoomsaeStopwatch,
  setPoomsaeStopwatchTime,
  useGetOperationalDataQuery,
} from '../../store';
import { socket } from '../../utils/socket';
// import "./stopwatch.css";

const Stopwatch = () => {
  const dispatch = useDispatch();
  const { poomsaeStopwatch } = useSelector((state) => state.data);
  const { data } = useGetOperationalDataQuery('poomsae');

  socket.on('resetPoomsaeStopwatch', () => dispatch(resetPoomsaeStopwatch()));

  useEffect(() => {
    let intervalId;
    if (data.stopwatchRunning === 'true' || data.stopwatchRunning === true) {
      // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
      intervalId = setInterval(
        () => dispatch(setPoomsaeStopwatchTime(poomsaeStopwatch.time + 1)),
        10
      );
    }
    return () => clearInterval(intervalId);
  }, [
    poomsaeStopwatch.isRunning,
    dispatch,
    poomsaeStopwatch.time,
    data.stopwatchRunning,
  ]);

  // Minutes calculation
  const minutes = Math.floor((poomsaeStopwatch.time % 360000) / 6000);

  // Seconds calculation
  const seconds = Math.floor((poomsaeStopwatch.time % 6000) / 100);

  // Milliseconds calculation
  const milliseconds = poomsaeStopwatch.time % 100;

  return (
    <div className="scale-500 translate-y-[-0.5rem] text-5xl font-bold">
      <p className="stopwatch-time text-2xl">
        {minutes.toString().padStart(1, '0')}:
        {seconds.toString().padStart(2, '0')}:
        {milliseconds.toString().padStart(2, '0')}
      </p>
    </div>
  );
};

export default Stopwatch;
