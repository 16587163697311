function FormInputPs({
  labelEn,
  labelDr,
  htmlFor,
  placeholder,
  type,
  register,
  className,
  ...rest
}) {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between">
        <label
          className="font-bold text-gray-800 font-pashto text-xl"
          htmlFor={htmlFor}
        >
          {labelDr}
        </label>
        <label
          className="font-bold text-gray-800 text-xl font-pashto"
          htmlFor={htmlFor}
        >
          {labelEn}
        </label>
      </div>
      <input
        className={`px-3 py-2 rounded shadow font-semibold hover:ring hover:ring-gray-400 transition-all ease-in focus:ring  focus:ring-gray-700 ${className} `}
        type={type || 'text'}
        name={htmlFor}
        id={htmlFor}
        {...rest}
        placeholder={placeholder}
        {...register(htmlFor)}
      />
    </div>
  );
}

export default FormInputPs;
