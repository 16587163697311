// import 'dotenv/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// import socket from '../../utils/socket';
import { socket } from '../../utils/socket';
import secureLocalStorage from 'react-secure-storage';

const user = JSON.parse(secureLocalStorage.getItem('user'));

const mainApi = createApi({
  reducerPath: 'mainApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_PROXY_URL}/api/v1/`,
    prepareHeaders: (header, { getState }) => {
      const { token } = getState().authentication;
      header.set('authorization', `Bearer ${token}`);
    },
    credentials: 'include',
  }),
  endpoints(builder) {
    return {
      /**
       * AUTHENTICATION
       */

      userLogin: builder.mutation({
        query: (body) => {
          return {
            url: 'users/login',
            method: 'POST',
            body,
          };
        },
      }),
      verifyUserLogin: builder.query({
        query: () => {
          return {
            url: '/users/verifyLogin',
            method: 'GET',
          };
        },
      }),
      userLogout: builder.mutation({
        invalidatesTags: [
          'athletes',
          'trainerAthletes',
          'athlete',
          'activeExamAthletes',
        ],
        query: () => {
          return {
            url: 'users/logout',
            method: 'GET',
          };
        },
      }),
      userSignup: builder.mutation({
        query: (body) => {
          return {
            url: 'users/signup',
            method: 'POST',
            body,
          };
        },
      }),
      getAllUsers: builder.query({
        query: () => {
          return {
            url: 'users',
            method: 'GET',
          };
        },
      }),
      updateMe: builder.mutation({
        query: (body) => {
          return {
            url: 'users/updateMe',
            method: 'PATCH',
            body,
          };
        },
      }),
      updateMyPassword: builder.mutation({
        query: (body) => {
          return {
            url: 'users/updateMyPassword',
            method: 'PATCH',
            body,
          };
        },
      }),

      /**
       * ATHLETES
       */
      getAthlete: builder.query({
        providesTags: ['athlete'],
        query: (athleteId) => {
          return {
            url: `athletes/${athleteId}`,
            method: 'GET',
          };
        },
      }),
      getAllAthletes: builder.query({
        providesTags: ['athletes'],
        query: () => `athletes`,
        async onCacheEntryAdded(
          arg,
          { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
        ) {
          try {
            await cacheDataLoaded;
            socket.on('new-athlete', (data) => {
              updateCachedData((draft) => (draft = [...draft, data]));
            });

            socket.on('update-athlete', (data) => {
              updateCachedData((draft) => {
                const index = draft.findIndex(
                  (athlete) => athlete._id === data._id
                );
                draft.splice(index, 1, data);
              });
            });
            socket.on('delete-athlete', (data) => {
              updateCachedData((draft) => {
                const index = draft.findIndex(
                  (athlete) => athlete._id === data._id
                );
                draft.splice(index, 1);
              });
            });
          } catch {
            console.log('error in socket (all athletes fetch)');
          }
          await cacheEntryRemoved;
        },
      }),

      athleteLookup: builder.query({
        query: (query) => {
          return {
            url: `athletes/athlete/detail?${query}`,
            method: 'GET',
          };
        },
      }),
      addAthlete: builder.mutation({
        // invalidatesTags: ['athletes', 'trainerAthletes'],
        query: (body) => {
          return {
            url: 'athletes/',
            method: 'POST',
            body,
          };
        },
      }),
      updateAthlete: builder.mutation({
        invalidatesTags: [
          // 'athletes',
          'athlete',
          // 'trainerAthletes',
        ],
        query: ({ id, body }) => {
          return {
            url: `athletes/${id}`,
            method: 'PATCH',
            body: body,
          };
        },
      }),
      deleteAthlete: builder.mutation({
        // invalidatesTags: ['athletes', 'trainerAthletes'],
        query: (id) => {
          return {
            url: `athletes/${id}`,
            method: 'DELETE',
          };
        },
      }),
      addCertificate: builder.mutation({
        invalidatesTags: ['athlete', 'athletes'],
        query: ({ id, ...patch }) => {
          return {
            url: `athletes/certificate/${id}`,
            method: 'PATCH',
            body: patch,
          };
        },
      }),

      /**
       * USERS, CLUBS, TRAINERS
       */
      addClub: builder.mutation({
        invalidatesTags: ['clubs'],
        query: (body) => {
          return {
            url: 'clubs/',
            method: 'POST',
            body,
          };
        },
      }),
      getAllClubs: builder.query({
        providesTags: ['clubs'],
        query: () => {
          return {
            url: 'clubs/',
            method: 'GET',
          };
        },
      }),
      updateClub: builder.mutation({
        invalidatesTags: ['clubs', 'trainerClub'],

        query: ({ id, ...patch }) => {
          return {
            url: `clubs/${id}`,
            method: 'PATCH',
            body: patch,
          };
        },
      }),
      addAthleteRefToClub: builder.mutation({
        invalidatesTags: ['clubs', 'club', 'trainerAthletes'],
        query: ({ athleteId, clubId }) => {
          return {
            url: `clubs/addAthleteRef/${athleteId}/${clubId}`,
            method: 'POST',
          };
        },
      }),
      getAllAthletesTrainer: builder.query({
        providesTags: ['trainerAthletes'],
        query: (trainerId) => {
          return {
            url: `athletes/trainer/${trainerId}`,
            method: 'GET',
          };
        },
        async onCacheEntryAdded(
          arg,
          { cacheDataLoaded, cacheEntryRemoved, updateCachedData }
        ) {
          try {
            await cacheDataLoaded;

            socket.on('new-athlete', (athlete) => {
              if (athlete.club.trainer === user._id) {
                updateCachedData((draft) => (draft = [...draft, athlete]));
              }
            });

            socket.on('update-athlete', (athlete) => {
              if (athlete.club.trainer === user._id) {
                updateCachedData((draft) => {
                  const index = draft.findIndex(
                    (draftAthlete) => draftAthlete._id === athlete._id
                  );
                  draft.splice(index, 1, athlete);
                });
              }
            });
            socket.on('delete-athlete', (athlete) => {
              if (athlete.club.trainer === user._id) {
                updateCachedData((draft) => {
                  const index = draft.findIndex(
                    (draftAthlete) => draftAthlete._id === athlete._id
                  );
                  draft.splice(index, 1);
                });
              }
            });
          } catch (error) {
            console.log(
              'error with socket cache entry: all athletes trainer.',
              error
            );
          }
          await cacheEntryRemoved;
        },
      }),
      getTrainerClub: builder.query({
        providesTags: ['trainerClub'],

        query: (trainerId) => {
          return {
            url: `clubs/trainer/${trainerId}`,
            method: 'GET',
          };
        },
      }),
      getAllTrainers: builder.query({
        query: () => {
          return {
            url: 'users/trainers',
            method: 'GET',
          };
        },
      }),

      /**
       * EVENTS
       */

      getAllEvents: builder.query({
        providesTags: ['events'],
        query: () => {
          return {
            url: 'events/',
            method: 'GET',
          };
        },
      }),

      getEvent: builder.query({
        providesTags: ['event'],
        query: ({ eventType, eventId }) => {
          return {
            url: `events/${eventType}/edit/${eventId}`,
            method: 'GET',
          };
        },
        async onCacheEntryAdded(
          arg,
          { cacheDataLoaded, cacheEntryRemoved, updateCachedData }
        ) {
          try {
            await cacheDataLoaded;

            socket.on('add-athlete-to-event', ({ athlete, eventId }) => {
              updateCachedData((draft) => {
                if (draft._id === eventId) {
                  return {
                    ...draft,
                    participants: [...draft.participants, athlete],
                  };
                } else return draft;
              });
            });

            socket.on('remove-athlete-from-event', ({ athlete, eventId }) => {
              updateCachedData((draft) => {
                if (draft._id === eventId) {
                  const draftAthleteIndex = draft.participants.findIndex(
                    (draftAthlete) => draftAthlete._id === athlete._id
                  );
                  draft.participants.splice(draftAthleteIndex, 1);
                } else return draft;
              });
            });

            socket.on('update-athlete-event', ({ athlete, eventId }) => {
              updateCachedData((draft) => {
                const draftAthleteIndex = draft.participants.findIndex(
                  (draftAthlete) => draftAthlete._id === athlete._id
                );
                if (draftAthleteIndex !== -1 && draft._id === eventId) {
                  draft.participants.splice(draftAthleteIndex, 1, athlete);
                }
              });
            });
          } catch (error) {
            console.log('error in socket: event.', error);
          }
          await cacheEntryRemoved;
        },
      }),
      addEvent: builder.mutation({
        invalidatesTags: ['events'],
        query: (body) => {
          return {
            url: `events/${body.get('type')}`,
            method: 'POST',
            body,
          };
        },
      }),
      updateEvent: builder.mutation({
        invalidatesTags: ['event', 'events'],
        query: ({ eventType, eventId, body }) => {
          return {
            url: `events/${eventType}/edit/${eventId}`,
            method: 'PATCH',
            body,
          };
        },
      }),

      addAthleteRefEvent: builder.mutation({
        // invalidatesTags: ['event', 'events'],
        query: ({ eventType, ...body }) => {
          return {
            url: `events/${eventType}/athlete`,
            method: 'POST',
            body,
          };
        },
      }),
      removeAthleteRefEvent: builder.mutation({
        // invalidatesTags: ['event'],
        query: ({ eventType, eventId, athleteId }) => {
          return {
            url: `events/${eventType}/athlete/${eventId}/${athleteId}`,
            method: 'DELETE',
          };
        },
      }),
      getOperationalData: builder.query({
        providesTags: ['operationalData'],
        query: (eventType) => {
          return {
            url: `events/${eventType}/operational`,
            method: 'GET',
          };
        },
        async onCacheEntryAdded(
          arg,
          { cacheDataLoaded, cacheEntryRemoved, updateCachedData }
        ) {
          try {
            await cacheDataLoaded;
            socket.on('activePoomsaeProfile', (data) => {
              updateCachedData((draft) => ({ ...draft, activeProfile: data }));
            });
            socket.on('updatedPoomsaeOperationalData', (data) => {
              updateCachedData((draft) => {
                return { ...draft, ...data };
              });
            });
          } catch {
            console.log('error in socket (all athletes fetch)');
          }
          await cacheEntryRemoved;
        },
      }),
      updateOperationalData: builder.mutation({
        invalidatesTags: ['operationalData', 'event', 'events'],
        query: ({ eventType, ...patch }) => {
          return {
            url: `events/${eventType}/operational`,
            method: 'PATCH',
            body: patch,
          };
        },
      }),
      /**
       * Dan Grade Certificates
       */

      getAllDanGradeCertificates: builder.query({
        query: (eventId) => {
          return {
            url: `events/examination/certificate/${eventId}`,
            method: 'GET',
          };
        },
      }),

      /**
       * EXAMINATION SYSTEM
       */

      updateExamProfile: builder.mutation({
        invalidatesTags: ['athlete', 'athletes'],
        query: ({ profileId, ...patch }) => {
          return {
            url: `athleteExamProfile/${profileId}`,
            method: 'PATCH',
            body: patch,
          };
        },
      }),

      /**
       * Poomsae System
       */
      addPoomsaeTeamAndDuo: builder.mutation({
        // invalidatesTags: ['event', 'events'],

        query: (body) => {
          return {
            url: `events/poomsae/athlete/duoTeam`,
            method: 'POST',
            body,
          };
        },
      }),
      removePoomsaeTeamAndDuo: builder.mutation({
        // invalidatesTags: ['event', 'events'],
        query: ({ profileId, eventId }) => {
          return {
            url: `events/poomsae/athlete/duoTeam/${profileId}/${eventId}`,
            method: 'DELETE',
          };
        },
      }),
      updatePoomsaeProfile: builder.mutation({
        invalidatesTags: ['athlete', 'athletes'],
        query: ({ profileId, ...patch }) => {
          return {
            url: `athletePoomsaeProfile/${profileId}`,
            method: 'PATCH',
            body: patch,
          };
        },
      }),
      submitPoomsaePoint: builder.mutation({
        query: (body) => {
          return {
            url: `events/poomsae/point`,
            method: 'POST',
            body,
          };
        },
      }),
      deletePoomsaePoint: builder.mutation({
        invalidatesTags: ['athlete', 'event'],

        query: ({ roundId, pointId }) => {
          return {
            url: `events/poomsae/point/${roundId}/${pointId}`,
            method: 'DELETE',
          };
        },
      }),
      addPenalty: builder.mutation({
        query: (body) => {
          return {
            url: `events/poomsae/point/penalty`,
            method: 'POST',
            body,
          };
        },
      }),
      deletePenalty: builder.mutation({
        query: (body) => {
          return {
            url: `events/poomsae/point/penalty`,
            method: 'PATCH',
            body,
          };
        },
      }),
    };
  },
});

export { mainApi };
export const {
  useUserLoginMutation,
  useVerifyUserLoginQuery,
  useUserLogoutMutation,
  useUserSignupMutation,
  useGetAllUsersQuery,
  useUpdateMeMutation,
  useUpdateMyPasswordMutation,
  useGetAllTrainersQuery,
  useGetTrainerClubQuery,
  useGetAllAthletesQuery,
  useGetAllAthletesTrainerQuery,
  useGetAllEventsQuery,
  useGetEventQuery,
  useLazyGetAllDanGradeCertificatesQuery,
  useGetAthleteQuery,
  useGetOperationalDataQuery,
  useLazyAthleteLookupQuery,
  useAddAthleteMutation,
  useAddCertificateMutation,
  useAddAthleteRefEventMutation,
  useAddEventMutation,
  useSubmitPoomsaePointMutation,
  useDeletePoomsaePointMutation,
  useAddPoomsaeTeamAndDuoMutation,
  useRemovePoomsaeTeamAndDuoMutation,
  useAddPenaltyMutation,
  useDeletePenaltyMutation,
  useRemoveAthleteRefEventMutation,
  useDeleteAthleteMutation,
  useUpdateAthleteMutation,
  useUpdateEventMutation,
  useUpdateExamProfileMutation,
  useUpdatePoomsaeProfileMutation,
  useUpdateOperationalDataMutation,
  useAddClubMutation,
  useUpdateClubMutation,
  useGetAllClubsQuery,
  useAddAthleteRefToClubMutation,
} = mainApi;
