import { createSlice } from '@reduxjs/toolkit';
const poomsaeActiveStage = localStorage.getItem('poomsaeActiveStage') || '';
const poomsaeJudgeRole = localStorage.getItem('poomsaeJudgeRole') || '';
const activeTab = localStorage.getItem('activeTab') || 'Dashboard';

const dataSlice = createSlice({
  name: 'dataSlice',
  initialState: {
    activeTab,
    athleteNameSearchTerm: '',
    athleteClubSearchTerm: '',
    athleteProvinceSearch: '',
    athleteDanSearch: '',
    athletePromoEligibilityFilter: '',
    athleteFeeSearchTerm: '',
    athleteStatusSearchTerm: '',
    poomsaeCategorySearchTerm: '',
    poomsaePreliminarySearchTerm: '',
    poomsaeSemiFinalSearchTerm: '',
    poomsaeFinalSearchTerm: '',
    clubNameSearchTerm: '',
    menuIsOpen: false,
    showUserProfileModal: false,
    showCertificateModal: false,
    showUserModal: false,
    showClubModal: false,
    showViewClubModal: false,
    showExamModal: false,
    showManageExamEventCertificatesModal: false,
    showPoomsaeControlsModal: false,
    showJudgeRoleConfirmModal: false,
    showTeamAndDuoCreationModal: false,
    showTeamAndDuoPointsSummaryModal: false,
    poomsaeActiveStage,
    poomsaeJudgeRole,
    poomsaeStopwatch: {
      time: 0,
      isRunning: false,
    },
    eventTypeFilterTerm: '',
    eventStatusFilterTerm: '',
    eventNameSearchTerm: '',
    pageScrollPosition: 0,
  },
  reducers: {
    setActiveTab(state, { payload }) {
      state.activeTab = payload;
      localStorage.setItem('activeTab', payload);
    },
    setAthleteNameSearchTerm(state, { payload }) {
      state.athleteNameSearchTerm = payload;
    },
    setAthleteClubSearchTerm(state, { payload }) {
      state.athleteClubSearchTerm = payload;
    },
    setAthletesState(state, { payload }) {
      state.athletes = payload;
    },
    setAthleteProvinceSearch(state, { payload }) {
      state.athleteProvinceSearch = payload;
    },
    setAthleteDanSearch(state, { payload }) {
      state.athleteDanSearch = payload;
    },
    setAthletePromoEligibilityFilter(state, { payload }) {
      state.athletePromoEligibilityFilter = payload;
    },
    setAthleteFeeSearchTerm(state, { payload }) {
      state.athleteFeeSearchTerm = payload;
    },
    setAthleteStatusSearchTerm(state, { payload }) {
      state.athleteStatusSearchTerm = payload;
    },
    setPoomsaeCategorySearchTerm(state, { payload }) {
      state.poomsaeCategorySearchTerm = payload;
    },
    setPoomsaePreliminarySearchTerm(state, { payload }) {
      state.poomsaePreliminarySearchTerm = payload;
    },
    setPoomsaeSemiFinalSearchTerm(state, { payload }) {
      state.poomsaeSemiFinalSearchTerm = payload;
    },
    setPoomsaeFinalSearchTerm(state, { payload }) {
      state.poomsaeFinalSearchTerm = payload;
    },

    setClubNameSearchTerm(state, { payload }) {
      state.clubNameSearchTerm = payload;
    },
    setMenuIsOpen(state, { payload }) {
      if (payload === true || payload === false) {
        state.menuIsOpen = payload;
      }
      if (payload === undefined) {
        state.menuIsOpen = !state.menuIsOpen;
      }
    },
    setShowUserProfileModal(state) {
      state.showUserProfileModal = !state.showUserProfileModal;
    },
    setShowCertificateModal(state) {
      state.showCertificateModal = !state.showCertificateModal;
    },
    setShowUserModal(state) {
      state.showUserModal = !state.showUserModal;
    },
    setShowClubModal(state) {
      state.showClubModal = !state.showClubModal;
    },
    setShowViewClubModal(state, { payload }) {
      state.showViewClubModal = payload;
    },
    setShowExamModal(state) {
      state.showExamModal = !state.showExamModal;
    },
    setShowManageExamEventCertificatesModal(state) {
      state.showManageExamEventCertificatesModal =
        !state.showManageExamEventCertificatesModal;
    },
    setShowPoomsaeControlsModal(state) {
      state.showPoomsaeControlsModal = !state.showPoomsaeControlsModal;
    },
    setShowJudgeConfirmRoleModal(state, { payload }) {
      state.showJudgeRoleConfirmModal = payload;
    },
    setShowTeamAndDuoCreationModal(state) {
      state.showTeamAndDuoCreationModal = !state.showTeamAndDuoCreationModal;
    },
    setShowTeamAndDuoPointsSummaryModal(state) {
      state.showTeamAndDuoPointsSummaryModal =
        !state.showTeamAndDuoPointsSummaryModal;
    },
    setPoomsaeActiveStage(state, { payload }) {
      state.poomsaeActiveStage = payload;
    },
    setPoomsaeStopwatchTime(state, { payload }) {
      state.poomsaeStopwatch.time = payload;
    },
    setPoomsaeStopwatchRunning(state) {
      state.poomsaeStopwatch.isRunning = !state.poomsaeStopwatch.isRunning;
    },
    resetPoomsaeStopwatch(state) {
      state.poomsaeStopwatch.time = 0;
      state.poomsaeStopwatch.isRunning = false;
    },
    setPoomsaeJudgeRole(state, { payload }) {
      state.poomsaeJudgeRole = payload;
    },
    setEventNameSearchTerm(state, { payload }) {
      state.eventNameSearchTerm = payload;
    },
    setEventTypeFilterTerm(state, { payload }) {
      state.eventTypeFilterTerm = payload;
    },
    setEventStatusFilterTerm(state, { payload }) {
      state.eventStatusFilterTerm = payload;
    },
    setPageScrollPosition(state, { payload }) {
      state.pageScrollPosition = payload;
    },
  },
});

export const dataReducer = dataSlice.reducer;
export const {
  setActiveTab,
  setAthleteNameSearchTerm,
  setAthleteClubSearchTerm,
  setAthleteProvinceSearch,
  setAthleteDanSearch,
  setAthletePromoEligibilityFilter,
  setAthleteFeeSearchTerm,
  setAthleteStatusSearchTerm,
  setPoomsaeFinalSearchTerm,
  setPoomsaePreliminarySearchTerm,
  setPoomsaeSemiFinalSearchTerm,
  setPoomsaeCategorySearchTerm,
  setEventNameSearchTerm,
  setEventStatusFilterTerm,
  setEventTypeFilterTerm,
  setClubNameSearchTerm,
  setMenuIsOpen,
  setShowUserProfileModal,
  setShowCertificateModal,
  setShowClubModal,
  setShowViewClubModal,
  setShowExamModal,
  setShowManageExamEventCertificatesModal,
  setShowUserModal,
  setShowPoomsaeControlsModal,
  setShowJudgeConfirmRoleModal,
  setShowTeamAndDuoCreationModal,
  setShowTeamAndDuoPointsSummaryModal,
  setPoomsaeActiveStage,
  resetPoomsaeStopwatch,
  setPoomsaeStopwatchRunning,
  setPoomsaeStopwatchTime,
  setPoomsaeJudgeRole,
  setPageScrollPosition,
} = dataSlice.actions;
