import { useNavigate } from 'react-router-dom';
import MainHeading from '../components/uiComponents/MainHeading';
import Panel from '../components/uiComponents/Panel';
import Scrollbars from 'rc-scrollbars';

function BlankPage({ form, title }) {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-4 h-full">
      <Panel className="print:hidden">
        <div className="flex justify-between items-center">
          <MainHeading className={'text-center'}>{title}</MainHeading>
          <div className="flex flex-col md:flex-row gap-2">
            <button
              className="uppercase h-8 w-auto py-1 px-2 md:h-10 md:py-2 md:px-3 font-bold bg-gray-700 text-white rounded-md shadow hover:bg-gray-800 transition"
              onClick={() => navigate(-1)}
            >
              back
            </button>
            {/* <Link
              to={'/dashboard'}
              className="uppercase h-8 w-auto py-1 px-2 md:h-10 md:px-3 md:p-2 font-bold bg-gray-700 text-white rounded-md shadow hover:bg-gray-800 transition"
            >
              Dashboard
            </Link> */}
          </div>
        </div>
      </Panel>
      <Scrollbars className="rounded-md">{form}</Scrollbars>
    </div>
  );
}

export default BlankPage;
