import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  useAddAthleteMutation,
  useGetAllClubsQuery,
  useGetAthleteQuery,
  useGetTrainerClubQuery,
  useUpdateAthleteMutation,
} from '../../store';
import Notiflix from 'notiflix';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Scrollbars from 'rc-scrollbars';
import Select from '../uiComponents/Select';
import FormInputPs from '../uiComponents/FormInputPs';
import Panel from '../uiComponents/Panel';
import { showLoadingEffect, trimCapitalize } from '../../utils/helpers';

function AthleteForm() {
  const { athleteId } = useParams();
  const navigate = useNavigate();

  const { user, isTrainer } = useSelector((state) => state.authentication);

  const [selectedClub, setSelectedClub] = useState('');
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedCurrentDan, setSelectedCurrentDan] = useState('');
  const [selectedBelt, setSelectedBelt] = useState('');

  const [disableInputs, setDisableInputs] = useState(false);

  const { register, handleSubmit, setValue, reset } = useForm();
  const [addAthlete, addAthleteResults] = useAddAthleteMutation();
  const {
    data: clubsApi,
    isLoading: clubsLoading,
    isError: clubsError,
  } = useGetAllClubsQuery('', { skip: isTrainer });

  const {
    data: trainerClub,
    isLoading: trainerClubLoading,
    isError: trainerClubError,
  } = useGetTrainerClubQuery(user._id, { skip: user.role === 'admin' });

  const [updateAthlete, updateAthleteResults] = useUpdateAthleteMutation();

  const {
    data: athleteApi,
    isLoading: athleteApiIsLoading,
    isError: athleteApiIsError,
  } = useGetAthleteQuery(athleteId, { skip: !athleteId });

  useEffect(() => {
    if (athleteApi) {
      const DOB = new Date(athleteApi.dateOfBirth).toISOString().split('T')[0];

      const danIssue = athleteApi.danGradesKukkiwon.slice(-1)[0]?.issueDate
        ? new Date(athleteApi.danGradesKukkiwon.slice(-1)[0]?.issueDate)
            .toISOString()
            .split('T')[0]
        : '';

      setValue('firstNameEn', athleteApi.firstNameEn);
      setValue('middleNameEn', athleteApi.middleNameEn);
      setValue('lastNameEn', athleteApi.lastNameEn);
      setValue('firstNameDr', athleteApi.firstNameDr);
      setValue('middleNameDr', athleteApi.middleNameDr);
      setValue('lastNameDr', athleteApi.lastNameDr);
      setValue(
        'danCertificate',
        athleteApi.danGradesKukkiwon.slice(-1)[0]?.danCertificate || ''
      );
      if (user.role === 'admin') {
        setValue('tconUsername', athleteApi.tcon?.username);
        setValue('tconPassword', athleteApi.tcon?.password);
      }

      if (isTrainer && athleteApi.profileLocked) setDisableInputs(true);

      setSelectedClub({
        label: athleteApi.club?.name,
        value: athleteApi.club?._id,
      });
      setSelectedBelt({
        label: athleteApi.belt,
        value: athleteApi.belt,
      });
      setSelectedGender({
        label: athleteApi.gender,
        value: athleteApi.gender,
      });
      setSelectedCurrentDan({
        label: athleteApi.danGradesKukkiwon.slice(-1)[0]?.dan,
        value: athleteApi.danGradesKukkiwon.slice(-1)[0]?.dan,
      });
      setValue('dateOfBirth', DOB);
      setValue('issueDate', danIssue || '');
    }
  }, [athleteApi, isTrainer, setValue, user]);

  useEffect(() => {
    showLoadingEffect(clubsApi, clubsLoading, clubsError);
  }, [clubsLoading, clubsError, clubsApi]);

  useEffect(() => {
    showLoadingEffect(trainerClub, trainerClubLoading, trainerClubError);
  }, [trainerClubLoading, trainerClubError, trainerClub]);

  useEffect(() => {
    const { isLoading, isError, isSuccess, error } = addAthleteResults;

    showLoadingEffect(isSuccess, isLoading, isError, error);
    if (error) {
      if (error.data.error.code === 11000) {
        Notiflix.Report.merge({
          rtl: true,
          fontFamily: 'Bahij Nazanin',
          titleFontSize: '24px',
          messageFontSize: '16px',
          buttonFontSize: '16px',
        });
        Notiflix.Report.failure(
          'بازیکن ثبت شده است',
          'بازیکن را که شما حالی میخواهید ثبت کنید در سیستم موجود میباشد. این به دو وجه صورت گرفته میتواند: 1- شما دوباره ثبت  نام میکنید. 2- بازیکن از طریق یوزر دیگری ثبت نام کرده است ',
          'درست است'
        );
      }
    }
    if (isSuccess) {
      reset();
      setSelectedClub('');
      setSelectedCurrentDan('');
      setSelectedGender('');
      setSelectedBelt('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addAthleteResults]);

  useEffect(() => {
    showLoadingEffect(athleteApi, athleteApiIsLoading, athleteApiIsError);
  }, [athleteApiIsLoading, athleteApiIsError, athleteApi]);

  useEffect(() => {
    const { isLoading, isError, isSuccess } = updateAthleteResults;
    showLoadingEffect(isSuccess, isLoading, isError);

    if (isSuccess) {
      navigate(-1);
    }
  }, [updateAthleteResults, navigate]);

  useEffect(() => {
    if (athleteApi?.profileLocked && isTrainer) {
      Notiflix.Report.merge({
        rtl: true,
        fontFamily: 'Bahij Nazanin',
        titleFontSize: '24px',
        messageFontSize: '16px',
        buttonFontSize: '16px',
      });
      Notiflix.Report.info(
        'تغیرات منع است',
        'تغیرات آوردن در خلص سوانح  بغیر از عکس و یا تذکره این ورزشکار توسط فدراسیون بسته شده است. در صورت درخواست به تغیرات، با فدراسیون به تماس شوید',
        'درست است'
      );
    }
  }, [athleteApi, isTrainer]);
  const danGradeOptions = [
    { label: '1st Dan', value: 1, className: 'py-2' },
    { label: '2nd Dan', value: 2, className: 'py-2' },
    { label: '3rd Dan', value: 3, className: 'py-2' },
    { label: '4th Dan', value: 4, className: 'py-2' },
    { label: '5th Dan', value: 5, className: 'py-2' },
    { label: '6th Dan', value: 6, className: 'py-2' },
  ];

  const genderOptions = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
  ];

  const beltOptions = [
    { label: 'سفید', value: 'white' },
    { label: 'زرد', value: 'yellow' },
    { label: 'سبز', value: 'green' },
    { label: 'آبی', value: 'blue' },
    { label: 'سرخ', value: 'red' },
    { label: 'سیاه', value: 'black' },
  ];

  const handleClubChange = (option) => {
    setSelectedClub(option);
  };
  const handleCurrentDanChange = (option) => {
    setSelectedCurrentDan(option);
  };
  const handleGenderChange = (option) => {
    setSelectedGender(option);
  };
  const handleBeltChange = (option) => {
    setSelectedBelt(option);
  };

  const handleFormSubmit = (data, event) => {
    event.preventDefault();
    const formData = new FormData();

    if (isTrainer && athleteApi?.profileLocked) {
      if (data.photo[0] || data.nid[0]) {
        formData.append(
          'photo',
          data.photo[0] ? data.photo[0] : athleteApi.photo
        );
        formData.append('nid', data.nid[0] ? data.nid[0] : athleteApi.nid);
        formData.append('firstNameEn', trimCapitalize(data.firstNameEn));
        formData.append('middleNameEn', trimCapitalize(data.middleNameEn));
        formData.append('lastNameEn', trimCapitalize(data.lastNameEn));
        return updateAthlete({ id: athleteId, body: formData });
      }
      return;
    }

    const dataWithCorrectFileFields = {
      ...data,
      firstNameDr: trimCapitalize(data.firstNameDr),
      middleNameDr: trimCapitalize(data.middleNameDr),
      lastNameDr: trimCapitalize(data.lastNameDr),
      firstNameEn: trimCapitalize(data.firstNameEn),
      middleNameEn: trimCapitalize(data.middleNameEn),
      lastNameEn: trimCapitalize(data.lastNameEn),
      belt: selectedBelt.value,
      club: isTrainer ? trainerClub._id : selectedClub.value,
      gender: selectedGender.value,
      fullNameEn: `${trimCapitalize(data.firstNameEn)}${
        data.middleNameEn ? ` ${trimCapitalize(data.middleNameEn)}` : ''
      } ${trimCapitalize(data.lastNameEn)}`,
      fullNameDr: `${trimCapitalize(data.firstNameDr)}${
        data.middleNameDr ? ` ${trimCapitalize(data.middleNameDr)}` : ''
      } ${trimCapitalize(data.lastNameDr)}`,
      nid: data.nid[0] ? data.nid[0] : athleteApi.nid,
      photo: data.photo[0] ? data.photo[0] : athleteApi.photo,
      dan: selectedCurrentDan?.value,
    };
    Object.entries(dataWithCorrectFileFields)
      .reverse()
      .forEach((entry) => {
        formData.append(entry[0], entry[1]);
      });

    if (athleteId) {
      const danGradesKukkiwonClone = structuredClone(
        athleteApi.danGradesKukkiwon
      );

      danGradesKukkiwonClone.pop();

      danGradesKukkiwonClone.push({
        // ...danGradesKukkiwonClone.slice(-1)[0],
        dan: formData.get('dan'),
        issueDate: formData.get('issueDate'),
        danCertificate: formData.get('danCertificate'),
      });
      if (athleteApi.danGradesKukkiwon.length > 0) {
        formData.append(
          'danGradesKukkiwon',
          JSON.stringify(danGradesKukkiwonClone)
        );
      }

      updateAthlete({ id: athleteId, body: formData });
    } else {
      if (!dataWithCorrectFileFields.club) {
        Notiflix.Notify.failure('Please Choose a club.');
      } else if (!dataWithCorrectFileFields.gender) {
        Notiflix.Notify.failure('Please select a gender.');
      } else if (!dataWithCorrectFileFields.belt) {
        Notiflix.Notify.failure('Please Select a belt.');
      } else {
        addAthlete(formData);
      }
    }
  };

  if (clubsApi || trainerClub) {
    const docRequired = athleteId ? false : true;
    let clubs;
    if (clubsApi) {
      clubs = clubsApi.map((club) => {
        return {
          label: club.name,
          value: club._id,
        };
      });
    }
    return (
      <Panel className="h-full">
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="flex flex-col h-full"
        >
          <Scrollbars className="rounded-md">
            <div
              className={`grid grid-cols-1 md:grid-cols-3 gap-4 md:p-2 h-fit`}
            >
              <FormInputPs
                required={true}
                labelEn={'لومړی نوم په انګلیسي'}
                labelDr={'اسم اول به انگلیسی'}
                htmlFor={'firstNameEn'}
                placeholder={'Mohammad'}
                register={register}
                disabled={disableInputs}
              />
              <FormInputPs
                labelEn={'منځنی نوم په انګلیسي'}
                labelDr={'اسم وسط به انگلیسی'}
                htmlFor={'middleNameEn'}
                placeholder={'Anwar'}
                register={register}
                disabled={disableInputs}
              />
              <FormInputPs
                required={true}
                labelEn={'تخلص په انګلیسي'}
                labelDr={'تخلص به انگلیسی'}
                htmlFor={'lastNameEn'}
                placeholder={'Asghari'}
                register={register}
                disabled={disableInputs}
              />
              <FormInputPs
                required={true}
                labelEn={'لومړی نوم په پښتو'}
                labelDr={'اسم اول به دری'}
                htmlFor={'firstNameDr'}
                placeholder={'محمد'}
                className={'font-pashto text-lg'}
                register={register}
                disabled={disableInputs}
              />
              <FormInputPs
                labelEn={'منځنی نوم په پښتو'}
                labelDr={'اسم وسط به دری'}
                htmlFor={'middleNameDr'}
                placeholder={'انور'}
                className={'font-pashto text-lg'}
                register={register}
                disabled={disableInputs}
              />
              <FormInputPs
                className={'font-pashto text-lg'}
                required={true}
                labelEn={'تخلص په پښتو'}
                labelDr={'تخلص به دری'}
                htmlFor={'lastNameDr'}
                placeholder={'اصفری'}
                register={register}
                disabled={disableInputs}
              />
              <FormInputPs
                required={true}
                type={'date'}
                labelEn={'زېږېدلو نېټه'}
                labelDr={'تاریخ تولد'}
                htmlFor={'dateOfBirth'}
                register={register}
                disabled={disableInputs}
                max={`${new Date().getFullYear() - 5}-12-30`}
              />
              <div className="flex flex-col gap-2">
                <div className="flex justify-between">
                  <label className="font-bold font-pashto text-xl">جنس</label>
                  <label className="font-bold font-pashto text-xl">جنس</label>
                </div>
                <Select
                  value={selectedGender}
                  options={genderOptions}
                  onChange={(value) => handleGenderChange(value)}
                  required
                  isSearchable={false}
                  isDisabled={disableInputs}
                />
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex justify-between">
                  <label className="font-bold font-pashto text-xl">
                    کمربند
                  </label>
                  <label className="font-bold font-pashto text-xl">
                    کمربند
                  </label>
                </div>
                <Select
                  value={selectedBelt}
                  options={beltOptions}
                  onChange={(value) => handleBeltChange(value)}
                  isSearchable={false}
                  required
                  isDisabled={disableInputs}
                />
              </div>
              {user.role === 'admin' && (
                <div className="flex flex-col gap-2">
                  <div className="flex justify-between">
                    <label className="font-bold font-pashto text-xl">
                      کلب مربوطه
                    </label>
                    <label className="font-bold font-pashto text-xl">
                      مربوطه کلب
                    </label>
                  </div>
                  <Select
                    required
                    options={clubs}
                    value={selectedClub}
                    onChange={(value) => handleClubChange(value)}
                    isDisabled={disableInputs}
                  />
                </div>
              )}
              {selectedBelt.value === 'black' && (
                <>
                  <div className="flex flex-col gap-2">
                    <div className="flex justify-between">
                      <label className="font-bold font-pashto text-xl">
                        درجه فعلی
                      </label>
                      <label className="font-bold font-pashto text-xl">
                        اوسنۍ درجه
                      </label>
                    </div>
                    <Select
                      isSearchable={false}
                      value={selectedCurrentDan}
                      options={danGradeOptions}
                      onChange={(value) => handleCurrentDanChange(value)}
                      isClearable
                      isDisabled={disableInputs}
                    />
                  </div>
                  <FormInputPs
                    labelEn={'اوسنۍ درجې نمبر'}
                    labelDr={'نمبر درجه فعلی'}
                    htmlFor={'danCertificate'}
                    placeholder={'i.e: 01512345'}
                    register={register}
                    disabled={disableInputs}
                    maxLength={8}
                    minLength={8}
                  />
                  <FormInputPs
                    type={'date'}
                    labelEn={'اوسني درجې د صدور نېټه'}
                    labelDr={'تاریخ صدور درجه فعلی'}
                    htmlFor={'issueDate'}
                    register={register}
                    disabled={disableInputs}
                  />
                </>
              )}
              {user.role === 'admin' && (
                <>
                  <FormInputPs
                    type={'text'}
                    labelDr={'Tcon Username'}
                    htmlFor={'tconUsername'}
                    placeholder={'anwari1975'}
                    register={register}
                  />
                  <FormInputPs
                    type={'text'}
                    labelDr={'Tcon Password'}
                    htmlFor={'tconPassword'}
                    placeholder={'anwari@1975'}
                    register={register}
                  />
                </>
              )}

              <div className="flex flex-col gap-2">
                <div className="flex justify-between">
                  <label
                    className="font-bold font-pashto text-xl"
                    htmlFor="nid"
                  >
                    تذکره
                  </label>
                  <label
                    className="font-bold font-pashto text-xl"
                    htmlFor="nid"
                  >
                    تذکره
                  </label>
                </div>
                <input
                  required={docRequired}
                  {...register('nid')}
                  type="file"
                  name="nid"
                  id="nid"
                  className="file:bg-gray-700 file:font-bold file:text-white hover:file:bg-gray-600 hover:file:shadow file:cursor-pointer file:border-0 file:rounded-full file:py-2 file:px-4 file:mr-4 text-gray-500"
                />
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex justify-between">
                  <label
                    className="font-bold font-pashto text-xl"
                    htmlFor="photo"
                  >
                    عکس
                  </label>
                  <label
                    className="font-bold font-pashto text-xl"
                    htmlFor="photo"
                  >
                    عکس
                  </label>
                </div>
                <input
                  required={docRequired}
                  {...register('photo')}
                  type="file"
                  name="photo"
                  id="photo"
                  className="file:bg-gray-700 file:font-bold file:text-white hover:file:bg-gray-600 hover:file:shadow file:cursor-pointer file:border-0 file:rounded-full file:py-2 file:px-4 file:mr-4 text-gray-500"
                />
              </div>

              <div className="md:col-start-3 flex gap-4">
                <button
                  type="submit"
                  className="font-pashto text-lg col-start-3 row-start-6 self-end w-full h-12 mt-2 rounded hover:bg-gray-800 uppercase bg-gray-700 text-white font-bold py-3 px-2 transition"
                >
                  لېګل |{'  '}
                  {<span className="font-pashto text-lg font-bold">ارسال</span>}
                </button>
                <button
                  type="button"
                  onClick={() => {
                    Notiflix.Confirm.show(
                      'Reset Form',
                      'Are you sure you want to reset the form?',
                      'Reset',
                      'Cancel',
                      () => {
                        reset();
                        setSelectedClub('');
                        setSelectedCurrentDan('');
                        setSelectedGender('');
                        setSelectedBelt('');
                      }
                    );
                  }}
                  className="font-pashto text-xl col-start-2 w-full row-start-6 self-end h-12 mt-2 rounded hover:bg-red-600 uppercase bg-red-500 text-white font-bold py-3 px-2 transition"
                >
                  له سره |{' '}
                  {
                    <span className="font-pashto text-lg font-bold">
                      شروع دوباره
                    </span>
                  }
                </button>
              </div>
            </div>
          </Scrollbars>
        </form>
      </Panel>
    );
  }
}

export default AthleteForm;
