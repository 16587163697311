import { useEffect, useState } from 'react';
import FormInputText from '../uiComponents/FormInputText';
import { useForm } from 'react-hook-form';
import { useUserSignupMutation } from '../../store';
import Select from '../uiComponents/Select';
import MainHeading from '../uiComponents/MainHeading';
import { showLoadingNotificationEffect } from '../../utils/helpers';

function UserForm() {
  const { register, handleSubmit, reset } = useForm();
  const [selectedRoles, setSelectedRoles] = useState('');

  const [userSignup, userSignupResults] = useUserSignupMutation();

  const roles = [
    { label: 'Trainer', value: 'trainer' },
    { label: 'Moderator', value: 'moderator' },
    { label: 'Admin', value: 'admin' },
    { label: 'Poomsae Judge', value: 'poomsaeJudge' },
  ];

  const handleRoleChange = (option) => {
    setSelectedRoles(option);
  };

  const handleFormSubmit = (form) => {
    form.role = selectedRoles.value;
    userSignup(form);
  };

  useEffect(() => {
    showLoadingNotificationEffect(userSignupResults, 'user', 'Adding');
    if (userSignupResults.isSuccess) reset();
  }, [userSignupResults, reset]);

  return (
    <form
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(handleFormSubmit)}
      className="w-5/6 md:w-fit"
    >
      <div className="flex flex-col bg-gray-100 rounded-lg p-4 md:p-8 h-fit w-full md:w-full">
        <MainHeading>Add User | Enter Details</MainHeading>
        <div className="flex flex-col gap-4 md:grid md:grid-cols-2 py-4">
          <FormInputText
            label={'First Name (English)'}
            htmlFor={'firstName'}
            placeholder={'Mohammad'}
            register={register}
            required
          />
          <FormInputText
            label={'Middle Name (English)'}
            htmlFor={'middleName'}
            placeholder={'Anwar'}
            register={register}
          />
          <FormInputText
            label={'Last Name (English)'}
            htmlFor={'lastName'}
            placeholder={'Asghari'}
            register={register}
            required
          />
          <FormInputText
            label={'Username'}
            htmlFor={'username'}
            placeholder={'anwar-asghari'}
            register={register}
            required
          />
          <FormInputText
            label={'Password'}
            htmlFor={'password'}
            placeholder={'*******'}
            register={register}
            required
          />

          <div className="flex flex-col gap-1">
            <label className="font-bold">Role</label>
            <Select
              value={selectedRoles}
              options={roles}
              onChange={(value) => handleRoleChange(value)}
              required
            />
          </div>

          <button className="col-start-1 col-end-3 row-start-4 self-end h-12 w-full mt-2 rounded hover:bg-gray-800 uppercase bg-gray-700 text-white font-bold py-3 px-2">
            submit
          </button>
        </div>
      </div>
    </form>
  );
}

export default UserForm;
