import React from 'react';
import Select from 'react-select';
import MainHeading from '../uiComponents/MainHeading';
import { useDispatch, useSelector } from 'react-redux';
import { setPoomsaeJudgeRole, setShowJudgeConfirmRoleModal } from '../../store';
import Button from '../uiComponents/Button';
export default function JudgeConfirmRoleForm() {
  const dispatch = useDispatch();

  const { poomsaeJudgeRole } = useSelector((state) => state.data);
  const dropdownStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: 'transparent',
      paddingTop: '0.125rem',
      paddingBottom: '0.125rem',
      paddingLeft: '0.125rem',
      paddingRight: '0.125rem',
      boxShadow:
        '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);',
      fontWeight: '500',
    }),
    container: (baseStyles, state) => ({
      ...baseStyles,
      outlineColor: '#1f2937',
      outlineWidth: 50,
    }),
    placeholder: (baseStyles, state) => ({
      ...baseStyles,
      fontWeight: '500',
    }),
  };

  const dropdownTheme = (theme) => ({
    ...theme,
    colors: { ...theme.colors, primary25: '#D1D5DB', primary: '#1f2937' },
  });
  const judgeRoleOptions = [
    { label: `Judge 1`, value: 'j1' },
    { label: `Judge 2`, value: 'j2' },
    { label: `Judge 3`, value: 'j3' },
    { label: `Judge 4`, value: 'j4' },
    { label: `Judge 5`, value: 'j5' },
  ];
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="flex flex-col gap-4 bg-gray-100 rounded-lg p-4 md:p-8 h-fit w-5/6 md:w-fit"
    >
      <MainHeading>Select Your Role</MainHeading>
      <div className="flex flex-col gap-2">
        <Select
          styles={dropdownStyles}
          theme={dropdownTheme}
          isSearchable={false}
          value={
            poomsaeJudgeRole
              ? {
                  label: `Judge ${poomsaeJudgeRole.split('j')[1]}`,
                  value: poomsaeJudgeRole,
                }
              : ''
          }
          onChange={(option) => {
            localStorage.setItem('poomsaeJudgeRole', option.value);
            dispatch(setPoomsaeJudgeRole(option.value));
          }}
          options={judgeRoleOptions}
        />
        {
          <Button
            onClick={() =>
              poomsaeJudgeRole ? dispatch(setShowJudgeConfirmRoleModal()) : ''
            }
          >
            done
          </Button>
        }
      </div>
    </div>
  );
}
