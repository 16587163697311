import React from 'react';

export default function Button({ onClick, className, children }) {
  return (
    <button
      className={`uppercase w-auto py-1 px-2 md:h-10 md:py-2 md:px-3 font-bold bg-gray-700 text-white rounded-md shadow hover:bg-gray-800 transition ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
