import classNames from 'classnames';
import { Link } from 'react-router-dom';

function BigButton({ icon, text, to, ...rest }) {
  const defaultClasses = classNames(
    'text-gray-700 flex flex-col gap-2 p-1 items-center justify-center border-2 rounded-lg border-gray-100  cursor-pointer hover:bg-gray-700 hover:text-gray-300 hover:shadow transition',
    'w-28 h-28'
  );
  const mdClasses = classNames('md:p-4 md:w-40 md:h-40');

  return (
    <Link to={to} className={`${defaultClasses} ${mdClasses}`}>
      <div className="text-4xl md:text-6xl">{icon}</div>
      <p className="text-md md:text-lg font-bold uppercase text-center">
        {text}
      </p>
    </Link>
  );
}

export default BigButton;
