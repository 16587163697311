import React from 'react';
import Panel from '../uiComponents/Panel';
import NavLink from '../uiComponents/NavLink';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../uiComponents/Button';
import { setShowExamModal } from '../../store';
import Skeleton from '../uiComponents/Skeleton';
import {
  HiMiniCalendarDays,
  HiMiniClock,
  HiMiniMapPin,
  HiMiniUser,
} from 'react-icons/hi2';

export default function EventPageInfo({ event, eventDate, regEndDate }) {
  const { user } = useSelector((state) => state.authentication);
  const { eventType } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Panel className={'h-fit w-full flex justify-between'}>
      <div className="w-full flex gap-2 items-center">
        <div className="flex flex-col gap-2 w-1/2 md:w-fit">
          <img
            className="w-48 rounded-lg drop-shadow h-auto"
            // src={`${window.location.origin}/public/uploads/events/${event?.image}`}
            src={`https://www.server.antf.cloud/public/uploads/events/${event?.image}`}
            alt=""
          />

          <div className="flex flex-col gap-2 mt-2 md:hidden">
            <NavLink
              to={`/dashboard/events/${eventType}/participants/${event?._id}`}
            >
              participants
            </NavLink>
            {user.role === 'admin' && (
              <>
                {eventType === 'poomsae' && (
                  <Button
                    onClick={() =>
                      navigate(
                        `/dashboard/events/poomsae/results/${event?._id}`
                      )
                    }
                  >
                    Results
                  </Button>
                )}

                <Button
                  onClick={() => {
                    dispatch(setShowExamModal());
                  }}
                >
                  Edit Event
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="text-md text-gray-500 pl-4 flex flex-col">
          <h1 className="text-gray-800 font-bold text-xl ">
            {event?.title || <Skeleton />}
          </h1>
          <div className="flex gap-1 items-center">
            <HiMiniMapPin />
            <p>Location: {event?.locations.join(', ')}</p>
          </div>
          <div className="flex gap-1 items-center">
            <HiMiniCalendarDays />
            <p>Date: {eventDate}</p>
          </div>
          <div className="flex gap-1 items-center">
            <HiMiniUser />
            <p>Participants: {event?.participants.length}</p>
          </div>
          <div className="flex gap-1 items-center">
            <HiMiniClock />
            <p>Deadline: {regEndDate}</p>
          </div>
        </div>
      </div>
    </Panel>
  );
}

function EventPageInfoSkeleton() {
  const { user } = useSelector((state) => state.authentication);
  const { eventType } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Panel className={'h-fit w-full flex justify-between'}>
      <div className="w-full flex gap-2 items-center">
        <div className="flex flex-col gap-2 w-1/2 md:w-fit">
          <div className="w-48 rounded-lg drop-shadow h-32">
            <Skeleton />
          </div>

          <div className="flex flex-col gap-2 mt-2 md:hidden">
            <NavLink
            //   to={`/dashboard/events/${eventType}/participants/${event?._id}`}
            >
              participants
            </NavLink>
            {user.role === 'admin' && (
              <>
                {eventType === 'poomsae' && (
                  <Button
                    onClick={
                      () => navigate()
                      // `/dashboard/events/poomsae/results/${event?._id}`
                    }
                  >
                    Results
                  </Button>
                )}

                <Button
                  onClick={() => {
                    dispatch(setShowExamModal());
                  }}
                >
                  Edit Event
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="text-md text-gray-500 pl-4 flex flex-col">
          <h1 className="text-gray-800 font-bold text-xl w-48 ">
            <Skeleton />
          </h1>
          <div className="flex gap-1 items-center py-0.5">
            <HiMiniMapPin />
            <Skeleton />
          </div>
          <div className="flex gap-1 items-center py-0.5">
            <HiMiniCalendarDays />
            <Skeleton />
          </div>
          <div className="flex gap-1 items-center py-0.5">
            <HiMiniUser />
            <Skeleton />
          </div>
          <div className="flex gap-1 items-center py-0.5">
            <HiMiniClock />
            <Skeleton />
          </div>
        </div>
      </div>
    </Panel>
  );
}

export { EventPageInfoSkeleton };
