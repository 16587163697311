import React from 'react';
import { calculateAthletePoints } from '../../utils/helpers';
import Notiflix from 'notiflix';
import { useSelector } from 'react-redux';
import { useDeletePoomsaePointMutation } from '../../store';
import Tippy from '@tippyjs/react';
import { HiTrash } from 'react-icons/hi2';
import Panel from '../uiComponents/Panel';
export default function TeamAndDuoPointsSummary({ poomsaeProfile }) {
  const { user } = useSelector((state) => state.authentication);
  const [deletePoomsaePoint] = useDeletePoomsaePointMutation();

  const handleDeletePoint = (roundId, pointId) => {
    Notiflix.Confirm.show(
      'Delete Point',
      'Are you sure you want to delete this point?',
      'Delete',
      'Cancel',
      () => deletePoomsaePoint({ roundId, pointId })
    );
  };

  const renderedPointsForRound = (profile, round, groupTitle) => {
    const pointArrToSort = [...(profile[round] || '')];
    const sortedJudges = pointArrToSort.sort((a, b) => {
      const jA = Object.keys(a)[2].split('j')[1];
      const jB = Object.keys(b)[2].split('j')[1];

      return jA - jB;
    });
    if (sortedJudges.length > 0) {
      const { avgAccuracy, avgPresentation, totalPoint } =
        calculateAthletePoints(profile, round);

      const points = sortedJudges.map((point) => {
        const judgeName = Object.keys(point)[2];

        return (
          <div
            key={point._id}
            className="flex flex-col gap-2 bg-gray-800 rounded shadow w-full p-4"
          >
            <div className="flex justify-between text-lg font-bold text-gray-100 text-center">
              <p className="">
                {judgeName.charAt(0).toUpperCase() + judgeName.slice(1)} |{' '}
                {point[judgeName].user.firstName}
              </p>
              {user.role === 'admin' && (
                <Tippy content="Delete">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeletePoint(point._id, point[judgeName]._id);
                    }}
                  >
                    <HiTrash />
                  </button>
                </Tippy>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex justify-between gap-2 font-bold text-gray-100 text-center">
                <div className="bg-green-500 w-full p-2 rounded">
                  <p>{point[judgeName].accuracyPoints.toFixed(2)}</p>
                </div>
                <div className="bg-red-500 w-full p-2 rounded">
                  <p>{point[judgeName].presentationPoints.toFixed(2)}</p>
                </div>
              </div>
              <div className="bg-green-500 p-2 rounded text-center font-bold text-gray-100">
                <p>
                  {(
                    point[judgeName].presentationPoints +
                    point[judgeName].accuracyPoints
                  ).toFixed(2)}
                </p>
              </div>
            </div>
          </div>
        );
      });

      return (
        <div>
          <p className="font-bold text-gray-800 text-xl mb-2">{groupTitle}</p>
          <div className="grid grid-cols-2 grid-rows-2 md:grid-rows-1 md:grid-cols-6 gap-4">
            {points}{' '}
            <div className="md:col-start-6 flex flex-col gap-2 bg-gray-800 rounded shadow w-full p-4">
              <div>
                <p className="text-lg font-bold text-gray-100 text-center">
                  Result
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex justify-between gap-2 font-bold text-gray-100 text-center">
                  <div className="bg-green-500 w-full p-2 rounded">
                    <p>{avgAccuracy?.toFixed(2) ?? `0.00`}</p>
                  </div>
                  <div className="bg-red-500 w-full p-2 rounded">
                    <p>{avgPresentation?.toFixed(2) ?? `0.00`}</p>
                  </div>
                </div>
                <div className="bg-green-500 p-2 rounded text-center font-bold text-gray-100">
                  <p>
                    {isNaN(totalPoint?.toFixed(3))
                      ? `0.000`
                      : totalPoint?.toFixed(3)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderedPreliminaryPoints = renderedPointsForRound(
    poomsaeProfile,
    'preliminaryPoints',
    'Preliminary'
  );

  const renderedSemiFinalPoints = renderedPointsForRound(
    poomsaeProfile,
    'semiFinalPoints',
    'Semi Final'
  );

  const renderedFinalPoints = renderedPointsForRound(
    poomsaeProfile,
    'finalPoints',
    'Final'
  );
  return (
    <Panel className="">
      <div className="grid gap-4 auto-rows-min">
        {renderedPreliminaryPoints}
        {renderedSemiFinalPoints}
        {renderedFinalPoints}
        {!renderedPreliminaryPoints &&
          !renderedSemiFinalPoints &&
          !renderedFinalPoints && (
            <div className="w-full h-full  flex items-center justify-center">
              <h1 className="font-bold text-center text-gray-800 text-xl">
                Competition Pending.
              </h1>
            </div>
          )}
      </div>
    </Panel>
  );
}
