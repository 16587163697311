import { useGetAthleteQuery, useUpdateExamProfileMutation } from '../../store';
import { useEffect } from 'react';
import Notiflix from 'notiflix';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  getExaminationProfile,
  showLoadingEffect,
  showLoadingNotificationEffect,
} from '../../utils/helpers';

function AthleteExamForm({ handleSetAthleteName }) {
  const { register, handleSubmit, setValue, watch, getValues } = useForm();
  const { athleteId, eventId } = useParams();
  const navigate = useNavigate();
  const {
    data: athleteDataApi,
    isLoading: athleteIsLoading,
    isError: athleteIsError,
  } = useGetAthleteQuery(athleteId);

  const [updateProfile, updateProfileResults] = useUpdateExamProfileMutation();

  useEffect(() => {
    watch();
    if (athleteDataApi) {
      const testObj = getExaminationProfile(athleteDataApi, eventId)[0]
        .promotionTests;

      const testArr = Object.entries(testObj);
      testArr.forEach((test) => {
        setValue(test[0], test[1].score);
      });
    }
  }, [athleteDataApi, eventId, setValue, watch]);

  useEffect(() => {
    showLoadingNotificationEffect(updateProfileResults, 'Athlete', 'Updating');
  }, [updateProfileResults]);

  useEffect(() => {
    showLoadingEffect(athleteDataApi, athleteIsLoading, athleteIsError);
    if (athleteDataApi) handleSetAthleteName(athleteDataApi.fullNameEn);
  }, [athleteIsLoading, athleteIsError, athleteDataApi, handleSetAthleteName]);

  useEffect(() => {
    if (athleteDataApi) {
      if (getExaminationProfile(athleteDataApi, eventId)[0].feePaid === false) {
        Notiflix.Report.failure(
          'Fees Not Cleared!',
          'You will not be able to conduct the examination until fees has been cleared.',
          'Okay',
          () => navigate(-1)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [athleteDataApi, navigate]);

  if (athleteDataApi) {
    const athlete = athleteDataApi;
    const examinationProfile = getExaminationProfile(athlete, eventId)[0];

    const handleFormSubmit = (form) => {
      const profileId = examinationProfile._id;

      const body = {
        profileId,
        promotionTests: {},
        promotionStatus: 'passed',
      };
      const points = [];
      const failCount = [];

      const array = Object.entries(form);
      array.forEach((test) => {
        body.promotionTests[test[0]] = {
          score: test[1],
          passed: test[1] >= 6 ? true : false,
        };
      });

      array.forEach((test) => {
        points.push(test[1]);
      });

      points.forEach((point) => {
        if (point <= 5) {
          failCount.push(1);
        }
      });

      if (failCount.length >= 3) body.promotionStatus = 'failed';

      if (failCount.length < 3) body.promotionStatus = 'conditional';

      if (failCount.length === 0) body.promotionStatus = 'passed';

      updateProfile(body);
    };

    const handleReset = (e) => {
      e.preventDefault();
      const profileId = examinationProfile._id;

      const body = {
        profileId,
        promotionTests: {
          settingStancePunch: {
            score: 0,
            passed: false,
          },
          stretchKicks: {
            score: 0,
            passed: false,
          },
          basicKicks: {
            score: 0,
            passed: false,
          },
          coordinationKicks: {
            score: 0,
            passed: false,
          },
          selfDefense: {
            score: 0,
            passed: false,
          },
          oneStep: {
            score: 0,
            passed: false,
          },
          poomsae: {
            score: 0,
            passed: false,
          },
          keurugi: {
            score: 0,
            passed: false,
          },
          breaking: {
            score: 0,
            passed: false,
          },
          physique: {
            score: 0,
            passed: false,
          },
        },
        promotionStatus: 'uninitialized',
      };

      Notiflix.Confirm.show(
        'Reset Tests',
        'Are you sure you want to reset the tests?',
        'Reset',
        'Cancel',
        () => updateProfile(body)
      );
    };

    const testsArray = Object.entries(examinationProfile.promotionTests);

    const promotionForms = testsArray.map((test, i) => {
      const title = `${test[0].split(/(?=[A-Z])/)[0][0].toUpperCase()}${test[0]
        .split(/(?=[A-Z])/)[0]
        .slice(1)} ${
        test[0].split(/(?=[A-Z])/)[1] ? test[0].split(/(?=[A-Z])/)[1] : ''
      } ${test[0].split(/(?=[A-Z])/)[2] ? test[0].split(/(?=[A-Z])/)[2] : ''}`;
      return (
        <div
          className={`flex flex-col justify-between bg-gray-800 text-white font-bold text-xl rounded-lg px-4 py-4 h-40 ${
            getValues(test[0]) >= 6 ? `bg-gray-700` : 'bg-red-500'
          } transition-all`}
          key={i}
        >
          <label>{title}</label>
          <input
            min={0}
            max={10}
            placeholder="0"
            className={`placeholder:text-white rounded-lg px-3 py-2 ${
              getValues(test[0]) >= 6 ? `bg-gray-400` : 'bg-red-300'
            } transition-all`}
            type="number"
            {...register(test[0])}
          />
        </div>
      );
    });

    return (
      <>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="flex flex-col gap-4 mt-4">
            <div className="grid grid-cols-5 gap-2">
              {promotionForms}
              <div className="flex gap-2 justify-end row-start-3 col-start-5 self-start">
                <button className="bg-gray-700 px-3 py-2 self-end w-1/2 text-white font-bold rounded hover:bg-gray-800 transition">
                  Submit
                </button>
                <button
                  onClick={handleReset}
                  className="bg-red-500 px-3 py-2 self-end w-1/2 text-white font-bold rounded hover:bg-red-600 transition"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}

export default AthleteExamForm;
