import React from 'react';

import {
  HiMiniCalendarDays,
  HiMiniClock,
  HiMiniMapPin,
  HiMiniUser,
} from 'react-icons/hi2';
import Skeleton from '../uiComponents/Skeleton';
export default function EventItemSkeleton() {
  return (
    <div
      key={1}
      className="w-full h-fit md:h-full mb-4 flex flex-col md:flex-row bg-gray-100 rounded-lg hover:drop-shadow-lg border border-gray-200  transition-all"
    >
      <div className="h-full w-full flex flex-col justify-between">
        <div className="p-2 w-full aspect-[4/3]">
          <Skeleton />
        </div>
        <div className="w-full flex flex-col gap-2">
          <h1 className="font-bold text-center text-gray-800 mt-4 md:mt-1 px-8">
            <Skeleton />
          </h1>
          <div className="flex flex-col gap-1 text-sm text-gray-500 px-4">
            <div className="flex gap-1 items-center">
              <HiMiniMapPin />
              <Skeleton />
            </div>
            <div className="flex gap-1 items-center">
              <HiMiniCalendarDays />
              <Skeleton />
            </div>
            <div className="flex flex-row gap-1 items-center">
              <HiMiniUser size={14} />
              <Skeleton />
            </div>
            <div className="flex gap-1 items-center">
              <HiMiniClock />
              <Skeleton />
            </div>
          </div>
        </div>
        <div className="flex justify-center align-middle px-4 pb-4">
          <Skeleton />
        </div>
      </div>
    </div>
  );
}
