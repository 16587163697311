import React from 'react';
import { HiMiniMagnifyingGlass } from 'react-icons/hi2';
import { twMerge } from 'tailwind-merge';
export default function SearchbarSearchInput({
  label,
  value,
  onChange,
  placeholder,
  inputClassName,
}) {
  const inputClasses = twMerge(
    'peer/search w-full py-2 pr-3 pl-8 text-gray-800 font-semibold rounded shadow focus:outline focus:transition-all focus:duration-[30ms]',
    inputClassName
  );
  return (
    <div className="w-full md:w-1/2 flex flex-col gap-2">
      <label className="lg:text-md font-bold text-gray-200">{label}</label>
      <div className="relative group">
        <input
          placeholder={placeholder}
          className={inputClasses}
          type="text"
          value={value}
          onChange={onChange}
        />

        <HiMiniMagnifyingGlass className="absolute peer-focus/search:text-gray-800 top-[10px] left-2 text-gray-400 font-bold text-xl transition" />
      </div>
    </div>
  );
}
