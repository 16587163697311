import React from 'react';

import Rselect from 'react-select';
import { dropdownStyles, dropdownTheme } from '../../utils/helpers';

export default function Select({
  value,
  options,
  onChange,
  isSearchable,
  isDisabled,
  isClearable,
  required,
  ...rest
}) {
  return (
    <Rselect
      className="rounded hover:ring ring-gray-400 focus:ring focus:ring-gray-800 transition"
      classNames={{
        container: (state) =>
          state.isFocused ? 'ring ring-gray-900 ' : 'transition',
      }}
      theme={dropdownTheme}
      styles={dropdownStyles}
      value={value}
      options={options}
      onChange={(value) => onChange(value)}
      required={required}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      isClearable={isClearable}
      {...rest}
    />
  );
}
