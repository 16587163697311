import Scrollbars from 'rc-scrollbars';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPageScrollPosition } from '../../store';

export default function Scrollbar({ children, isLoading }) {
  const scrollbars = useRef(null);
  const dispatch = useDispatch();
  const { pageScrollPosition } = useSelector((state) => state.data);

  useEffect(() => {
    if (scrollbars.current) {
      scrollbars.current.scrollTop(pageScrollPosition);
    }
  }, [pageScrollPosition]);

  return (
    <Scrollbars
      ref={scrollbars}
      onScrollStop={() => {
        if (!isLoading)
          dispatch(setPageScrollPosition(scrollbars.current.getScrollTop()));
      }}
      className="rounded-md"
    >
      {children}
    </Scrollbars>
  );
}
