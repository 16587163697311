import React, { useEffect } from 'react';
import Panel from '../uiComponents/Panel';
import {
  useAddAthleteRefToClubMutation,
  useGetAllAthletesTrainerQuery,
} from '../../store';
import { Link, useLocation } from 'react-router-dom';
import MainHeading from '../uiComponents/MainHeading';
import Tippy from '@tippyjs/react';
import { HiEye, HiPencilSquare, HiPlus } from 'react-icons/hi2';
import {
  showLoadingEffect,
  showLoadingNotificationEffect,
} from '../../utils/helpers';
import Scrollbars from 'rc-scrollbars';
import SortableTable from '../uiComponents/SortableTable';

export default function ClubItem() {
  const {
    state: { club },
  } = useLocation();

  const { data, isLoading, isError, error } = useGetAllAthletesTrainerQuery(
    club.trainer._id
  );

  const [addRefToClub, addRefToClubResults] = useAddAthleteRefToClubMutation();

  useEffect(() => {
    showLoadingEffect(data, isLoading, isError, error);
  }, [data, error, isError, isLoading]);
  useEffect(() => {
    showLoadingNotificationEffect(addRefToClubResults, 'Athlete', 'Adding');
  }, [addRefToClubResults]);

  const config = [
    {
      label: 'Athlete',
      render: (athlete) => athlete.fullNameEn,
    },

    {
      label: 'Dan',
      render: (athlete) =>
        athlete.danGradesKukkiwon.slice(-1)[0]?.dan ||
        athlete.belt.charAt(0).toUpperCase() + athlete.belt.slice(1),
    },
    {
      label: 'Club',
      render: (athlete) => athlete.club.name,
    },
    { label: 'Country', render: (athlete) => athlete.club.country },
    { label: 'Province', render: (athlete) => athlete.club.province },
    {
      label: 'Actions',
      render: (athlete) => (
        <div className="flex gap-2 text-gray-800 text-lg cursor-pointer">
          <Tippy content="View">
            <Link to={`/dashboard/athletes/view/${athlete._id}`}>
              <HiEye />
            </Link>
          </Tippy>
          <Tippy content="Edit">
            <Link to={`/dashboard/athletes/edit/${athlete._id}`}>
              <HiPencilSquare />
            </Link>
          </Tippy>
          {!club.athletes.includes(athlete._id) && (
            <Tippy content="Add">
              <button
                onClick={() => {
                  addRefToClub({ athleteId: athlete._id, clubId: club._id });
                }}
              >
                <HiPlus />
              </button>
            </Tippy>
          )}
        </div>
      ),
    },
  ];

  if (data) {
    return (
      <Panel
        className="h-5/6 w-5/6 md:h-4/6 md:w-2/5 md:p-8 flex flex-col"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="border-b-2 border-gray-300 pb-2 mb-2">
          <MainHeading className="mb-2">About Club</MainHeading>
          <div className="font-semibold">
            <div className="grid grid-cols-2">
              <p>Name:</p>
              <p>{club.name}</p>
            </div>
            <div className="grid grid-cols-2">
              <p>Country:</p>
              <p>{club.country}</p>
            </div>
            <div className="grid grid-cols-2">
              <p>Province:</p>
              <p>{club.province}</p>
            </div>
          </div>
        </div>
        <MainHeading className="mb-2">Athletes</MainHeading>

        <div className="h-full">
          <Scrollbars>
            <SortableTable data={data} config={config} />
          </Scrollbars>
        </div>
      </Panel>
    );
  }
}
