import { Route, Routes } from 'react-router-dom';

import Layout from './components/uiComponents/Layout';
import Dashboard from './pages/dashboard';
import BlankPage from './pages/blankPage';
import AthleteForm from './components/athlete/AthleteForm';
import AllAthletesPage from './pages/allAthletesPage';
import LoginPage from './pages/loginPage';
import { useSelector } from 'react-redux';
import AthleteExamProfile from './components/athlete/AthleteExamProfile';
import AthleteDetail from './components/athlete/AthleteDetail';
import AthleteExaminationPage from './pages/athleteExaminationPage';
import Monitor from './components/monitor/Monitor';
// import TestPage from './pages/TestPage';
import { Link } from 'react-router-dom';
import EventsList from './components/events/EventsList';
import EventPage from './pages/events/eventPage';
import PoomsaeParticipantsPage from './pages/events/poomsae/poomsaeParticipantsPage';
import ScoringPage from './pages/events/poomsae/scoringApp/ScoringPage';
import AnnouncePage from './pages/events/poomsae/announcePage';
import PoomsaeMonitor from './pages/events/poomsae/poomsaeMonitor';
import AthletePoomsaeProfile from './components/athlete/AthletePoomsaeProfile';
import PoomsaeResultsList from './components/poomsae/PoomsaeResultsList';
import ClubsPage from './pages/clubsPage';
import UsersList from './components/userAndClub/UsersList';
import ExamParticipantsPage from './pages/events/examinations/examParticipantsPage';

function App() {
  const { token, user } = useSelector((state) => state.authentication);
  return (
    <Routes>
      <Route path="/" Component={LoginPage}></Route>
      {token && (
        <Route path="/dashboard" Component={Layout}>
          <Route index Component={Dashboard} />
          <Route
            path="addAthlete"
            element={
              <BlankPage
                title={
                  <p className="font-pashto font-bold text-xl sm:text-2xl md:text-3xl">
                    لوبغاړي د ثبت فورم | فورم ثبت ورزشکار
                  </p>
                }
                form={<AthleteForm />}
              />
            }
          />
          {user?.role === 'admin' && (
            <>
              <Route path="clubs/:action?/:clubId?" Component={ClubsPage} />
              <Route
                path="users/:action?/:clubId?"
                element={<BlankPage title="Users" form={<UsersList />} />}
              />
              {/* <Route
                path="clubs/view/:clubId"
                element={<BlankPage title={'View Club'} form={<ClubItem />} />}
              />
              <Route path="clubs/edit/:clubId" Component={ClubsPage} /> */}
              {/************************
               * Examination Pages Routes
               *************************/}
              <Route
                path="events/examination/participants/:eventId/start/:athleteId"
                Component={AthleteExaminationPage}
              />
            </>
          )}
          <Route
            path="events/poomsae/participants/:eventId"
            Component={PoomsaeParticipantsPage}
          />
          <Route
            path="events/poomsae/results/:eventId"
            element={
              <BlankPage
                title="Poomsae Event Results"
                form={<PoomsaeResultsList />}
              />
            }
          />
          <Route
            path="events/poomsae/participants/:eventId/view/:athleteId"
            element={
              <BlankPage
                title={'Athlete Poomae Profile'}
                form={<AthletePoomsaeProfile />}
              />
            }
          />
          <Route
            path="events/examination/participants/:eventId"
            Component={ExamParticipantsPage}
          />
          <Route
            path="events/examination/participants/:eventId/view/:athleteId"
            element={
              <BlankPage
                title={'Athlete Exam Profile'}
                form={<AthleteExamProfile />}
              />
            }
          />
          <Route path="events" Component={EventsList} />
          <Route path="events/:eventType/:eventId" Component={EventPage} />

          <Route path="athletes" Component={AllAthletesPage} />

          <Route
            path="athletes/edit/:athleteId"
            element={
              <BlankPage title={'Update Athlete '} form={<AthleteForm />} />
            }
          />
          <Route
            path="athletes/view/:athleteId"
            element={
              <BlankPage title={'Athlete Detail'} form={<AthleteDetail />} />
            }
          />
        </Route>
      )}
      {token && (user.role === 'admin' || user.role === 'poomsaeJudge') && (
        <Route path="poomsaeScoringApp" Component={ScoringPage} />
      )}

      {user?.role === 'admin' && (
        <>
          <Route
            path="/events/poomsae/monitor/announcePage/:eventId"
            Component={AnnouncePage}
          />
          <Route
            path="/events/poomsae/monitor/:eventId"
            Component={PoomsaeMonitor}
          />
          <Route path="/dashboard/monitor" Component={Monitor} />
          {/* <Route path="/test" Component={TestPage} /> */}
        </>
      )}

      <Route
        path="*"
        element={
          !token ? (
            <div className="flex flex-col gap-4 p-8">
              <p className="px-3 py-2 bg-red-500 rounded font-bold text-center text-white">
                You are not logged in.
              </p>
              <Link
                className="uppercase bg-gray-700 hover:bg-gray-800 px-3 py-2 font-bold text-gray-200 rounded-md text-center"
                to="/"
              >
                Login
              </Link>
            </div>
          ) : (
            <div className="flex flex-col gap-4 p-8">
              <p className="px-3 py-2 bg-red-500 rounded font-bold text-center text-white">
                You do not have permission to access this page.
              </p>
              <Link
                className="uppercase bg-gray-700 hover:bg-gray-800 px-3 py-2 font-bold text-gray-100 rounded-md text-center"
                to={-1}
              >
                Go Back
              </Link>
            </div>
          )
        }
      ></Route>
    </Routes>
  );
}

export default App;
