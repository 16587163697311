import React, { useEffect } from 'react';
import {
  setPoomsaeActiveStage,
  useAddPenaltyMutation,
  useDeletePenaltyMutation,
  useGetOperationalDataQuery,
  useUpdateOperationalDataMutation,
} from '../../store';
import { useDispatch } from 'react-redux';
import MainHeading from '../uiComponents/MainHeading';
import Button from '../uiComponents/Button';
import { socket } from '../../utils/socket';
import { showLoadingEffect } from '../../utils/helpers';
import { useParams } from 'react-router-dom';
import { HiMiniClock, HiMiniFlag, HiTrash } from 'react-icons/hi2';
import Tippy from '@tippyjs/react';
import PoomsaeTestingProfile from './PoomsaeTestingProfile';

export default function PoomsaeControls() {
  const { eventId } = useParams();
  const { data, isLoading, isError, error } =
    useGetOperationalDataQuery('poomsae');
  const [updateOperationalData] = useUpdateOperationalDataMutation();
  const [deletePenalty] = useDeletePenaltyMutation();
  const [addPenalty] = useAddPenaltyMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    showLoadingEffect(data, isLoading, isError, error);
  }, [data, error, isError, isLoading]);

  const handleActiveStageChange = (event) => {
    localStorage.setItem('poomsaeActiveStage', event.target.value);
    dispatch(setPoomsaeActiveStage(localStorage.getItem('poomsaeActiveStage')));
    const body = {
      eventType: 'poomsae',
      activeStage: event.target.value,
    };
    updateOperationalData(body);
  };

  if (data) {
    const { activeStage, activeProfile, announce } = data;

    const handleAddPenalty = (penalty) => {
      const round =
        activeStage === 'activePreliminary'
          ? 'preliminary'
          : activeStage === 'activeSemiFinal'
          ? 'semiFinal'
          : activeStage === 'activeFinal'
          ? 'final'
          : '';
      const body = {
        profileId: activeProfile?._id,
        penalty: { round, penalty },
      };
      addPenalty(body);
    };

    const renderPenalties = (penalties) => {
      return penalties?.map((penalty) => {
        return (
          <div
            key={penalty._id}
            className="flex flex-col w-full bg-red-500 text-white font-semibold rounded-md px-2 py-1"
          >
            <div className="flex justify-between">
              <div>
                Round:{' '}
                {penalty.round.charAt(0).toUpperCase() + penalty.round.slice(1)}
              </div>
              <div>
                Penalty:{' '}
                {penalty.penalty.charAt(0).toUpperCase() +
                  penalty.penalty.slice(1)}
              </div>
              <Tippy content="Delete">
                <button
                  onClick={() =>
                    deletePenalty({
                      profileId: activeProfile?._id,
                      penaltyId: penalty._id,
                    })
                  }
                >
                  {<HiTrash />}
                </button>
              </Tippy>
            </div>
          </div>
        );
      });
    };

    const renderedPreliminaryPenalties = renderPenalties(
      activeProfile?.penalties.filter(
        (penalty) => penalty.round === 'preliminary'
      )
    );

    const renderedSemiFinalPenalties = renderPenalties(
      activeProfile?.penalties.filter(
        (penalty) => penalty.round === 'semiFinal'
      )
    );
    const renderedFinalPenalties = renderPenalties(
      activeProfile?.penalties.filter((penalty) => penalty.round === 'final')
    );

    const renderedAthleteInfo = (
      <div className="flex flex-col bg-gray-200 rounded-md p-4 shadow-md col-span-2 font-semibold">
        <div className="grid grid-cols-2">
          <div className="font-bold">Athlete Name:</div>
          <div>
            {Array.isArray(activeProfile?.athlete)
              ? activeProfile?.athlete
                  .map((athlete) => athlete.fullNameEn)
                  .join(', ')
              : activeProfile?.athlete.fullNameEn}
          </div>
        </div>
        <div className="grid grid-cols-2">
          <div className="font-bold">Category:</div>
          <div>{activeProfile?.category}</div>
        </div>
        <div className="grid grid-cols-2">
          <div className="font-bold">Club:</div>
          <div>
            {Array.isArray(activeProfile?.athlete)
              ? activeProfile?.athlete
                  .map((athlete) => athlete.club.name)
                  .join(', ')
              : activeProfile?.athlete.club.name}
          </div>
        </div>
        <div>
          <div className="flex justify-between">
            <p className="font-bold">Penalties:</p>
            <div className="flex gap-2 text-red-500 text-lg">
              <Tippy content="Time Penalty">
                <button onClick={() => handleAddPenalty('time-out')}>
                  <HiMiniClock />
                </button>
              </Tippy>
              <Tippy content="Boundary Penalty">
                <button onClick={() => handleAddPenalty('boundary')}>
                  <HiMiniFlag />
                </button>
              </Tippy>
            </div>
          </div>
          <div className="flex flex-col">
            {renderedPreliminaryPenalties?.length > 0 && (
              <div className="flex flex-col gap-1">
                <div>Preliminary</div>
                {renderedPreliminaryPenalties}
              </div>
            )}
            {renderedSemiFinalPenalties?.length > 0 && (
              <div className="flex flex-col gap-1">
                <div>Semi-Final</div>
                {renderedSemiFinalPenalties}
              </div>
            )}
            {renderedFinalPenalties?.length > 0 && (
              <div className="flex flex-col gap-1">
                <div>Final</div>
                {renderedFinalPenalties}
              </div>
            )}{' '}
            {renderedFinalPenalties?.length === 0 &&
              renderedPreliminaryPenalties?.length === 0 &&
              renderedSemiFinalPenalties?.length === 0 && <div>None</div>}
          </div>
        </div>
      </div>
    );
    return (
      <>
        <div
          onClick={(e) => e.stopPropagation()}
          className="flex flex-col gap-2 bg-gray-100 rounded-lg p-4 md:p-8 h-fit w-5/6 md:w-fit"
        >
          <MainHeading className={'mb-4 text-center'}>
            Competition Controls
          </MainHeading>

          <div className="flex md:grid md:grid-cols-2 gap-8">
            <div className="bg-gray-200 rounded-md p-4 shadow-md">
              <h1 className="font-bold mb-2 text-center">Stopwatch</h1>
              <div className="flex flex-col gap-2">
                <button
                  className="p-2 bg-gray-700 text-white w-full h-10 font-bold rounded-md text-center cursor-pointer shadow-md hover:bg-gray-800 transition"
                  onClick={() => {
                    socket.emit('startStopPoomsaeStopwatch', {
                      stopwatchRunning: !data.stopwatchRunning,
                    });
                  }}
                >
                  {data.stopwatchRunning ? 'Stop' : 'Start'}
                </button>
                <button
                  className="p-2 bg-red-500 text-white w-full h-10 font-bold rounded-md text-center cursor-pointer shadow-md"
                  onClick={() => {
                    socket.emit('resetPoomsaeStopwatch');
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
            <div className="bg-gray-200 rounded-md p-4 shadow-md">
              <h1 className="font-bold text-center mb-2">Competition Stage</h1>
              <div>
                <label className="flex gap-2 text-darkBlue font-semibold">
                  <input
                    className="drop-shadow-md"
                    type="radio"
                    checked={activeStage === 'activePreliminary'}
                    name="activeStage"
                    value="activePreliminary"
                    onChange={handleActiveStageChange}
                  />
                  Preliminaries
                </label>
                <label className="flex gap-2 text-darkBlue font-semibold">
                  <input
                    className="drop-shadow-md"
                    type="radio"
                    checked={activeStage === 'activeSemiFinal'}
                    name="activeStage"
                    value="activeSemiFinal"
                    onChange={handleActiveStageChange}
                  />
                  Semi-Finals
                </label>
                <label className="flex gap-2 text-darkBlue font-semibold">
                  <input
                    className="drop-shadow-md"
                    type="radio"
                    checked={activeStage === 'activeFinal'}
                    name="activeStage"
                    value="activeFinal"
                    onChange={handleActiveStageChange}
                  />
                  Finals
                </label>
              </div>
            </div>
            {renderedAthleteInfo}

            <div className="col-span-2 grid grid-cols-2  gap-2">
              <Button
                className={''}
                onClick={() => {
                  window.open(
                    `${window.location.origin}/events/poomsae/monitor/${eventId}`,
                    '_blank'
                  );
                }}
              >
                Open Monitor
              </Button>
              <Button
                onClick={() => {
                  updateOperationalData({
                    eventType: 'poomsae',
                    announce: !announce,
                  });
                }}
              >
                {announce ? 'Hide Results' : 'Announce Results'}
              </Button>
              {/* <Button
                onClick={() => {
                  updateOperationalData({
                    eventType: 'poomsae',
                    test: !data.test,
                  });
                }}
              >
                {data.test ? 'Disable Testing' : 'Activate Testing'}
              </Button> */}
            </div>
          </div>
        </div>
        {data.test && (
          <div
            onClick={(e) => e.stopPropagation()}
            className="ml-4 bg-gray-100 rounded-lg p-4 md:p-8 h-fit w-5/6 md:w-fit"
          >
            <PoomsaeTestingProfile profile={data.testingProfile} />
          </div>
        )}
      </>
    );
  }
}
