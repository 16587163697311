function FormInputText({
  label,
  htmlFor,
  placeholder,
  type,
  register,
  className,
  ...rest
}) {
  return (
    <div className="flex flex-col gap-1">
      <div className="flex justify-between">
        <label className="font-bold text-gray-800 text-md" htmlFor={htmlFor}>
          {label}
        </label>
      </div>
      <input
        className={`px-3 py-2 rounded shadow font-semibold hover:ring hover:ring-gray-400 focus:ring transition focus:ring-gray-800 ${className}`}
        type={type || 'text'}
        name={htmlFor}
        id={htmlFor}
        {...rest}
        placeholder={placeholder}
        {...register(htmlFor)}
      />
    </div>
  );
}

export default FormInputText;
